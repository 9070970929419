import PreLoginContent from "../components/PreLoginContent";
import { useNavigate } from "react-router-dom";
import {apiUrl, appName} from "../helpers";
import {ForgotPasswordForm} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ForgotPassword = () => {
    document.title = `${appName} - Forgot Password`
    const {t} = useTranslation('common')
    const navigate = useNavigate();

    const labelOverrides = {
      heading: t('common:login.pw_recovery'),
      emailFieldLabel: t('common:login.pw_recovery_instruction'),
      emailFieldRequiredlabelOverride: t('common:general.this_field_required'),
      emailFieldValidEmailLabelOveride: t('common:general.enter_valid_email'),
      resetPwBtnText: t('common:login.reset_pw'),
      cancelBtnRedirectText: t('common:general.cancel'),
      resetLinkSentHeader: t('common:login.reset_link_sent'),
      resetLinkSentInfo: t('common:login.reset_link_info'),
      soundsGood: t('common:general.sounds_good'),
      changeEmailLink: t('common:login.change_email'),
      anErrorOccurred: t('common:login.an_error_occurred'),
    }

    return (
        <PreLoginContent>
            <div className="flex justify-center items-center" style={{minHeight: "100vh", paddingTop: "91px", paddingBottom: "15px"}}>
                <ForgotPasswordForm
                  redirectToLoginFxn={()=> {
                      navigate("/")
                  }}
                  redirectToSetPasswordFxn={()=> {
                      navigate("/set-password")
                  }}
                  resetPWApiEndPoint={`${apiUrl}/fx/admin/users/forgot-pwd`}
                  labelOverrides={labelOverrides}
                  userLang={i18next.language}
                />
            </div>
        </PreLoginContent>
    )
}

export default ForgotPassword