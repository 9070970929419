import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AddFormProps{
    showPanelTitle:(a:boolean)=>void
}
const AddAppForm = (props:AddFormProps) => {
    const {showPanelTitle} = props
    const [name,setName] = useState('')
    const [code,setCode] = useState('')
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [disableBtn,setDisableBtn] = useState(true)
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (!name||!code){
            setDisableBtn(true)
        }else{
            setDisableBtn(false)
        }
    },[name,code])

    function addForm(){
        setLoading(true)
        let formData = {
            formCode:code,
            formName:name,
            action:'codeMaintenance'
        }

        axiosSend({
            url:'/fx/admin/app-forms',
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setStage(2)
            setLoading(false)
        })
    }

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    addForm()
                }} className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 gap-[24px]">
                        <TextField label={t('common:app_forms.form_name')} value={name} onChange={(e)=>{setName(e.target.value)}} required={true} autoFocus={true}/>
                        <TextField label={t('common:app_forms.form_code')} value={code} onChange={(e)=>{setCode(e.target.value)}} required={true}/>
                    </div>
                    <Button type="submit" size="lg" variant="primary" disabled={loading||disableBtn}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:app_forms.add_form')}</Button>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1 ">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:app_forms.app_form_added')} subText={t('common:app_forms.app_form_added_msg',{name:name})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else {
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default AddAppForm