import theme from "../../theme";
import styled from "styled-components";

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${theme.colors["neutral-900"]};
  p{
    margin: 0;
    font-size: 14px;
    font-family: "Circular Book Medium",sans-serif;
    font-weight: 500;
    line-height: 17px;
  }
  .field-dets{
    padding: 16px;
    border-radius: 8px;
    background: ${theme.colors["neutral-50"]};
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .field-dets .bank-acct-dets{
    display: flex;
    gap: 8px;
    flex-direction: column;
    color: ${theme.colors["neutral-600"]};
    font-size: 12px;
    line-height: 14px;
  }
  .field-dets .bank-acct-dets .acct-no{
    font-family: "Circular Book Medium",sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.colors["neutral-900"]};
  }
  .field-dets .bank-acct-dets .bank-name{
    font-family: "Circular Book Medium",sans-serif;
    font-weight: 500;
  }
  .amt-dets{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .exch-rate{
    font-family: "Circular Book",sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #0F1B4C;
  }
  .amt-dets div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
`