import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../utils/userContext";
import {axiosSend} from "../../utils/request";
import ConfTxn from "../ConfirmTransaction";
import LogPmt from "../LogPmt";
import CancelTransaction from "../CancelTransaction";
import ConfirmLPRecvFunds from "../ConfirmLPRecvFunds";
import ConfirmBrokerRecvFunds from "../ConfirmBrokerRecvFunds";
import BackButton from "../BackButton";
import SidePanel from "../SidePanel/SidePanel";
import {ucFirstAll} from "../../helpers";
import {
    ActionSuccess, AuditLog, BidsCards,
    Button,
    ButtonOutline,
    LoadingSpinner, LPRequestTable, Pagination,
    Tab, TransactionDetails
} from "@secondstaxorg/sscomp";
import {FieldSection} from "./style";
import {useTranslation} from "react-i18next";


interface TradingHistoryProps{
    transactions:[],
    loadingState:boolean
}

const TradingHistoryTable = (props:TradingHistoryProps) => {
    const userDets:any = useContext(UserContext)
    const {transactions,loadingState} = props
    const [txns,setTxns]:any = useState([])
    const [txnsOri,setTxnsOri]:any = useState([])
    const [txnDetsPanel,setTxnDetsPanel] = useState(false)
    const [txnDets,setTxnDets]:any = useState({})
    const [stage,setStage] = useState(1)
    const [loading,setLoading] = useState(true)
    const [confTxnScr,setConfTxnScr] = useState(false)
    const [logPmtScr,setLogPmtScr] = useState(false)
    const [cancelTxnScr,setCancelTxnScr] = useState(false)
    const [txnInfo,setTxnInfo]:any = useState({})
    const [lpRecvFundsScr,setLpRecvFundsScr] = useState(false)
    const [brokerRecvFundsScr,setBrokerRecvFundsScr] = useState(false)
    const [auditLog,setAuditLog]:any = useState([])
    const [transDets,setTransDets]:any = useState({})
    const [backBtn,setBackBtn]:any = useState(null)
    const [backBtnDisabled,setBackBtnDisabled] = useState(false)
    const navigate = useNavigate()
    const [bids,setBids]:any = useState([])
    const {t,i18n} = useTranslation('common')

    useEffect(()=>{
        document.addEventListener('click',function(e:any){
            let overlay = e.target.classList.value
            if(overlay.includes('trans-overlay')){
                setTxnDetsPanel(false)
                setStage(1)
            }
        })

        //on the trading history page, listen for changes in localstorage then open the details of the transaction
        window.addEventListener('storage',function(){
            loadTxnDets(localStorage.getItem('txnId') as string)
        })
        window.removeEventListener('storage',function(){
            loadTxnDets(localStorage.getItem('txnId') as string)
        })
    },[])

    useEffect(()=>{
            if (Object.keys(txnDets).length > 0){
                if (stage === 1){
                    setBackBtn(<p className="text-neutral-900">Transaction #{txnDets.trx_details.ptr_ref_no}</p>)
                }
                if (stage === 2){
                    setBackBtn(<BackButton disabled={backBtnDisabled} onClick={()=>{
                        if (stage > 1){
                            setStage(stage - 1)
                            setCancelTxnScr(false)
                            setConfTxnScr(false)
                        }
                    }}/>)
                }
                if (stage > 2) {
                    setBackBtn(<BackButton disabled={true}/>)
                }
            }
    },[stage,txnDets,backBtnDisabled])

    useEffect(()=>{
        setTxnsOri(transactions)
        setLoading(loadingState)
    },[transactions,loadingState])

    useEffect(()=>{
        if (localStorage.getItem('txnId')){
            loadTxnDets(localStorage.getItem('txnId') as string)
        }
    },[])

    function loadTxnDets(txnId:string){
        if (txnId != null && txnId !== 'null'){
            axiosSend({
                url:'/fx/pop-fx?transRefNo='+txnId,
                method:"GET"
            }).then(res => {
                setTxnDets(res.data[0].data)
                if (res.data[0].data.trx_audits.length > 0){
                    let tmp:any = []
                    res.data[0].data.trx_audits.forEach((audit:any)=>{
                        tmp.push({
                            timestamp:dayjs(audit.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                            user:audit.aud_usr_id,
                            logDescription:audit.aud_action,
                            reference:audit.aud_id
                        })
                    })
                    setAuditLog(tmp)
                }

                //transaction details for component
                if (res.data.length > 0){
                    setTransDets({
                        requestedBy:res.data[0].data.trx_details.ptr_requester_name,
                        requestDate:dayjs(res.data[0].data.trx_details.ptr_trans_date).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        clientID:res.data[0].data.trx_details.client_id,
                        amount:{
                            baseAmount:res.data[0].data.trx_details.ptr_source_amount,
                            baseFlag:res.data[0].data.trx_details.source_curr_flag,
                            baseCurr:res.data[0].data.trx_details.ptr_source_cur_code,
                            exchAmount:res.data[0].data.trx_details.ptr_dest_amount,
                            exchFlag:res.data[0].data.trx_details.dest_curr_flag,
                            exchCurr:res.data[0].data.trx_details.ptr_dest_cur_code,
                            exchRate:res.data[0].data.trx_details.ptr_rate_selected,
                        },
                        provider:{
                            name:res.data[0].data.trx_details.ptr_rate_lip_code,
                            logo:res.data[0].data.trx_details.LP_logos[44]
                        },
                        destBankAcctDets:{
                            bankLogo:res.data[0].data.trx_dest_bank[0].bank_logos[44],
                            acctNo:res.data[0].data.trx_dest_bank[0].prt_bank_account_number,
                            bankName:res.data[0].data.trx_dest_bank[0].prt_bank_name,
                            acctName:res.data[0].data.trx_dest_bank[0].prt_bank_account_name,
                            acctCurr:res.data[0].data.trx_dest_bank[0].prt_curr_code,
                        }
                    })
                }

                setTxnInfo({
                    txnNo:res.data[0].data.trx_details.ptr_ref_no,
                    pymtAmt:res.data[0].data.trx_details.ptr_source_amount,
                    exchAmt:res.data[0].data.trx_details.ptr_dest_amount,
                    exchCurr:res.data[0].data.trx_details.ptr_dest_cur_code,
                    pymtCurr:res.data[0].data.trx_details.ptr_source_cur_code,
                    exchCurrFlag:res.data[0].data.trx_details.dest_curr_flag,
                    baseCurrFlag:res.data[0].data.trx_details.source_curr_flag,
                })

                //populate offers
                let tmpBids:any = []
                res.data[0].data.trx_bids.forEach((bid:any)=>{
                    tmpBids.push({
                        providerName:bid.ptr_rate_lip_name,
                        baseCurrency:bid.pbid_src_cur_code,
                        exchangeCurrency:bid.pbid_des_cur_code,
                        exchangeAmount:bid.pbid_des_amt,
                        baseAmount:bid.pbid_src_amt,
                        selectedProvider:bid.pbid_client_select === 'Y',
                        providerId:bid.pbid_lip_code,
                        rate:Number(bid.pbid_prop_rate)
                    })
                })
                setBids(tmpBids)

                //open the transaction details panel
                setTxnDetsPanel(true)
                if (localStorage.getItem('txnId')){
                    localStorage.removeItem('txnId')
                }

            }).catch((err)=>{
                console.log(err)
            })
        }
    }

    function TransactionDets(){
        return (
            <>
                <div className="flex flex-col flex-1 gap-[40px] mt-[32px]">
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TransactionDetails requestedBy={transDets.requestedBy} requestDate={transDets.requestDate} clientID={transDets.clientID} amount={transDets.amount} provider={transDets.provider} destAcctDets={transDets.destBankAcctDets} labelOverrides={{
                            clientId:t('common:general.client_id'),
                            requestedBy:t('common:transaction_history.requested_by'),
                            requestDate:t('common:transaction_history.request_date'),
                            amount:t('common:general.amount'),
                            provider:t('common:provider_rates.provider'),
                            destinationAcct:t('common:transaction_history.destination_account')
                        }}/>
                        {txnDets.trx_pay_details && txnDets.trx_pay_details.length > 0 &&
                            <>
                                <FieldSection>
                                    <p>{t('common:transaction_history.payment_method')}</p>
                                    <div className="field-dets">
                                        {ucFirstAll(txnDets.trx_pay_details[0].pay_mode_name)}
                                    </div>
                                </FieldSection>
                                <FieldSection>
                                    <p>{t('common:transaction_history.payee_name')}</p>
                                    <div className="field-dets">
                                        {txnDets.trx_pay_details[0].payee_name}
                                    </div>
                                </FieldSection>
                                <FieldSection>
                                    <p>{t('common:liquidity_providers.bank_name')}</p>
                                    <div className="field-dets">
                                        {txnDets.trx_pay_details[0].bank_name}
                                    </div>
                                </FieldSection>
                                <FieldSection>
                                    <p>{t('common:brokers.bank_country')}</p>
                                    <div className="field-dets flex gap-[8px]">
                                        {txnDets.trx_pay_details[0].bank_cty_flag && <img src={txnDets.trx_pay_details[0].bank_cty_flag} alt="Bank country"/>}
                                        {ucFirstAll(txnDets.trx_pay_details[0].bank_ctry_name)}
                                    </div>
                                </FieldSection>
                                <FieldSection>
                                    <p>{t('common:general.remarks')}</p>
                                    <div className="field-dets">
                                        {txnDets.trx_details.ptr_pay_remarks ? txnDets.trx_details.ptr_pay_remarks : 'N/A'}
                                    </div>
                                </FieldSection>
                            </>
                        }
                    </div>
                    {userDets.profile.length > 0 &&
                        <div className="flex justify-between gap-[8px] bg-white">
                            {Object.keys(userDets).length && userDets.userActions.includes('savCanTrnx') &&
                                <>
                                    {txnDets.trx_actions.savCanTrnx &&
                                        <ButtonOutline onClick={()=>{
                                            setCancelTxnScr(true)
                                            setStage(stage + 1)
                                        }}
                                                       size="lg" variant="error" width="full">{t('common:transaction_history.cancel_transaction')}</ButtonOutline>
                                    }
                                </>
                            }

                            {Object.keys(userDets).length && userDets.userActions.includes('cfmTrnx') &&
                                <>
                                    {txnDets.trx_actions.cfmTrnx &&
                                        <Button variant="primary" size="lg" width="full"
                                                onClick={() => {
                                                    setConfTxnScr(true)
                                                    setLogPmtScr(false)
                                                    setStage(stage + 1)
                                                }}>{t('common:transaction_statuses.action_button.confirm_txn')}</Button>
                                    }
                                </>
                            }

                            {Object.keys(userDets).length && userDets.userActions.includes('cfmCLPay') &&
                                <>
                                    {txnDets.trx_actions.cfmCLPay &&
                                        <Button size="lg" variant="primary"
                                                onClick={() => {
                                                    setLogPmtScr(true)
                                                    setConfTxnScr(false)
                                                    setStage(stage + 1)
                                                }}>{t('common:transaction_statuses.action_button.log_payment')}</Button>
                                    }
                                </>
                            }

                            {Object.keys(userDets).length && userDets.userActions.includes('cfmLPRecpt') &&
                                <>
                                    {txnDets.trx_actions.cfmLPRecpt &&
                                        <Button size="lg" variant="primary"
                                                onClick={() => {
                                                    setLpRecvFundsScr(true)
                                                    setStage(stage + 1)
                                                }}>{t('common:transaction_statuses.action_button.confirm_lp_rec_payment')}</Button>
                                    }
                                </>
                            }
                            {Object.keys(userDets).length && userDets.userActions.includes('cfmBRRecpt') &&
                                <>
                                    {txnDets.trx_actions.cfmBRRecpt &&
                                        <Button size="lg" variant="primary" onClick={() => {
                                            setBrokerRecvFundsScr(true)
                                            setStage(stage + 1)
                                        }}>{t('common:transaction_statuses.action_button.confirm_broker_rec_funds')}</Button>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </>
        )
    }

    function Audit(){
        return (
            <div className="audit-tab-content">
                {auditLog.length > 0 &&
                    <AuditLog logDetails={auditLog}/>
                }
            </div>
        )
    }

    function Offers(){
        return (
            <>
                <BidsCards offers={bids} labelOverrides={{selected:t('common:general.selected')}}/>
            </>
        )
    }


    const tabs:any = [
        {
            tabName:t('common:transaction_history.transaction_details'),
            tabComponent:<TransactionDets/>
        },
        {
            tabName: t('common:brokers.audit'),
            tabComponent:<Audit/>
        },
        {
            tabName:t('common:general.offers'),
            tabComponent:<Offers/>
        }
    ]

    return (
        <>
            {/*TRANSACTION DETAILS PANEL*/}
            {txnDetsPanel &&
                <SidePanel backBtnArea={backBtn} closeFxn={()=> {
                    setTxnDetsPanel(false)
                    setStage(1)
                    setConfTxnScr(false)
                    setLogPmtScr(false)
                    setCancelTxnScr(false)
                    setLpRecvFundsScr(false)
                    setBrokerRecvFundsScr(false)
                    setBackBtnDisabled(false)
                }}>
                {txnDetsPanel &&
                    <>
                        {stage === 1 &&  //initial transaction details page
                            <Tab tabs={tabs}/>
                        }
                        {stage === 2 &&
                            <>
                                {confTxnScr && <ConfTxn txnNo={txnDets.trx_details.ptr_ref_no} backBtnDisabledFxn={(a)=>{setBackBtnDisabled(a)}}/>} {/*confirm transaction*/}
                                {logPmtScr && <LogPmt txnInfo={txnInfo} backBtnDisabledFxn={(a)=>{setBackBtnDisabled(a)}}/>} {/*log payment*/}
                                {cancelTxnScr && <CancelTransaction txnNo={txnDets.trx_details.ptr_ref_no} setBackBtnDisabled={(a)=>{setBackBtnDisabled(a)}}/>} {/*cancel transaction*/}
                                {lpRecvFundsScr && <ConfirmLPRecvFunds txnNo={txnDets.trx_details.ptr_ref_no} setBackBtnDisabled={(a)=>{setBackBtnDisabled(a)}}/>} {/*confirm lp received payment*/}
                                {brokerRecvFundsScr && <ConfirmBrokerRecvFunds txnNo={txnDets.trx_details.ptr_ref_no} setBackBtnDisabled={(a)=>{setBackBtnDisabled(a)}}/>} {/*confirm broker received funds*/}
                            </>
                        }
                        {stage === 3 &&
                            <div>
                                <div className="justify-center">
                                    <ActionSuccess headingText={t('common:transaction_history.payment_logged')}/>
                                </div>
                                <div className="absolute bottom-0 w-full p-[32px]">
                                    <button className="primary-button-lg w-full" onClick={()=>{
                                        setTxnDetsPanel(false)
                                        setStage(1)
                                    }}>{t('common:general.sounds_good')}</button>
                                </div>
                            </div>
                        }
                    </>
                }
                </SidePanel>
            }

            {/*TRADING HISTORY TABLE*/}
            <div className="my-[16px]">
                <div className="mt-[8px] rounded-[8px]">
                    {loading &&
                        <div className="flex justify-center items-center h-[400px] bg-white">
                            <LoadingSpinner variant={"primary"}/>
                        </div>
                    }
                    {!loading &&
                        <>
                            <LPRequestTable transactions={txns} callback={(txn:any)=>{loadTxnDets(txn.transactionId)}} newTransactionFxn={()=>{navigate('/request-lp')}} showDetailsArrow={true} showBroker={true} labelOverrides={{
                                provider:t('common:provider_rates.provider'),
                                clientId:t('common:general.client_id'),
                                broker:t('common:general.broker'),
                                trxAmt:t('common:transaction_history.transaction_amount'),
                                trxId:t('common:transaction_history.transaction_id'),
                                status:t('common:general.status'),
                                date:t('common:general.date'),
                                new:t('common:general.new'),
                                complete:t('common:transaction_statuses.complete'),
                                cancelled:t('common:transaction_statuses.canceled'),
                                confirmedWithLp:t('common:transaction_history.confirmed_with_lp'),
                                sentToLp:t('common:transaction_history.sent_to_lp'),
                                pendingPayment:t('common:transaction_history.pending_payment'),
                                processingAtLp:t('common:transaction_history.processing_at_lp'),
                                emptyMsg:t('common:transaction_history.empty_msg'),
                                beginTrx:t('common:transaction_history.begin_transaction')
                            }}/>
                            <Pagination itemsPerPage={10} items={txnsOri} paginatedItems={(a)=>{setTxns(a)}}/>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default TradingHistoryTable