import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import dayjs from "dayjs";
import {ucFirstAll} from "../helpers";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

interface LogPaymentProps{
    txnInfo:any,
    backBtnDisabledFxn:(a:boolean)=>void
}

const LogPmt = (props:LogPaymentProps) => {
    const {txnInfo,backBtnDisabledFxn} = props
    const [loggingPayment,setLoggingPayment] = useState(false)
    const [stage,setStage] = useState(1)
    const [pmtMthds,setPmtMthds]:any = useState([])
    const [paymentOption,setPaymentOption]:any = useState({})
    const [bankName,setBankName] = useState('')
    const [bankCountryCode,setBankCountryCode] = useState('')
    const [swiftCode,setSwiftCode] = useState('')
    const [payee,setPayee] = useState('')
    const [txnReference,setTxnReference] = useState('')
    const [remarks,setRemarks] = useState('')
    const [chqNo,setChqNo] = useState('')
    const [pymtRes,setPymtRes]:any = useState({})
    const [countries,setCountries]:any = useState([])
    const [disableSubmitBtn,setDisableSubmitBtn] = useState(true)
    const [banks,setBanks]:any = useState([])
    const [bankCode,setBankCode] = useState('')
    const {t,i18n} = useTranslation('common')

    useEffect(()=>{
        if (stage === 2){
            backBtnDisabledFxn(true)
        }
    },[stage])

    useEffect(()=>{
       //form validation
        !bankCountryCode || !bankName || !payee || !txnReference || !remarks || (paymentOption.label.toLowerCase() === 'cheque' && !chqNo) || (paymentOption.label.toLowerCase() !== 'cheque' && !swiftCode) ? setDisableSubmitBtn(true) : setDisableSubmitBtn(false)
    },[bankCountryCode, bankName, payee, txnReference, remarks, paymentOption, chqNo, swiftCode])

    useEffect(()=>{
        //load payment methods
        axiosSend({
            url:'/fx/lov/pmt',
            method:'GET'
        }).then(res => {
            let paymentMethods:any = []
            res.data.data.forEach((pmtMthd:any) => {
                paymentMethods.push({
                    label:ucFirstAll(pmtMthd.pym_desc),
                    value:pmtMthd.pym_code
                })
            })
            setPmtMthds(paymentMethods)
        })

        //load counties
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name){
                        tmp.push({
                            optionName:ucFirstAll(country.cty_name),
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])


    const logPayment = () => { //capture payment from broker to the provider
        setLoggingPayment(true)
        let formBody = {
            action:'cfmCLPay',
            tranxNo:txnInfo.txnNo,
            updRem:remarks,
            jsonParams:[{
                ptp_ptr_ref_no:txnInfo.txnNo,
                ptp_pay_date:dayjs(Date.now()).locale(i18n.language).format('YYYY/MM/DD'),
                ptp_pym_code:paymentOption.value,
                ptp_pay_curr_code:txnInfo.pymtCurr,
                ptp_pay_amount:txnInfo.pymtAmt,
                ptp_payee_name:payee,
                ptp_trd_bank_ctry_code:bankCountryCode,
                ptp_trd_bank_name:bankName,
                bankCode:bankCode,
                ptp_trd_bank_pay_ref_no:txnReference,
                ptp_trd_bank_swift:swiftCode,
                ptp_trd_bank_cheque_no:chqNo,
            }]
        }

        axiosSend({
            url:'/fx/save-actions-symbol',
            method:'POST',
            data:JSON.stringify(formBody)
        }).then(res => {
            setPymtRes(res.data)
            setStage(2)
            setLoggingPayment(false)
        }).catch(err => {
            console.log(err)
            setLoggingPayment(false)
        })

    }

    return (
        <>
            {stage === 1 &&
                <>
                    <div className="mb-[40px]">
                        <h6 className="text-neutral-900 font-bold text-[19px]">{t('common:transaction_history.log_payment')}</h6>
                        <p className="text-neutral-900 text-[14px]">{t('common:general.transaction')} #{txnInfo.txnNo}</p>
                    </div>
                    <div>
                        <div className="form-control rounded-[8px] bg-neutral-50 flex gap-[18px] justify-between items-center mb-[12px]">
                            <div className="flex gap-[4px]">
                                <img src={txnInfo.baseCurrFlag} alt="Base flag"/>
                                <p className="paragraph-md-regular text-neutral-900">{`${txnInfo.pymtCurr} ${txnInfo.pymtAmt}`}</p>
                            </div>
                            <i className="icon-arrows-left-right text-[25px] text-neutral-300"/>
                            <div className="flex gap-[4px]">
                                <img src={txnInfo.exchCurrFlag} alt="Exchange flag"/>
                                <p className="paragraph-md-regular text-neutral-900">{`${txnInfo.exchCurr} ${txnInfo.exchAmt}`}</p>
                            </div>
                        </div>
                        <div className="mb-[12px]">
                            <CountrySelector options={countries} selectedOption={(val)=>{setBankCountryCode(val.optionShortName)}} initialCountry="Select Country" label="Select Bank Country" searchable={true} inputName="country"/>
                        </div>
                        <div className="mb-[12px]">
                            <CountrySelector options={banks} selectedOption={(e)=>{
                                setBankName(e.optionName)
                                setBankCode(e.optionShortName)
                                e.swiftCode ? setSwiftCode(e.swiftCode) : setSwiftCode('')
                            }} placeholder={t('common:liquidity_providers.select_bank')} label={t('common:liquidity_providers.bank_name')} searchable={true} inputName="bankName"/>
                        </div>
                        <div className="mb-[12px]">
                            <DropdownList initialText={t('common:transaction_history.select_payment_method')} disabled={false} options={pmtMthds} label={t('common:transaction_history.payment_method')} returnedSelection={(option)=>{
                                setChqNo('')
                                setSwiftCode('')
                                setPaymentOption(option)
                            }} inputName="paymentMethod"/>
                        </div>
                        {paymentOption.value && paymentOption.label.toLowerCase() !== 'cheque' &&
                        <div className="mb-[12px]">
                            <TextField onChange={(e)=>{setSwiftCode(e.target.value)}} value={swiftCode} placeholder={t('common:liquidity_providers.swift_code')} label={t('common:liquidity_providers.swift_code')} required={true} errMsg={t('common:liquidity_providers.enter_swift')}/>
                        </div>
                        }
                        {paymentOption.value && paymentOption.label.toLowerCase() === 'cheque' &&
                            <div className="mb-[12px]">
                                <TextField onChange={(e)=>{
                                    if (isNaN(Number(e.target.value))) return
                                    setChqNo(e.target.value)
                                }} value={chqNo} required={true} label={t('common:transaction_history.cheque_number')} placeholder={t('common:transaction_history.cheque_number')} errMsg={t('common:transaction_history.enter_cheque_number')}/>
                            </div>
                        }
                        <div className="mb-[12px]">
                            <TextField required={true} onChange={(e)=>{setPayee(e.target.value)}} value={payee} label={t('common:transaction_history.payee_name')} placeholder={t('common:transaction_history.payee_name')} errMsg={t('common:transaction_history.enter_payee_name')}/>
                        </div>
                        <div className="mb-[12px]">
                            <TextField required={true} onChange={(e)=>{setTxnReference(e.target.value)}} value={txnReference} errMsg={t('common:transaction_history.enter_transaction_ref')} label={t('common:transaction_history.bank_transaction_ref')} placeholder={t('common:transaction_history.transaction_ref')}/>
                        </div>
                        <div className="mb-[40px]">
                            <TextArea required={true} onChange={(e)=>{setRemarks(e.target.value)}} value={remarks} style={{height:133}} label={t('common:general.remarks')} placeholder={t('common:general.enter_remarks')}/>
                        </div>
                        <Button type="button" size="lg" variant="primary" disabled={loggingPayment || disableSubmitBtn} onClick={()=>{logPayment()}}>{loggingPayment ? <LoadingSpinner variant={"primary"}/> : t('common:transaction_history.log_payment')}</Button>
                    </div>
                </>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {pymtRes.Status === 'SUCC' &&
                            <ActionSuccess headingText={pymtRes.Message}/>
                        }
                        {pymtRes.Status === 'FAIL' &&
                            <ActionFailed headingText={pymtRes.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if(pymtRes.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(1)
                        }
                    }}>{pymtRes.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default LogPmt
