import {useEffect, useState} from "react";
import HDivider from "../HDivider";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    EmailField,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";

interface EditBrokerProps{
    brokerDets: any
}

const EditBrokerContent = (props:EditBrokerProps) => {
    const {brokerDets} = props
    const [brokerName1,setBrokerName1] = useState(brokerDets.org_name1)
    const [brokerName2,setBrokerName2] = useState(brokerDets.org_name2)
    const [address1,setAddress1] = useState(brokerDets.org_address1)
    const [address2,setAddress2] = useState(brokerDets.org_address2)
    const [address3,setAddress3] = useState(brokerDets.org_address3)
    const [country,setCountry] = useState(brokerDets.org_cty)
    const [vat,setVat] = useState(brokerDets.org_vat_reg)
    const [tin,setTin] = useState(brokerDets.org_tin)
    const [phone,setPhone] = useState(brokerDets.org_mph_no)
    const [email,setEmail] = useState(brokerDets.org_email)
    const [contactName1,setContactName1] = useState(brokerDets.org_con1_name)
    const [contactPhone1,setContactPhone1] = useState(brokerDets.org_con1_mb_no)
    const [contactEmail1,setContactEmail1] = useState(brokerDets.org_con1_email)
    const [contactGrade1,setContactGrade1] = useState(brokerDets.org_con1_grade)
    const [contactName2,setContactName2] = useState(brokerDets.org_con2_name)
    const [contactPhone2,setContactPhone2] = useState(brokerDets.org_con2_mb_no)
    const [contactEmail2,setContactEmail2] = useState(brokerDets.org_con2_email)
    const [contactGrade2,setContactGrade2] = useState(brokerDets.org_con2_grade)
    const [stage,setStage] = useState(1)
    const [resp,setResp]:any = useState({})
    const [processing,setProcessing] = useState(false)
    const [countries,setCountries]:any = useState([])
    const [countryFullName,setCountryFullName]:any = useState({})

    useEffect(()=>{
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name && country.cty_upd_rem){
                        tmp.push({
                            optionName:country.cty_name,
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)

                //get the full name of the current broker country
                let tmpFullCtry = tmp.filter((ctry:any)=>ctry.optionShortName.toLowerCase() === brokerDets.org_cty.toLowerCase())[0]
                if (tmpFullCtry) setCountryFullName(tmpFullCtry)
            }
        })
        // console.log(tmpCntries.filter((country:any)=>country.optionShortName.toLowerCase() !== brokerDets.org_cty.toLowerCase()))
    },[])

    function updateBroker(){
        setProcessing(true)
        let formData = {
            orgCode:brokerDets.org_code,
            orgName1:brokerName1,
            orgName2:brokerName2,
            orgCity:country,
            orgAddress1:address1,
            orgAddress2:address2,
            orgAddress3:address3,
            orgTin:tin,
            orgVat:vat,
            orgPhone:phone,
            orgEmail:email,
            orgContact1Name:contactName1,
            orgContact1Grade:contactGrade1,
            orgContact1Phone:contactPhone1,
            orgContact1Email:contactEmail1,
            orgContact2Name:contactName2,
            orgContact2Grade:contactGrade2,
            orgContact2Phone:contactPhone2,
            orgContact2Email:contactEmail2,
            action:'savOrg'
        }

        axiosSend({
            url:`/fx/admin/org/${props.brokerDets.org_code}`,
            method:"PUT",
            data:formData
        })
            .then(res => {
                setResp(res.data)
                setProcessing(false)
                setStage(2)
            })
    }

  return (
      <>
          {stage === 1 &&
              <form onSubmit={(e)=>{
                  e.preventDefault()
                  updateBroker()
              }}>
                  <div className="flex flex-col gap-[40px]">
                      <div className="flex flex-col gap-[4px]">
                          <h4 className="font-bold text-[19px]">Edit Broker</h4>
                          <p>{`${brokerDets.org_name1} ${brokerDets.org_name2}`}</p>
                      </div>
                      <div className="flex gap-[24px] flex-col">
                          <h6 className="font-bold">Basic Information</h6>
                          <HDivider twBorderColor="border-neutral-900"/>
                          <TextField label="Broker Name #1" value={brokerName1} onChange={(e)=>{setBrokerName1(e.target.value)}} required={true}/>
                          <TextField label="Broker Name #2" value={brokerName2} onChange={(e)=>{setBrokerName2(e.target.value)}}/>
                          <TextField label="Address Line #1" value={address1} required={true} onChange={(e)=>{setAddress1(e.target.value)}}/>
                          <TextField label="Address Line #2" value={address2} onChange={(e)=>{setAddress2(e.target.value)}}/>
                          <TextField label="Address Line #3" value={address3} onChange={(e)=>{setAddress3(e.target.value)}}/>
                          <div className="flex flex-col gap-[12px]">
                              <CountrySelector options={countries} initialCountry={Object.keys(countryFullName).length > 0 ? countryFullName.optionName : 'Select country'} selectedOption={(e)=>{setCountry(e.optionShortName)}} label="Country" inputName="country"/>
                          </div>
                          <TextField label="VAT Number" value={vat} onChange={(e)=>{setVat(e.target.value)}}/>
                          <TextField label="TIN" value={tin} onChange={(e)=>{setTin(e.target.value)}}/>
                          <TextField label="Phone Number" value={phone} required={true} onChange={(e)=>{setPhone(e.target.value)}}/>
                          <EmailField label="Email" value={email} required={true} onChange={(e)=>{setEmail(e.target.value)}}/>
                          {/*CONTACT PERSON 1*/}
                          <div className="flex gap-[24px] flex-col mt-[32px]">
                              <h6 className="font-bold">Contact Person #1</h6>
                              <HDivider twBorderColor="border-neutral-900"/>
                              <TextField label="Name" value={contactName1} onChange={(e)=>{setContactName1(e.target.value)}} required={true}/>
                              <TextField label="Phone Number" value={contactPhone1} onChange={(e)=>{setContactPhone1(e.target.value)}} required={true}/>
                              <TextField label="Email" value={contactEmail1} onChange={(e)=>{setContactEmail1(e.target.value)}} required={true}/>
                              <TextField label="Role" value={contactGrade1} onChange={(e)=>{setContactGrade1(e.target.value)}} required={true}/>
                          </div>
                          {/*CONTACT PERSON 1*/}
                          <div className="flex gap-[24px] flex-col mt-[32px]">
                              <h6 className="font-bold">Contact Person #2</h6>
                              <HDivider twBorderColor="border-neutral-900"/>
                              <TextField label="Name" value={contactName2} onChange={(e)=>{setContactName2(e.target.value)}}/>
                              <TextField label="Phone Number" value={contactPhone2} onChange={(e)=>{setContactPhone2(e.target.value)}}/>
                              <TextField label="Email" value={contactEmail2} onChange={(e)=>{setContactEmail2(e.target.value)}}/>
                              <TextField label="Role" value={contactGrade2} onChange={(e)=>{setContactGrade2(e.target.value)}}/>
                          </div>
                      </div>
                      <Button size="lg" variant="primary" type="submit" disabled={processing}>{processing ? <LoadingSpinner variant={"primary"}/> : 'Update Broker Details'}</Button>
                  </div>
              </form>
          }
          {stage === 2 &&
            <div className="flex flex-col flex-1">
                <div className="flex flex-col flex-1 justify-center items-center">
                    {resp.Status === 'SUCC' &&
                        <ActionSuccess headingText="Broker Updated Successfully"/>
                    }
                    {resp.Status === 'FAIL' &&
                        <ActionFailed headingText={resp.Message}/>
                    }
                </div>
                <Button size="lg" variant="primary" onClick={()=>{
                    if(resp.Status === 'SUCC'){
                        window.location.reload()
                    }else{
                        setStage(stage - 1)
                    }
                }}>Sounds Good</Button>
            </div>
          }
      </>
  )
}

export default EditBrokerContent
