import styled from "styled-components";
import theme from "../../theme";

export const TableContainer = styled.div`
  overflow-x: auto;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  text-align: left;
  .rounded-row td:first-child, .rounded-row th:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .rounded-row td:last-child, .rounded-row th:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rounded-row td:last-child div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rounded-row th div{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: ${theme.colors.white};
  }
  thead tr{
    background: ${theme.colors["neutral-900"]};
    height:57px;
  }
  thead th{
    padding: 11px 16px;
  }
  thead th, thead div{
    color: ${theme.colors.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    font-family: Circular Book Medium, sans-serif;
  }
  tbody tr{
    background: ${theme.colors.white};
    cursor:pointer;
  }
  tbody td{
    padding: 16px;
    color: ${theme.colors["neutral-900"]};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
`

export const NothingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: ${theme.colors.white};
  border-radius: 8px;
`

export const NothingContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
  }
`
