import React, {useState} from "react";
import {axiosSend} from "../../utils/request";
import {Button, ButtonOutline, DisplayField, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface ProvDetsProps {
    provDets:any,
    refreshProvs:()=>void,
    switchView:(a:string)=>void
}

const ProviderDets = (props:ProvDetsProps) => {
    const {provDets,refreshProvs,switchView} = props
    const [provInfo,setProvInfo] = useState(provDets)
    const [loading,setLoading] = useState(false)
    const {t} = useTranslation('common')

    function getProvInfo(){
        axiosSend({
            url:`/fx/admin/liq-prov/${provInfo.lip_code}`,
            method:"GET"
        }).then(res => {
            setProvInfo(res.data.data[0])
            setLoading(false)
        })
    }

    function deactivateProvider(){
        setLoading(true)
        axiosSend({
            url:`/fx/admin/liq-prov/deact/${provInfo.lip_code}`,
            method:'PATCH',
            data:{
                action:'savLiqProvider',
            }
        }).then(() => {
            getProvInfo()
            refreshProvs()
        }).catch(err => {
            console.log(err)
        })
    }

    function activateProvider(){
        setLoading(true)
        axiosSend({
            url:`/fx/admin/liq-prov/act/${provInfo.lip_code}`,
            method:'PATCH',
            data:{
                action:'savLiqProvider',
            }
        }).then(() => {
            getProvInfo()
            refreshProvs()
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="pt-[32px] flex flex-col gap-[40px]">
            <DisplayField label={t('common:provider_dets.provider_name')}>
                {provInfo.lip_name ? provInfo.lip_name : '-'}
            </DisplayField>
            <DisplayField label={t('common:provider_dets.address')}>
                {provInfo.lip_address ? provInfo.lip_address : '-'}
            </DisplayField>
            <DisplayField label={t('common:login.email')}>
                {provInfo.lip_email ? provInfo.lip_email : '-'}
            </DisplayField>
            <DisplayField label={t('common:general.phone')}>
                {provInfo.lip_phone ? provInfo.lip_phone : '-'}
            </DisplayField>
            <DisplayField label={t('common:liquidity_providers.contact_name')}>
                {provInfo.lip_contact_name ? provInfo.lip_contact_name : '-'}
            </DisplayField>
            <DisplayField label={t('common:liquidity_providers.contact_phone')}>
                {provInfo.lip_contact_phone ? provInfo.lip_contact_phone : '-'}
            </DisplayField>
            <DisplayField label={t('common:liquidity_providers.contact_email')}>
                {provInfo.lip_contact_email ? provInfo.lip_contact_email : '-'}
            </DisplayField>
            <DisplayField label={t('common:liquidity_providers.api_url')}>
                {provInfo.lip_curr_rate_api_url ? provInfo.lip_curr_rate_api_url : '-'}
            </DisplayField>
            <div className="flex flex-col gap-[8px]">
                <Button size="lg" variant="primary" type="button" onClick={()=>{switchView('edit-provider')}}>{t('common:general.edit')}</Button>
                <div className="flex gap-[8px]">
                    <div className="w-[310px]">
                        <ButtonOutline size="lg" variant="primary" type="button" onClick={()=>{switchView('manage-banks')}}>{t('common:liquidity_providers.manage_bank_accts')}</ButtonOutline>
                    </div>
                    <div className="flex flex-1">
                        {provInfo.lip_act_stat === 'ACTIVE' &&
                            <ButtonOutline size="lg" variant="warning" type="button" onClick={()=>{deactivateProvider()}} disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.deactivate')}</ButtonOutline>
                        }
                        {provInfo.lip_act_stat === 'INACTIVE' &&
                            <ButtonOutline size="lg" variant="success" type="button" onClick={()=>{activateProvider()}} disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.activate')}</ButtonOutline>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderDets