import DashboardBaseLayout from "../components/DashboardBaseLayout";
import {useContext, useEffect, useState} from "react";
import UserContext from "../utils/userContext";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {axiosSend} from "../utils/request";
import SearchField from "../components/SearchField";
import VDivider from "../components/VDivider";
import {appName} from "../helpers";
import {Button, DropdownList} from "@secondstaxorg/sscomp";
import TradingHistoryTable from "../components/TradingHistoryTable/TradingHistoryTable";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

const TradingHistory = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.transaction_history')}`
    const userDets:any = useContext(UserContext)
    const [txns,setTxns]:any = useState([])
    const [txnsOri,setTxnsOri]:any = useState([])
    const [loading,setLoading] = useState(true)
    const [filter,setFilter] = useState('all')
    const [selDateRange,setSelDateRange] = useState('7days')
    const navigate = useNavigate()

    useEffect(()=>{
        if(Object.keys(userDets).length > 0 && userDets.profile.length > 0){
            let url
            switch (selDateRange){
                case '7days':
                    url = `/all-trnx-list?endDate=${dayjs(new Date()).add(1,'day').locale(i18n.language).format('YYYY-MM-DD')}&startDate=${dayjs(new Date()).subtract(7,'days').locale(i18n.language).format('YYYY-MM-DD')}`
                    break
                case '30days':
                    url = `/all-trnx-list?endDate=${dayjs(new Date()).add(1,'day').locale(i18n.language).format('YYYY-MM-DD')}&startDate=${dayjs(new Date()).subtract(30,'days').locale(i18n.language).format('YYYY-MM-DD')}`
                    break
                case '6months':
                    url = `/all-trnx-list?endDate=${dayjs(new Date()).add(1,'day').locale(i18n.language).format('YYYY-MM-DD')}&startDate=${dayjs(new Date()).subtract(6,'months').locale(i18n.language).format('YYYY-MM-DD')}`
                    break
                case '1year':
                    url = `/all-trnx-list?endDate=${dayjs(new Date()).add(1,'day').locale(i18n.language).format('YYYY-MM-DD')}&startDate=${dayjs(new Date()).subtract(1,'year').locale(i18n.language).format('YYYY-MM-DD')}`
                    break
                case 'alltime':
                    url = `/all-trnx`
                    break
            }

            setLoading(true)
            axiosSend({
                url:url as string,
                method:'GET'
            }).then(res=> {
                if (res.data.data){
                    let tmp:any = []
                    res.data.data.forEach((item:any)=>{
                        tmp.push({
                            providerLogo:item.LP_logos[24],
                            providerName:item.lip_name,
                            broker:item.broker,
                            baseCurrFlag:item.base_curr_flag,
                            baseAmount:item.base_amount,
                            baseCurrency:item.base_curr,
                            exchangeCurrFlag:item.exch_curr_flag,
                            exchangeAmount:item.exch_amount,
                            exchangeCurrency:item.exch_curr,
                            transactionId:item.ref_no,
                            status:item.trans_status,
                            date:dayjs(item.trans_date).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                            clientId: item.client_id
                        })
                    })
                    let srtTmp = tmp.sort((a:any,b:any)=>(b.transactionId - a.transactionId))
                    setTxns(srtTmp)
                    setTxnsOri(srtTmp)
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    },[userDets,selDateRange])

    useEffect(()=>{
            if(filter === 'all'){
                setTxns(txnsOri)
            }else{
                setTxns(txnsOri.filter((txn:any)=>txn.status === filter))
            }
    },[txnsOri,filter])

    const options = [
        {
            label:t('common:transaction_history.sort_by_status'),
            value: 'all'
        },
        {
            label:t('common:transaction_statuses.new'),
            value: 'NW'
        },
        {
            label:t('common:transaction_statuses.pending_payment'),
            value: 'PCP'
        },
        {
            label:t('common:transaction_statuses.sent_to_lp'),
            value: 'PLC'
        },
        {
            label:t('common:transaction_statuses.confirmed_with_lp'),
            value: 'PLP'
        },
        {
            label:t('common:transaction_statuses.processing_at_lp'),
            value: 'PBC'
        },
        {
            label:t('common:transaction_statuses.complete'),
            value: 'ST'
        },
        {
            label:t('common:transaction_statuses.canceled'),
            value: 'CAN'
        }
    ]

    const dateRanges = [
        {
            label:t('common:transaction_history.last_7_days'),
            value:'7days'
        },
        {
            label:t('common:transaction_history.last_30_days'),
            value:'30days'
        },
        {
            label:t('common:transaction_history.last_6_months'),
            value:'6months'
        },
        {
            label:t('common:transaction_history.last_1_year'),
            value:'1year'
        },
        {
            label:t('common:transaction_history.all_time'),
            value:'alltime'
        }
    ]

    let debounce: NodeJS.Timeout | null = null // Declare a debounce timer variable outside the function

    function srchTxn(qry: string) {
        if (!qry) {
            setTxns(txnsOri)
        } else {
            if (debounce) {
                clearTimeout(debounce) // Cancel the previous timer if it exists
            }
            debounce = setTimeout(() => {
                setLoading(true)
                axiosSend({
                    url: `/search?searchID=${qry}`,
                    method: 'GET'
                }).then((res) => {
                    let tmp:any = []
                    res.data.data.forEach((item:any)=>{
                        tmp.push({
                            providerLogo:item.LP_logos[24],
                            providerName:item.lip_name,
                            broker:item.broker,
                            baseCurrFlag:item.base_curr_flag,
                            baseAmount:item.base_amount,
                            baseCurrency:item.base_curr,
                            exchangeCurrFlag:item.exch_curr_flag,
                            exchangeAmount:item.exch_amount,
                            exchangeCurrency:item.exch_curr,
                            transactionId:item.ref_no,
                            status:item.trans_status,
                            date:dayjs(item.trans_date).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                            clientId: item.client_id
                        })
                    })
                    let srtTmp = tmp.sort((a:any,b:any)=>(b.transactionId - a.transactionId))
                    setTxns(srtTmp)
                    setLoading(false)
                });
            }, 1200);
        }

        return () => {
            if (debounce) {
                clearTimeout(debounce)
            }
        };
    }

    function TopLevelButtons () {
        const hideOn = ['uat','prod']

        return (
            <>
                {/*dont show this button in prod and uat*/}
                {hideOn.includes(process.env.REACT_APP_ENVIRONMENT as string) &&
                    <Button
                        size="sm"
                        variant="primary"
                        width="fit-content"
                        onClick={() => navigate("/payment-history")}>
                        {t("common:payment_history.flutterwave_payments")}
                    </Button>
                }
            </>
        )
    }

    return (
        <DashboardBaseLayout pageName="trading-history" topLevelButtons={<TopLevelButtons />}>
            <div className="flex justify-between items-center">
                <h6 className={typography.heading6Bold}>{t('common:transaction_history.lp_requests')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="w-[200px]">
                        <DropdownList options={dateRanges} initialText={selDateRange} returnedSelection={(a:any)=>{setSelDateRange(a.value)}} inputName="dateRanges"/>
                    </div>
                    <div className="w-[200px]">
                        <DropdownList options={options} initialText={filter} returnedSelection={(e:any)=>{
                            setFilter(e.value)
                        }} inputName="sortBy"/>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:transaction_history.search_by')} onChange={(e)=>{srchTxn(e.target.value)}}/>
                </div>
            </div>
            {/*TRANSACTION HISTORY TABLE WITH TRANSACTION DETAILS PANEL*/}
            <TradingHistoryTable transactions={txns} loadingState={loading}/>
        </DashboardBaseLayout>
    )
}

export default TradingHistory