import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface EditFormProps {
    formDets:any,
    showPanelTitle:(a:boolean)=>void
}

const EditAppForm = (props:EditFormProps) => {
    const {formDets,showPanelTitle} = props
    const [name,setName] = useState(formDets.af_form_name)
    const [code,setCode] = useState(formDets.af_form_code)
    const [loading,setLoading] = useState(false)
    const [stage,setStage] = useState(1)
    const [resp,setResp]:any = useState({})
    const {t} = useTranslation('common')

    function updateForm(){
        setLoading(true)
        let formData = {
            formCode:code,
            formName:name,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/app-forms/${formDets.af_form_code}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    updateForm()
                }} className="flex flex-col flex-1 justify-between">
                    <div className="flex flex-col gap-[24px]">
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <TextField label={t('common:app_forms.form_name')} value={name} onChange={(e)=>{setName(e.target.value)}} required={true}/>
                        <TextField label={t('common:app_forms.form_code')} value={code} onChange={(e)=>{setCode(e.target.value)}} required={true}/>
                    </div>
                    <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:app_forms.update_form')}</Button>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:app_forms.app_form_updated')} subText={t('common:app_forms.app_form_updated_msg',{formName:formDets.af_form_name})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button type="button" size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else {
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default EditAppForm