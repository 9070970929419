import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector, EmailField,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";
import { ucFirstAll } from "../../helpers";

interface AddProvProps{
    step:number,
    stepFxn:(a:number)=>void
}
const AddProvider = (props:AddProvProps) => {
    const {stepFxn,step} = props
    const [stage,setStage] = useState(step)
    const [lpName,setLpName] = useState('')
    const [lpPhone,setLpPhone] = useState('')
    const [lpEmail,setLpEmail] = useState('')
    const [lpAddress,setLpAddress] = useState('')
    const [lpRemarks,setLpRemarks] = useState('')
    const [contactName,setContactName] = useState('')
    const [contactPhone,setContactPhone] = useState('')
    const [contactEmail,setContactEmail] = useState('')
    const [apiUrl,setApiUrl] = useState('')
    const [rateDecimal,setRateDecimal] = useState('')
    const [selBank,setSelBank]:any = useState({}) //selected bank
    const [bankCountryText,setBankCountryText] = useState('')
    const [bankCountryFlag,setBankCountryFlag] = useState('')
    const [bankBranch,setBankBranch] = useState('')
    const [bankAddress,setBankAddress] = useState('')
    const [bankCountry,setBankCountry] = useState('')
    const [bankSwift,setBankSwift] = useState('')
    const [acctNo,setAcctNo] = useState('')
    const [acctName,setAcctName] = useState('')
    const [acctCurr,setAcctCurr] = useState('')
    const [errMsg,setErrMsg] = useState('')
    const [countries,setCountries]:any = useState([])
    const [currencies,setCurrencies]:any = useState([])
    const [banks,setBanks]:any = useState([])
    const [resp,setResp]:any = useState({})
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })

        //get countries
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                const activeCountriesCode = ['GH','KE','NG','ZA']
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    // if (country.cty_flag_name && country.cty_upd_rem){
                    if (activeCountriesCode.includes(country.cty_code)){
                        tmp.push({
                            optionName:ucFirstAll(country.cty_name),
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks', //banks list
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        selBank.swiftCode ? setBankSwift(selBank.swiftCode) : setBankSwift('')
        if (selBank.bankCountry){
            let bnkCntry
            bnkCntry = countries.filter((country:any)=>country.optionShortName === selBank.bankCountry)
            setBankCountryText(bnkCntry[0].optionName)
            setBankCountry(bnkCntry[0].optionShortName)
            setBankCountryFlag(bnkCntry[0].optionFlag)
        }
    },[selBank])

    function validateForm(){
        if (stage === 1){
            if (!lpName || !lpPhone || !lpEmail || !lpAddress){
                setErrMsg('Some fields have not been filled')
                return
            }else if (isNaN(Number(lpPhone))){
                setErrMsg('Phone number is invalid')
                return
            }else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 2){
            if (!contactName || !contactEmail || !contactPhone){
                setErrMsg('Some fields have not been filled')
                return
            }else if(isNaN(Number(contactPhone))){
                setErrMsg('Phone number is invalid')
                return
            }else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 3){
            if (!rateDecimal){
                setErrMsg('Forex rate field is no required')
                return
            }else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 4){
            if (!selBank.optionName || !bankBranch || !bankAddress || !bankCountry || !bankSwift || !acctName || !acctNo || !acctCurr){
                setErrMsg('Some fields have not been filled')
                return
            }else if(isNaN(Number(acctNo))){
                setErrMsg('Account number is invalid')
                return
            }else{
                setErrMsg('')
                addProvider()
            }
        }
    }

    function addProvider(){
        setLoading(true)
        let formBody = {
            lpName: lpName,
            lpAddress: lpAddress,
            lpPhone: lpPhone,
            lpEmail: lpEmail,
            lpContactName: contactName,
            lpContactEmail: contactEmail,
            lpContactPhone: contactPhone,
            lpCurrRateApiUrl: apiUrl,
            lpUpdRem: lpRemarks,
            action: 'savLiqProvider',
            prtCurrCode:acctCurr,
            prtBankCtrCode:bankCountry,
            prtBankName:selBank.optionName,
            bankCode:selBank.optionShortName,
            prtBankBranch:bankBranch,
            prtBankSwift:bankSwift,
            prtBankAddress:bankAddress,
            prtBankAccName:acctName,
            prtBankAccNum:acctNo,
            lpDecimalPlaces:rateDecimal
        }

        axiosSend({
            url:'/fx/admin/liq-prov',
            method:"POST",
            data: JSON.stringify(formBody),
        }).then(res => {
            setResp(res.data)
            stepFxn(stage + 1)
            setLoading(false)
        })
    }

    return (
       <>
           {stage === 1 &&
               <form onSubmit={(e)=>{
                   e.preventDefault()
                   validateForm()}} className="flex flex-col flex-1 justify-between gap-[40px]">
                   <div className="flex flex-col flex-1 gap-[40px]">
                       <TextField label="Name" type="text" required={true} placeholder="Enter name" autoFocus={true} value={lpName} onChange={(e)=>{setLpName(e.target.value)}}/>
                       <TextField label="Phone Number" type="text" required={true} placeholder="Enter phone number" value={lpPhone} onChange={(e)=>{
                           if (isNaN(Number(e.target.value))) return
                           setLpPhone(e.target.value)
                       }}/>
                       <EmailField label="Email" required={true} placeholder="Enter email" value={lpEmail} onChange={(e)=>{setLpEmail(e.target.value)}}/>
                       <TextField label="Address" type="text" required={true} placeholder="Enter address" value={lpAddress} onChange={(e)=>{setLpAddress(e.target.value)}}/>
                       <TextArea label="Additional Contact Remarks" style={{height:133}} placeholder="Enter remarks" value={lpRemarks} onChange={(e)=>{setLpRemarks(e.target.value)}}/>
                   </div>
                   <div className="flex flex-col gap-[4px]">
                       {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                       <Button type="submit" size="lg" variant="primary">Confirm & Proceed</Button>
                   </div>
               </form>
           }
           {stage === 2 &&
               <form onSubmit={(e)=>{
                   e.preventDefault()
                   validateForm()}} className="flex flex-col flex-1 justify-between gap-[40px]">
                   <div className="flex flex-col flex-1 gap-[40px]">
                       <TextField label="Name" type="text" required={true} placeholder="Enter name" autoFocus={true} value={contactName} onChange={(e)=>{setContactName(e.target.value)}}/>
                       <TextField label="Phone Number" type="text" required={true} placeholder="Enter phone number" value={contactPhone} onChange={(e)=>{
                           if (isNaN(Number(e.target.value))) return
                           setContactPhone(e.target.value)
                       }}/>
                       <EmailField label="Email" required={true} placeholder="Enter email" value={contactEmail} onChange={(e)=>{setContactEmail(e.target.value)}}/>
                   </div>
                   <div className="flex flex-col gap-[4px]">
                       {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                       <Button type="submit" size="lg" variant="primary">Confirm & Proceed</Button>
                   </div>
               </form>
           }
           {stage === 3 &&
               <form onSubmit={(e)=>{
                   e.preventDefault()
                   validateForm()}} className="flex flex-col flex-1 justify-between gap-[40px]">
                   <div className="flex flex-col flex-1 gap-[40px]">
                       <TextField label="API URL" type="url" placeholder="Enter API URL" value={apiUrl} onChange={(e)=>{setApiUrl(e.target.value)}}/>
                       <TextField label="Forex Rate Decimal Places" type="number" required={true} placeholder="Enter a number" value={rateDecimal} onChange={(e)=>{setRateDecimal(e.target.value)}}/>
                   </div>
                   <div className="flex flex-col gap-[4px]">
                       {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                       <Button type="submit" size="lg" variant="primary">Confirm & Proceed</Button>
                   </div>
               </form>
           }
           {stage === 4 &&
               <form onSubmit={(e)=>{
                   e.preventDefault()
                   validateForm()}} className="flex flex-col flex-1 justify-between gap-[40px]">
                   <div className="flex flex-col flex-1 gap-[40px]">
                       <CountrySelector options={banks} selectedOption={(e)=>{setSelBank(e)}} placeholder="Select bank" label="Bank Name" searchable={true} inputName="bankName"/>
                       <TextField label="Branch" type="text" placeholder="Enter bank branch" required={true} value={bankBranch} onChange={(e)=>{setBankBranch(e.target.value)}}/>
                       <TextField label="Address" type="text" placeholder="Enter bank address" required={true} value={bankAddress} onChange={(e)=>{setBankAddress(e.target.value)}}/>
                       <CountrySelector options={countries} selectedOption={(e)=>{setBankCountry(e.optionShortName)}} initialCountry={bankCountryText} placeholder="Select bank country" label="Bank Country" inputName="bankCountry"/>
                       <TextField label="SWFIT Code" type="text" required={true} placeholder="Enter SWIFT code" value={bankSwift} onChange={(e)=>{setBankSwift(e.target.value)}}/>
                       <TextField label="Account Number" type="text" required={true} placeholder="Enter account number" value={acctNo} onChange={(e)=>{
                           if (isNaN(Number(e.target.value))) return
                           setAcctNo(e.target.value)
                       }}/>
                       <TextField label="Name on Account" type="text" required={true} placeholder="Enter name on account" value={acctName} onChange={(e)=>{setAcctName(e.target.value)}}/>
                       <CountrySelector options={currencies} selectedOption={(e)=>{setAcctCurr(e.optionShortName)}} placeholder="Select account currency" label="Account Currency" searchable={true} inputName="currency"/>
                   </div>
                   <div className="flex flex-col gap-[4px]">
                       {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                       <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : 'Confirm & Add Provider'}</Button>
                   </div>
               </form>
           }
           {stage === 5 &&
               <div className="flex flex-col flex-1">
                   {Object.keys(resp).length > 0 &&
                       <>
                           <div className="flex-1 flex flex-col justify-center items-center">
                               {resp.Status === 'SUCC' &&
                                   <ActionSuccess headingText="Liquidity Provider Added" subText={`${lpName} was added successfully`}/>
                               }
                               {resp.Status === 'FAIL' &&
                                   <ActionFailed headingText="An error occurred" subText={resp.Message}/>
                               }
                               {resp.Status === 'ERR' &&
                                   <ActionFailed headingText="An unexpected error occurred" subText="If the situation persists, you may report it as a bug."/>
                               }
                           </div>
                           <Button size="lg" variant="primary" onClick={()=>{
                               if (resp.Status === 'SUCC'){
                                   window.location.reload()
                               }else {
                                   stepFxn(stage - 1)
                               }
                           }}>{resp.Status === 'SUCC' ? 'Sounds Good' : 'Okay'}</Button>
                       </>
                   }
               </div>
           }
       </>
    )
}

export default AddProvider