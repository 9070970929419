import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ucFirstAll} from "../../helpers";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AddBankProps{
    providerId:string,
    showPanelTitle:(a:boolean)=>void,
    step:number
    stepFxn:(a:number)=>void
}

const AddProviderBank = (props:AddBankProps) => {
    const {providerId,showPanelTitle,stepFxn,step} = props
    const [countries,setCountries]:any = useState([])
    const [currencies,setCurrencies]:any = useState([])
    const [selBank,setSelBank]:any = useState({})
    const [bankCountryText,setBankCountryText] = useState('')
    const [bankCountryFlag,setBankCountryFlag] = useState('')
    const [country,setCountry] = useState('')
    const [branch,setBranch] = useState('')
    const [acctNo,setAcctNo] = useState('')
    const [acctName,setAcctName] = useState('')
    const [currency,setCurrency] = useState('')
    const [swift,setSwift] = useState('')
    const [address,setAddress] = useState('')
    const [main,setMain] = useState('N')
    const [loading,setLoading] = useState(false)
    const [stage,setStage] = useState(step)
    const [resp,setResp]:any = useState({})
    const [errMsg,setErrMsg] = useState('')
    const [banks,setBanks]:any = useState([])
    const {t} = useTranslation('common')

    useEffect(()=>{
        setStage(step)
    },[step])

    const options = [
        {
            label:t('common:general.yes'),
            value:'Y'
        },
        {
            label:t('common:general.no'),
            value:'N'
        }
    ]

    useEffect(()=>{
        //load counties
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name && country.cty_upd_rem){
                        tmp.push({
                            optionName:ucFirstAll(country.cty_name),
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }

                })
                setCountries(tmp)
            }
        }).catch(err=>{
            console.log(err)
        })

        //load currencies
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        selBank.swiftCode ? setSwift(selBank.swiftCode) : setSwift('')
        if (selBank.bankCountry){
            let bnkCntry
            bnkCntry = countries.filter((country:any)=>country.optionShortName === selBank.bankCountry)
            setBankCountryText(ucFirstAll(bnkCntry[0].optionName))
            setCountry(bnkCntry[0].optionShortName)
            setBankCountryFlag(bnkCntry[0].optionFlag)
        }
    },[selBank])

    function addBankAcct(){
        if (!selBank || !branch || !country || !acctName || !acctNo || !currency || !swift || !address){
            setErrMsg(t('common:general.some_required_fields_empty'))
            return
        }else{
            setErrMsg('')
        }

        let formData = {
            prtCode:providerId,
            prtCurrCode:currency,
            prtBankCtrCode:country,
            prtBankName:selBank.optionName,
            bankCode:selBank.optionShortName,
            prtBankBranch:branch,
            prtBankSwift:swift,
            prtBankAddress:address,
            prtBankAccName:acctName,
            prtBankAccNum:acctNo,
            prtMainAcc:main,
            action:'savPrtAcc'
        }

        setLoading(true)
        axiosSend({
            url:`/fx/admin/part-acc-v2`,
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            stepFxn(stage+1)
            setLoading(false)
            showPanelTitle(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    addBankAcct()
                }}>
                    <div className="flex flex-col gap-[40px]">
                        <CountrySelector options={banks} selectedOption={(e)=>{setSelBank(e)}} label={t('common:liquidity_providers.bank_name')} placeholder={t('common:liquidity_providers.select_bank')} searchable={true} inputName="bankName"/>
                        <TextField type="text" required={true} placeholder={t('common:liquidity_providers.enter_bank_branch')} label={t('common:general.branch')} value={branch} onChange={(e)=>{setBranch(e.target.value)}}/>
                        <CountrySelector options={countries} selectedOption={(e)=>{setCountry(e.optionShortName)}} placeholder={t('common:liquidity_providers.select_country')} label={t('common:general.country')} initialCountry={bankCountryText} inputName="bankCountry"/>
                        <TextField type="text" required={true} placeholder={t('common:liquidity_providers.enter_acct_number')} label={t('common:liquidity_providers.acct_number')} value={acctNo} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setAcctNo(e.target.value)
                        }}/>
                        <TextField type="text" required={true} placeholder={t('common:liquidity_providers.enter_account_name')} label={t('common:liquidity_providers.account_name')} value={acctName} onChange={(e)=>{setAcctName(e.target.value)}}/>
                        <CountrySelector options={currencies} selectedOption={(e)=>{setCurrency(e.optionShortName)}} placeholder={t('common:general.select_currency')} label={t('common:general.currency')} inputName="currency"/>
                        <TextField type="text" required={true} placeholder={t('common:liquidity_providers.enter_swift')} label={t('common:liquidity_providers.swift_code')} value={swift} onChange={(e)=>{setSwift(e.target.value)}}/>
                        <TextField type="text" required={true} placeholder={t('common:liquidity_providers.enter_bank_address')} label={t('common:liquidity_providers.bank_address')} value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                        <DropdownList options={options} placeholder={t('common:general.no')} returnedSelection={(e)=>{setMain(e.value)}} label={t('common:liquidity_providers.main_account')} inputName="mainAccount"/>
                        <div className="flex flex-col gap-[4px]">
                            {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                            <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:liquidity_providers.confirm_add_bank')}</Button>
                        </div>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:liquidity_providers.bank_added')} subText={`${selBank.optionName} ${t('common:general.was_added')}`}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={t('common:general.error_occurred')} subText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            stepFxn(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default AddProviderBank