import React from "react";
import PreLoginContent from "../components/PreLoginContent";
import {useState} from "react";
import {apiUrl, appName} from "../helpers";
import {SetPassword} from "@secondstaxorg/sscomp";

const PasswordReset = () => {
    document.title = `${appName} - Reset Password`
    const [pwResp,setPwResp]:any = useState({})

    function redirectUser(){
        window.location.replace(pwResp.Link || '/')
    }

    return (
        <PreLoginContent>
            <div className="flex justify-center items-center" style={{minHeight: "100vh", paddingTop: "91px", paddingBottom: "15px"}}>
                <SetPassword pwResetUrl={`${apiUrl}/fx/admin/users/reset-pwd`} redirectFn={redirectUser} pwResetResponse={setPwResp}/>
            </div>
        </PreLoginContent>
    )
}

export default PasswordReset
