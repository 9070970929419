import HDivider from "../HDivider";
import {useEffect, useRef, useState} from "react";
import {AnimatePresence,motion} from "framer-motion";
import axios from "axios";
import html2canvas from "html2canvas";
import {ActionSuccess, Button, LoadingSpinner, TextArea} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface ReportBugProps{
    clsFxn:(a:boolean)=>void,
    loggedInUser:any
}

const BugReportingForm = (props:ReportBugProps) => {
    const {clsFxn,loggedInUser} = props
    const [stage,setStage] = useState(1)
    const [sending,setSending] = useState(false)
    const bugReportingRef:any = useRef(null)
    const [pageUrl,setPageUrl] = useState('')
    const [bugDesc,setBugDesc] = useState('')
    const [errMsg,setErrMsg] = useState('')
    const {t} = useTranslation('common')


    useEffect(()=>{
        setPageUrl(window.location.href)

        //handling click outside to close for bug-reporting form
        function handler(e:MouseEvent){
            if(bugReportingRef.current){
                if (!bugReportingRef.current.contains(e.target)) {
                    setTimeout(()=>{
                        clsFxn(false)
                    },10)
                }
            }
        }

        window.addEventListener('mouseup',handler)
        return ()=>window.removeEventListener('mouseup',handler)
    },[])

    const submitBug = async () => {
        if (!bugDesc){
            setErrMsg('Type a description of the bug')
            return
        }else{
            setErrMsg('')
        }

        //SEQUENCE TO CAPTURE SCREENSHOT
        //momentarily hide the bug reporter form to capture the screenshot
        const brCard = (document as any).querySelector('#brf').classList
        const screenshotLayer = document.querySelector('#sslayer') as HTMLElement

        brCard.add('hidden')
        //wait 300ms and then do screenshot effect
        setTimeout(()=>{
            screenshotLayer.classList.add('sseffect')
        },300)

        let pageBody = document.querySelector('body') as HTMLElement
        html2canvas(pageBody,{
            useCORS:true,
            width:window.innerWidth,
            height:window.innerHeight
        }).then(async canvas => {
            const img = canvas.toDataURL()
            const res = await fetch(img)
            const buff = await res.arrayBuffer()
            const file = new File([buff],`attached-screenshot.jpg`,{
                type:'image/jpeg'
            })

            //show the bug reporter form again after capturing the screenshot
            setTimeout(()=>{
                screenshotLayer.classList.remove('sseffect')
                brCard.remove('hidden')
            },300)

            setSending(true)
            let formData = new FormData()
            formData.append('name',loggedInUser.user_name)
            formData.append('email',loggedInUser.user_email)
            formData.append('url',pageUrl)
            formData.append('description',bugDesc)
            formData.append('phoneNo',loggedInUser.user_phone_no)
            formData.append('org',loggedInUser.user_org_name)
            formData.append('bugScreenshot',file)
            formData.append('app','LP')
            axios.post('https://docs.uat.secondstax.com/api/send-bug',formData,{
                withCredentials:false
            })
                .then(res => {
                    setSending(false)
                    setStage(stage + 1)
                    //close modal 3 seconds after success message
                    setTimeout(()=>{
                        clsFxn(false)
                    },3000)
                }).catch((error) => {
                console.log(error)
                setSending(false)
            })
        })
    }

    return (
        <AnimatePresence>
            <motion.div ref={bugReportingRef} id="brf" className={`top-nav-card w-[430px] min-h-[295px] relative ${stage === 2 ? 'flex items-center justify-center' : ''}`}
                        animate={{
                            y:0,
                            opacity:1
                        }}
                        initial={{
                            y:-10,
                            opacity:0
                        }}
                        exit={{
                            y:-10,
                            opacity:0
                        }}
                        transition={{
                            duration:0.2,
                            ease:"easeOut"
                        }}
            >
                {stage === 1 &&
                    <>
                        <div className="py-[12px] px-[16px]">
                            <p className="paragraph-md-regular font-bold">{t('common:dashboard.report_bug')}</p>
                        </div>
                        <HDivider twBorderColor="border-neutral-100"/>
                        <canvas id="screenshot" style={{width:0,height:0}}/>
                        <div className="p-[16px] flex flex-col gap-[16px]">
                            <TextArea placeholder="Describe the bug" style={{height:108}} value={bugDesc} onChange={(e)=>{setBugDesc(e.target.value)}}/>
                            <label className="flex items-start gap-[8px]">
                                <i className="icon-info-circle"/>
                                <p className="text-[14px] leading-[17px]">{t('common:dashboard.report_bug_msg')}</p>
                            </label>
                            <img src="" alt="" id="sspreview"/>
                            <div>
                                {errMsg && <p className="text-error-500 text-[14px] mb-[4px]">{errMsg}</p>}
                                <Button size="md" variant="primary" disabled={sending || !bugDesc} onClick={()=>{submitBug()}}>{sending ? <LoadingSpinner variant={"primary"}/> : t('common:dashboard.submit_report')}</Button>
                            </div>
                        </div>
                    </>
                }
                {stage === 2 &&
                    <div className="flex justify-center items-center h-[295px]">
                        <ActionSuccess subText={t('common:dashboard.bug_report_sent')}/>
                    </div>
                }
            </motion.div>
        </AnimatePresence>
    )
}

export default BugReportingForm
