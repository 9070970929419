import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    DropdownList,
    LoadingSpinner,
    TextField,
    UpdateUserActions
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AddRoleProps{
    stepFxn:(a:number)=>void
    step:number
}

const AddUserRole = (props:AddRoleProps) => {
    const {stepFxn,step} = props
    const [stage,setStage] = useState(step)
    const [name,setName] = useState('')
    const [code,setCode] = useState('')
    const [clearance,setClearance] = useState('1')
    const [errMsg,setErrMsg] = useState('')
    const [roleActions,setRoleActions]:any = useState([])
    const [roleForms,setRoleForms]:any = useState([])
    const [roleApps,setRoleApps]:any = useState([])
    const [selRoleActions,setSelRoleActions]:any = useState([])
    const [selRoleForms,setSelRoleForms]:any = useState([])
    const [selRoleApps,setSelRoleApps]:any = useState([])
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const {t} = useTranslation('common')


    useEffect(()=>{
        axiosSend({
            url:'/fx/admin/app-actions',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.aa_action_title,
                        optionId:action.aa_action_code
                    })
                })
                setRoleActions(tmp)
            }
        })
        axiosSend({
            url:'/fx/admin/app-forms',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.af_form_name,
                        optionId:action.af_form_code
                    })
                })
                setRoleForms(tmp)
            }
        })
        axiosSend({
            url:'/fx/admin/apps',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.app_name,
                        optionId:action.app_code
                    })
                })
                setRoleApps(tmp)
            }
        })
    },[])

    useEffect(()=>{
        setStage(step)
    },[step])

    const dropdownOptions = [
        {
            label:'1',
            value:'1'
        },
        {
            label:'2',
            value:'2'
        },
        {
            label:'3',
            value:'3'
        },
        {
            label:'4',
            value:'4'
        },
        {
            label:'5',
            value:'5'
        }
    ]

    function validateForm(){
        // console.log(stage)
        // return
        if (stage === 1){
            if (!name || !code || !clearance){
                setErrMsg(t('common:general.some_required_fields_empty'))
                return null
            } else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 2){
            if (selRoleActions.length === 0){
                setErrMsg(t('common:user_roles.you_must_assign_action'))
                return null
            } else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 3){
            if (selRoleForms.length === 0){
                setErrMsg(t('common:user_roles.you_must_assign_role'))
                return null
            } else{
                setErrMsg('')
                stepFxn(stage + 1)
            }
        }
        if (stage === 4){
            if (selRoleApps.length === 0){
                setErrMsg(t('common:user_roles.you_must_assign_app'))
                return null
            } else{
                setErrMsg('')
                submitForm()
            }
        }
    }

    function submitForm(){
        setLoading(true)
        let formData = {
            roleCode:code,
            roleName:name,
            roleClrLvl:clearance,
            addRoleActions:selRoleActions,
            addRoleForms:selRoleForms,
            addRoleApps:selRoleApps,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/roles/`,
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            stepFxn(5)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    validateForm()
                }} className="flex flex-col flex-1 gap-[40px]">
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TextField label={t('common:user_roles.role_name')} value={name} onChange={(e)=>{setName(e.target.value)}} required={true}/>
                        <TextField label={t('common:user_roles.role_code')} value={code} onChange={(e)=>{setCode(e.target.value)}} required={true}/>
                        <DropdownList label={t('common:user_roles.clearance_level')} options={dropdownOptions} returnedSelection={(e)=>{setClearance(e.value)}} initialText={clearance} inputName="clearanceLevel"/>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="submit" size="lg" variant="primary">{t('common:general.confirm_proceed')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleActions} currentOptions={selRoleActions} selOptions={(e)=>{setSelRoleActions(e.added)}} titleAvailable={t('common:user_roles.available_actions')} titleCurrent={t('common:user_roles.selected_actions')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}}>{t('common:general.confirm_proceed')}</Button>
                    </div>
                </div>
            }
            {stage === 3 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleForms} currentOptions={selRoleForms} selOptions={(e)=>{setSelRoleForms(e.added)}} titleAvailable={t('common:user_roles.available_forms')} titleCurrent={t('common:user_roles.selected_forms')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}}>{t('common:general.confirm_proceed')}</Button>
                    </div>
                </div>
            }
            {stage === 4 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleApps} currentOptions={selRoleApps} selOptions={(e)=>{setSelRoleApps(e.added)}} titleAvailable={t('common:user_roles.available_apps')} titleCurrent={t('common:user_roles.selected_apps')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}} disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.confirm_proceed')}</Button>
                    </div>
                </div>
            }
            {stage === 5 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:user_roles.role_added',{name:name})} subText={t('common:user_roles.role_added_msg',{name:name})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if(resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(stage - 1)
                        }
                    }}>{t('common:general.sounds_good')}</Button>
                </div>
            }
        </>
    )
}

export default AddUserRole