import styled from "styled-components";
import theme from "../../theme";

export const Container = styled.div`
  padding: 24px;
  //border-radius: 8px;
  gap: 24px;
  display: grid;
  grid-template-columns: calc(100%/5) auto auto auto calc(100%/5);
  //grid-template-columns: calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5);
  align-items: end;
  //background: ${theme.colors.white};
`
