import {ButtonOutline} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const UnauthorisedAccess = () => {
    const {t} = useTranslation('common')

    return (
        <div className="flex justify-center items-center flex-col flex-1 h-full">
            <div className="flex flex-col items-center gap-[16px]">
                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1603_31524)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M30.681 8.568C31.281 8.523 31.888 8.5 32.5 8.5C45.746 8.5 56.5 19.254 56.5 32.5C56.5 45.746 45.746 56.5 32.5 56.5C19.254 56.5 8.5 45.746 8.5 32.5C8.5 31.888 8.52299 31.281 8.56799 30.681C9.42399 41.21 18.251 49.5 29 49.5C40.314 49.5 49.5 40.314 49.5 29C49.5 18.251 41.21 9.424 30.681 8.568Z"
                              fill="#FFD8D8"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M22.6011 25.429L39.5717 42.3996C40.3523 43.1802 41.62 43.1802 42.4007 42.3996C43.1813 41.619 43.1813 40.3513 42.4007 39.5706L25.4301 22.6C24.6495 21.8194 23.3818 21.8194 22.6011 22.6C21.8205 23.3807 21.8205 24.6484 22.6011 25.429Z"
                              fill="#FFB0B0"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M39.569 22.6011L22.5984 39.5717C21.8178 40.3523 21.8178 41.62 22.5985 42.4007C23.3791 43.1813 24.6468 43.1813 25.4274 42.4007L42.398 25.4301C43.1786 24.6495 43.1786 23.3818 42.398 22.6011C41.6174 21.8205 40.3497 21.8205 39.569 22.6011Z"
                              fill="#FFB0B0"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M48.0528 48.0561C39.4673 56.6415 25.526 56.6415 16.9406 48.0561C8.3552 39.4707 8.35521 25.5294 16.9406 16.944C25.526 8.35854 39.4673 8.35853 48.0528 16.9439C54.8206 23.7118 56.2546 33.8076 52.3523 41.9848C51.8762 42.9819 52.2996 44.1758 53.2949 44.6518C54.2911 45.127 55.4867 44.7035 55.9619 43.7074C60.5728 34.0443 58.88 22.1132 50.8817 14.115C40.735 3.96826 24.2583 3.96825 14.1116 14.1149C3.96492 24.2616 3.96493 40.7384 14.1116 50.8851C24.2583 61.0318 40.735 61.0318 50.8817 50.8851C51.6624 50.1044 51.6624 48.8367 50.8817 48.0561C50.1011 47.2754 48.8334 47.2754 48.0528 48.0561Z"
                              fill="#FFB0B0"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1603_31524">
                            <rect width="64" height="64" fill="white" transform="translate(0.5 0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
                <p>{t('common:general.unauthorised')}</p>
                <div className="w-fit">
                    <ButtonOutline size="sm" variant="primary" withBg={true} type="button" onClick={() => {
                        window.location.replace('/dashboard')
                    }}>{t('common:general.go_back')}</ButtonOutline>
                </div>
            </div>
        </div>
    )
}

export default UnauthorisedAccess