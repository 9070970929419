import {appName} from "../helpers";
import {ActionFailed, ActionSuccess} from "@secondstaxorg/sscomp";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import { axiosSend } from "../utils/request";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

const PaymentRedirect = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.transaction_history')}`

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            html, body, #root {
                height: 100%;
            }
        `;
        document.head.appendChild(style);
      }, []);

    const query = useQuery();
    const tx_ref = query.get('tx_ref');

    const [stage, setStage] = useState(0)

    useEffect(()=>{
        const interval = setInterval(() => {
            axiosSend({
                url:'/mf/verify-payment-status?tx_ref=' + tx_ref,
                method:'GET',
            }).then(res => {
                clearInterval(interval)

                if (res.data.Status === 'SUCCESS')
                    setStage(1)
                else
                    setStage(2)
            }).catch(err => {
                console.log(err)
            })
        }, 2000);

        return () => clearInterval(interval);
    }, [tx_ref])

    return <>
        {stage === 0 && <>
            <div className="flex flex-col h-full items-center justify-center bg-slate-200">
                <p>{t("common:payment_history.checking_payment_status")}</p>
            </div>
        </>}
        {stage === 1 && <>
            <div className="flex flex-col h-full items-center justify-center bg-slate-200">
                <ActionSuccess
                    headingText={t("common:payment_history.payment_confirmed")}
                    subText={t("common:payment_history.payment_confirmed_subtitle")}
                />
            </div>
        </>}
        {stage === 2 && <>
            <div className="flex flex-col h-full items-center justify-center bg-slate-200">
                <ActionFailed
                    headingText={t("common:payment_history.payment_failed")}
                    subText={t("common:payment_history.payment_failed_subtitle")}
                />
            </div>
        </>}
    </>
}

export default PaymentRedirect