import {useNavigate} from "react-router-dom";
import {ButtonOutline} from "@secondstaxorg/sscomp";

const PageNotFound = () => {
    const navigate = useNavigate()

    return (
        <main className="absolute inset-0 flex flex-col text-center justify-center items-center">
            <div className="w-[333px] flex flex-col gap-[16px] justify-center items-center">
                <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2107_35315)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M30.681 8.068C31.281 8.023 31.888 8 32.5 8C45.746 8 56.5 18.754 56.5 32C56.5 45.246 45.746 56 32.5 56C19.254 56 8.5 45.246 8.5 32C8.5 31.388 8.52299 30.781 8.56799 30.181C9.42399 40.71 18.251 49 29 49C40.314 49 49.5 39.814 49.5 28.5C49.5 17.751 41.21 8.924 30.681 8.068Z" fill="#E8EEF7"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M22.5994 24.9293L39.57 41.8999C40.3506 42.6805 41.6183 42.6805 42.3989 41.8999C43.1796 41.1192 43.1796 39.8516 42.399 39.0709L25.4284 22.1003C24.6477 21.3197 23.38 21.3197 22.5994 22.1003C21.8187 22.881 21.8187 24.1487 22.5994 24.9293Z" fill="#D2DDEE"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M39.5699 22.1007L22.5993 39.0713C21.8187 39.8519 21.8187 41.1196 22.5993 41.9003C23.38 42.6809 24.6476 42.6809 25.4283 41.9003L42.3989 24.9297C43.1795 24.1491 43.1795 22.8814 42.3989 22.1007C41.6182 21.3201 40.3505 21.3201 39.5699 22.1007Z" fill="#D2DDEE"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M48.0563 47.556C39.4709 56.1414 25.5296 56.1414 16.9442 47.556C8.35877 38.9706 8.35878 25.0293 16.9442 16.4439C25.5296 7.85848 39.4709 7.85847 48.0563 16.4439C54.8242 23.2117 56.2582 33.3075 52.3558 41.4848C51.8798 42.4818 52.3032 43.6757 53.2985 44.1517C54.2947 44.6269 55.4903 44.2035 55.9655 43.2073C60.5764 33.5443 58.8835 21.6131 50.8853 13.6149C40.7386 3.4682 24.2619 3.46819 14.1152 13.6149C3.96849 23.7616 3.9685 40.2383 14.1152 50.385C24.2619 60.5317 40.7386 60.5317 50.8853 50.385C51.6659 49.6044 51.666 48.3367 50.8853 47.556C50.1047 46.7754 48.837 46.7754 48.0563 47.556Z" fill="#D2DDEE"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2107_35315">
                            <rect width="64" height="64" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
                <h2 className="text-[23px] font-[700]">Lost in Cyberspace</h2>
                <p>Oops! The page you're looking for doesn't seem to exist.</p>
                <div className="w-fit">
                    <ButtonOutline size="sm" variant="primary" type="button" onClick={(e)=>{navigate('/dashboard')}}>Go to Dashboard</ButtonOutline>
                </div>
            </div>
        </main>
    )
}

export default PageNotFound