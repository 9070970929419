import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, CountrySelector, LoadingSpinner, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface CurrPairProps {
    showPanelTitle:(a:boolean)=>void
}

const AddCurrPair = (props:CurrPairProps) => {
    const {showPanelTitle} = props
    const [baseCurr,setBaseCurr]:any = useState({})
    const [exchangeCurr,setExchangeCurr]:any = useState({})
    const [pairName,setPairName] = useState('')
    const [currencies,setCurrencies]:any = useState([])
    const [errMsg,setErrMsg] = useState('')
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [combPair,setCombPair] = useState('')
    const [disabled,setDisabled] = useState(true)
    const {t} = useTranslation('common')

    useEffect(()=>{
        //load currencies
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },[])

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    useEffect(()=>{
        if (Object.keys(baseCurr).length > 0 && Object.keys(exchangeCurr).length > 0){
            if (baseCurr.optionShortName === exchangeCurr.optionShortName){
                setDisabled(true)
                setErrMsg(t('common:currency_pairs.base_exchange_curr_cant_be_same'))
                setCombPair('')
                setPairName('')
            }else{
                setErrMsg('')
                setPairName(`${baseCurr.optionName} to ${exchangeCurr.optionName}`)
                setCombPair(`${baseCurr.optionShortName}/${exchangeCurr.optionShortName}`) //combined pair
                setDisabled(false)
            }
        }

        if (Object.keys(baseCurr).length === 0 || Object.keys(exchangeCurr).length === 0){
            setDisabled(true)
            setCombPair('')
            setPairName('')
        }

    },[baseCurr,exchangeCurr])

    function updateCurr(){
        //validation
        if (baseCurr.optionShortName && exchangeCurr.optionShortName && baseCurr.optionShortName === exchangeCurr.optionShortName){
            setErrMsg(t('common:currency_pairs.base_exchange_curr_cant_be_same'))
            return
        }else if(!baseCurr.optionShortName || !exchangeCurr.optionShortName){
            setErrMsg(t('common:currency_pairs.both_fields_required'))
            return
        }else if (!pairName){
            setErrMsg(t('common:currency_pairs.pair_name_required'))
            return
        }else{
            setErrMsg('')
        }

        setLoading(true)
        let formData = {
            pairCode:combPair,
            pairName:pairName,
            action:'codeMaintenance'
        }

        axiosSend({
            url:'/fx/admin/currency-pairs',
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    updateCurr()
                }} className="flex flex-col flex-1">
                    <div className="flex flex-col gap-[40px] flex-1">
                        <CountrySelector options={currencies} initialCountry={t('common:currency_pairs.select_base_currency')} selectedOption={(val)=>{setBaseCurr(val)}} label={t('common:request_lp.base_currency')} inputName="baseCurrency"/>
                        <CountrySelector options={currencies} initialCountry={t('common:currency_pairs.select_exchange_currency')} selectedOption={(val)=>{setExchangeCurr(val)}} label={t('common:request_lp.exchange_currency')} inputName="exchangeCurrency"/>
                        <TextField label={t('common:currency_pairs.pair_name')} placeholder={t('common:currency_pairs.pair_name')} value={pairName} onChange={(e)=>{setPairName(e.target.value)}} required/>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="submit" size="lg" variant="primary" disabled={loading || disabled}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:currency_pairs.add_currency_pair')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:currency_pairs.curr_pair_added')} subText={t('common:currency_pairs.pair_added_successfully',{combPair:combPair})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={t('common:general.error_occurred')} subText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default AddCurrPair