import {SearchProps} from "./type";

const SearchField = (props:SearchProps) => {
    return (
        <div className="form-group-neutral-border w-[295px]" style={{margin:0}}>
            <input type="text" className="form-control" {...props}/>
            <span className="form-control-icon"><i className="icon-search"/></span>
        </div>
    )
}

export default SearchField
