import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useEffect, useState} from "react";
import {exportExcel, exportExcelMulti, exportPDF, getCurrFlag, moneyfy} from "../../helpers";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import dayjs from "dayjs";
import UserContext from "../../utils/userContext";
import UnauthorisedAccess from "../../components/UnauthorisedAccess";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, DropdownList, LoadingSpinner, TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const SumLpTransactionsByCurr = () => {
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [selProvider,setSelProvider]:any = useState('')
    const [providerList,setProviderList]:any[] = useState([])
    const [errMsg,setErrMsg] = useState('')
    const [emptyData,setEmptyData] = useState(false)
    const [loading,setLoading] = useState(false)
    const [currSums,setCurrSums]:any[] = useState([])
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const userDets:any = useContext(UserContext)
    const [reportPeriod,setReportPeriod] = useState('')
    const [repGrpData,setRepGrpData]:any = useState([])
    const [selReportLp,setSelReportLp] = useState('')
    const {t} = useTranslation('common')

    useEffect(()=>{
        axiosSend({
            url:`/fx/admin/liq-prov`,
            method:'GET'
        }).then(res => {
            if(res.data.data){
                let tempBrkr:any[] = []
                tempBrkr.push({
                    label:t('common:general.all'),
                    value:'all'
                })
                res.data.data.forEach((provider:any)=>{
                    tempBrkr.push({
                        label:`${provider.lip_name}`,
                        value:provider.lip_code
                    })
                })
                setProviderList(tempBrkr)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        setCurrSums([])
        setRepGrpData([])
    },[selProvider])

    function generateReport(){
        if (!startDate || !endDate || !selProvider){
            setErrMsg(t('common:reports.select_broker_msg'))
            return
        }else{
            setErrMsg('')
        }
        setLoading(true)
        axiosSend({
            url:`/fx/reports/lp-base-trx?startDate=${startDate}&endDate=${endDate}&lipCode=${selProvider}`,
            method:'GET'
        }).then(res => {
            if (res.data.data){
                if (selProvider !== 'all'){
                    let temp:any[] = []
                    res.data.data[0].transactions.forEach((trx:any)=>{
                        temp.push({
                            currency:trx.ptr_source_cur_code,
                            amount:trx.amount,
                            currencyName:trx.cur_name,
                            flag:getCurrFlag(trx.ptr_source_cur_code)
                        })
                    })
                    setCurrSums(temp)
                    setSelReportLp(res.data.data[0].lp_name)
                }else{
                    let groupArr:any[] = []
                    res.data.data.map((data:any) => {
                        let tempData:any[] = []
                        let providerName = data.lp_name
                        data.transactions.map((trx:any) => {
                            tempData.push({
                                currency:trx.ptr_source_cur_code,
                                amount:trx.amount,
                                currencyName:trx.cur_name,
                                flag:getCurrFlag(trx.ptr_source_cur_code)
                            })
                        })
                        let grouping = {
                            providerName:providerName,
                            trxs:tempData
                        }
                        groupArr.push(grouping)
                        setRepGrpData(groupArr)
                        setSelReportLp('All')
                    })
                }
                setReportPeriod(dayjs(startDate).format('MMM D YYYY')+' - '+dayjs(endDate).format('MMM D YYYY'))
                setEmptyData(false)
            }else if (!res.data.data || res.data.data.length === 0){
                setEmptyData(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        if (selProvider !== 'all'){
            currSums.forEach((trx:any) => {
                formattedData.push({
                    'Currency':trx.currencyName,
                    'Sum of Transactions':moneyfy(trx.amount,trx.currency)
                })
            })
            exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,`${t('common:reports.sum_trx_by_base_curr_by_lp')}: ${selReportLp}`)
        }else{
            repGrpData.map((data:any) => {
                let tempData:any = []
                let entityName = data.providerName
                data.trxs.map((trx: any) => {
                    tempData.push({
                        'Currency':trx.currencyName,
                        'Sum of Transactions':moneyfy(trx.amount,trx.currency)
                    })
                })
                let grouping = {
                    entityName: entityName,
                    trxs: tempData
                }
                formattedData.push(grouping)
            })
            exportExcelMulti(formattedData, reportPeriod, userDets.profile[0].user_name, `${t('common:reports.sum_trx_by_base_curr_by_lp')}: ${selReportLp}`)
        }

    }

    if (Object.keys(userDets).length > 0 && userDets.profile[0].user_type !== 'SX'){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal && <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,'Sum of LP Transactions by Base Currency: '+selReportLp)
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
            <div id="reportPreview">
                {selProvider === 'all' &&
                    <div className="bg-neutral-100 mt-[12px]">
                        {repGrpData.map((data:any,index:number) => (
                            <div key={index}>
                                <p className="font-bold px-[8px] py-[4px]">{data.providerName}</p>
                                <TableAlternative thead={[t('common:general.currency'),t('common:reports.transactions_sum')]}>
                                    {data.trxs.map((currSum:any,index:number)=>(
                                        <tr key={index}>
                                            <td>{currSum.currencyName}</td>
                                            <td>{moneyfy(currSum.amount,currSum.currency)}</td>
                                        </tr>
                                    ))}
                                </TableAlternative>
                            </div>
                        ))}
                    </div>
                }
                {selProvider !== 'all' &&
                        <TableAlternative thead={[t('common:general.currency'),t('common:reports.transactions_sum')]}>
                            {currSums.map((currSum:any,index:number)=>(
                                <tr key={index}>
                                    <td>{currSum.currencyName}</td>
                                    <td>{moneyfy(currSum.amount,currSum.currency)}</td>
                                </tr>
                            ))}
                        </TableAlternative>
                }
            </div>
            </ExportPreviewModal>}
            <div className="mb-[16px]">
                <h6 className={typography.heading6Bold}>{t('common:reports.sum_trx_by_base_curr_by_lp')}</h6>
            </div>
            <div className="mb-[32px] flex gap-[16px] justify-between">
                <form className="w-8/12" onSubmit={(e)=>{
                    e.preventDefault()
                    generateReport()
                }}>
                    <div className="flex gap-[8px]">
                        <DropdownList options={providerList} returnedSelection={(a)=>{setSelProvider(a.value)}} placeholder={t('common:reports.select_provider')} searchable={true} inputName="provider"/>
                        <div className="w-10/12">
                            <DateRangePicker selectedDateRange={(a)=>{
                                setStartDate(dayjs(a.startDate).format('YYYY-MM-DD'))
                                if (a.endDate) setEndDate(dayjs(a.endDate).format('YYYY-MM-DD'))
                            }} placeholder={t('common:reports.pick_date_range')}/>
                        </div>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant="primary"/> : t('common:reports.generate_report')}</Button>
                    </div>
                    {errMsg && <p className="text-error-500 mt-[8px]">{errMsg}</p>}
                </form>
                <div className="w-[200px]">
                    <ButtonOutline type="button" size="lg" variant="primary" disabled={(currSums.length === 0 && repGrpData.length === 0) || loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                </div>
            </div>
            <div>
                {!loading &&
                    <>
                        {currSums.length === 0 && repGrpData.length === 0 && !emptyData &&
                            <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                        }
                        {currSums.length === 0 && repGrpData.length === 0 && emptyData &&
                            <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                        }
                        {selProvider !== 'all' &&
                            <>
                                {currSums.length > 0 &&
                                    <>
                                        <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                                            <p className="text-white">{t('common:general.currency')}</p>
                                            <p className="text-white">{t('common:reports.transactions_sum')}</p>
                                        </div>
                                        <div className="flex flex-col gap-[8px]">
                                            {currSums.map((currSum:any,index:number)=>(
                                                <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                                    <div className="flex gap-[8px]">
                                                        {currSum.currencyName}
                                                    </div>
                                                    <div>{moneyfy(currSum.amount,currSum.currency)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {selProvider === 'all' &&
                            <>
                                {repGrpData.map((data:any,index:number) => (
                                    <div key={index}>
                                        <p className="font-bold px-[8px] pb-[4px] pt-[16px]">{data.providerName}</p>
                                        <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                                            <p className="text-white">{t('common:general.currency')}</p>
                                            <p className="text-white">{t('common:reports.transactions_sum')}</p>
                                        </div>
                                        <div className="flex flex-col gap-[8px]">
                                            {data.trxs.map((trx:any,index:number) => (
                                                <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                                    <div className="flex gap-[8px]">
                                                        {trx.currencyName}
                                                    </div>
                                                    <div>{moneyfy(trx.amount,trx.currency)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </>
                }
            </div>
            {loading &&
                <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant="primary"/></div>
            }
        </DashboardBaseLayout>
    )
}

export default SumLpTransactionsByCurr