import React, {useEffect, useState} from "react"
import {Container} from "./style"
import {RequestFormProps} from "./type";
import {formatNumber, moneyfy} from "../../helpers";
import {Button, CountrySelector, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
const RequestForm = (props:RequestFormProps) => {
    const {symbols,currencies,returnedData} = props
    const [selSymbol,setSelSymbol]:any = useState({})
    const [qty,setQty] = useState('')
    const [baseCurrency,setBaseCurrency]:any = useState({})
    const [price,setPrice] = useState('')
    const [rawPrice,setRawPrice] = useState('')
    const [currenciesOri,setCurrenciesOri]:any = useState([])
    const [fmtdSymbl,setFmtdSymbl]:any = useState([])
    const [fmtdCurr,setFmtdCurr]:any = useState([])
    const [reqData,setReqData]:any = useState({})
    const [blankFields,setBlankFields] = useState('')
    const {t} = useTranslation('common')

    useEffect(()=>{
        let tempFmtd:any = []
        symbols.forEach((symbol:{name:string,shortName:string,countryFlag?:string,price:string,currency:string,mrktCode:string,ticker?:string}) => {
            let temp = {
                optionName:symbol.name,
                optionShortName:symbol.shortName,
                optionFlag:symbol.countryFlag,
                price:symbol.price,
                symbCurrency:symbol.currency,
                marketCode:symbol.mrktCode,
                subName:symbol.ticker
            }
            tempFmtd.push(temp)
        })
        setFmtdSymbl(tempFmtd)
    },[symbols])

    useEffect(()=>{
        let tempFmtd:any = []
        currencies.forEach((currency:{currencyShortName:string, currencyLongName:string, countryFlag?:string}) => {
            let temp = {
                optionName:currency.currencyLongName,
                optionShortName:currency.currencyShortName,
                optionFlag:currency.countryFlag
            }
            tempFmtd.push(temp)
        })
        setFmtdCurr(tempFmtd)
        setCurrenciesOri(tempFmtd)
    },[currencies])

    useEffect(()=>{
        if (selSymbol.symbCurrency){
            setFmtdCurr(currenciesOri.filter((currency:any) => currency.optionShortName !== selSymbol.symbCurrency))
        }

        if (selSymbol.symbCurrency === baseCurrency.optionShortName){
            setBaseCurrency({})
        }
    },[selSymbol])

    function compileInput(){
        setBlankFields('')
        if (Object.keys(selSymbol).length > 0 && Object.keys(baseCurrency).length > 0 && qty && price){
            const data = {
                symbol:selSymbol,
                quantity:Number(qty),
                baseCurrency:baseCurrency,
                price:Number(price),
                mrktCode: selSymbol.marketCode,
                symbCurrency: selSymbol.symbCurrency
            }
            returnedData(data)
            setReqData(data)
        }else{
            setBlankFields(t('common:request_lp.all_fields_required'))
        }
    }

    return (
        <>
            <Container>
                <CountrySelector options={fmtdSymbl} selectedOption={(symb)=>{
                    setSelSymbol(symb)
                    setPrice(symb.price)
                    setRawPrice(symb.price)
                }} placeholder={t('common:request_lp.select_symbol')} label={t('common:request_lp.symbol')} searchable={true} sectionTitle={t('common:request_lp.all_tickers')} inputName="symbol"/>
                <TextField label={t('common:request_lp.price')} required={false} value={price} placeholder={t('common:request_lp.price')} onChange={(e)=>{
                    if (isNaN(Number(e.target.value))) return
                    setPrice(e.target.value)
                    setRawPrice(e.target.value)
                }} onBlur={()=>{setPrice(formatNumber(Number(price)))}} onFocus={()=>{setPrice(rawPrice)}}/>
                <TextField required={false} onChange={(e)=>{
                    if (isNaN(Number(e.target.value))) return
                    setQty(e.target.value)
                }} value={qty} placeholder={t('common:request_lp.enter_quantity')} label={t('common:request_lp.quantity')}/>
                <CountrySelector options={fmtdCurr} selectedOption={(curr)=>{setBaseCurrency(curr)}} placeholder={t('common:request_lp.select_currency')} label={t('common:request_lp.base_currency')} initialCountry={baseCurrency.optionName} inputName="baseCurrency"/>
                <Button type="button" size="lg" variant="primary" onClick={compileInput}>{t('common:request_lp.view_offers')}</Button>
                {blankFields && <p className="text-error-500">{blankFields}</p>}
            </Container>
            {/*CALCULATIONS*/}
            {!blankFields &&
                <>
                    {reqData.amount && reqData.totalAmount &&
                        <div className="pt-0 p-[24px] flex gap-[16px] justify-start items-end">
                            <div className="flex flex-col gap-[8px] trade-calcs">
                                <p className="caption-book text-neutral-500">{t('common:request_lp.equity_amount')}</p>
                                <p>{`${moneyfy(reqData.amount,reqData.symbCurrency)}`}</p>
                            </div>
                            <div>
                                +
                            </div>
                            <div className="flex flex-col gap-[8px] trade-calcs">
                                <p className="caption-book text-neutral-500">{t('common:request_lp.taxes_fees')}</p>
                                <p>{`${moneyfy(reqData.fees,reqData.symbCurrency)}`}</p>
                            </div>
                            <div>
                                =
                            </div>
                            <div className="flex flex-col gap-[8px] trade-calcs">
                                <p className="caption-book text-neutral-500">{t('common:request_lp.total_estimate')}</p>
                                <p className="font-[700]">{`${moneyfy(reqData.totalAmount,reqData.symbCurrency)}`}</p>
                            </div>
                        </div>
                    }
                </>
            }
        </>

    )
}

export default RequestForm
