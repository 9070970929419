import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {BankAccounts, Button} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface BrokerBankAccountsProps{
    brokerDets:any,
    addAccountFxn:()=>void,
    refreshBrokerDets:(a:string)=>void
    editBankAccount:(a:any)=>void
}

const BrokerBankAccounts = (props:BrokerBankAccountsProps) => {
    const {brokerDets,addAccountFxn,refreshBrokerDets,editBankAccount} = props
    const [accts,setAccts]:any = useState([])
    const [loading,setLoading] = useState(false)
    let bankAccts:any[] = brokerDets.bank_accts
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (bankAccts && bankAccts.length && bankAccts.length > 0){
            const tmp:any = []
            bankAccts.forEach((acct:any)=>{
                tmp.push(
                    {
                        acctNumber:acct.prt_bank_account_number,
                        bankName:acct.prt_bank_name,
                        acctName:acct.prt_bank_account_name,
                        currency:acct.prt_curr_code,
                        swiftCode:acct.prt_bank_swift_code,
                        isActive:acct.prt_bank_status === 'Y',
                        isMainAccount:acct.prt_main_account === 'Y',
                        bankLogo:acct.bank_logos[44],
                        editFxn:()=>{getAcctDets(acct.prt_curr_code,acct.prt_code)},
                        deactivateFxn:()=>{deactAcct(acct.prt_curr_code,acct.prt_code)},
                        reactivateFxn:()=>{reactAcct(acct.prt_curr_code,acct.prt_code)}
                    }
                )
            })
            setAccts(tmp)
        }
    },[bankAccts])

    function deactAcct(acctCurr:string,brokerId:string){
        setLoading(true)
        let formData = {
            prtCode:brokerId,
            prtCurrCode:acctCurr,
            action:'savPrtAcc'
        }
        axiosSend({
            url:`/fx/admin/part-acc-v2/deact/${brokerId}`,
            method:'PATCH',
            data:formData
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                refreshBrokerDets(brokerId)
            }
            setLoading(false)
        })
    }

    function reactAcct(acctCurr:string,brokerId:string){
        setLoading(true)
        let formData = {
            prtCode:brokerId,
            prtCurrCode: acctCurr,
            action: 'savPrtAcc'
        }
        axiosSend({
            url:`/fx/admin/part-acc-v2/act/${brokerId}`,
            method:'PATCH',
            data:formData
        }).then(res => {
            if (res.data.Status === 'SUCC') {
                refreshBrokerDets(brokerId)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    function getAcctDets(acctCurr:string,brokerId:string) {
        setLoading(true)
        axiosSend({
            url: `/fx/admin/part-acc-v2/${brokerId}?prtCurrCode=${acctCurr}`,
            method: 'GET'
        }).then(res => {
            editBankAccount(res.data.data[0])
            setLoading(false)
        })
    }

    return (
        <div className="flex flex-col flex-1 justify-between mt-[32px] gap-[40px]">
                <BankAccounts acctDets={accts} loading={loading} labelOverrides={{
                    deactivate:t('common:general.deactivate'),
                    reactivate:t('common:general.reactivate'),
                    acctName:t('common:general.account_name'),
                    currency:t('common:general.currency'),
                    swiftCode:t('common:liquidity_providers.swift_code'),
                    edit:t('common:general.edit')
                }}/>
            <Button size="lg" variant="primary" onClick={()=>{addAccountFxn()}}>{t('common:brokers.add_acct')}</Button>
        </div>
)}

export default BrokerBankAccounts
