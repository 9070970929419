interface VDividerProps {
    height:number
}

const VDivider = (props:VDividerProps) => {
    const {height} = props
    return (
        <div className="w-[1px] bg-neutral-100 table" style={{height:height}}/>
    )
}

export default VDivider
