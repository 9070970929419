import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {ActionFailed, ActionSuccess, Button, DatePicker, LoadingSpinner, UserActions} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
interface UserActionsProps  {
    userDets:any,
    step:number,
    setStep:(a:number)=>void
}
const AssignUserActions = (props:UserActionsProps) => {
    const {userDets,step,setStep} = props
    const [currentActions,setCurrentActions]:any = useState([])
    const [availableActions,setAvailableActions]:any = useState([])
    const [supActions,setSupActions] = useState([])
    const [selections,setSelections]:any = useState([])
    const [expDate,setExpDate] = useState('')
    const [loading,setLoading] = useState(true)
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [errMsg,setErrMsg] = useState('')
    const {t,i18n} = useTranslation('common')

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
            if (userDets.Status === 'SUCC'){
                //populate current actions
                const tmpCurr:any = []
                userDets.user_actions.forEach((action:any)=>{
                    tmpCurr.push({
                        optionName:action.action_title,
                        optionId:action.action_code
                    })
                })
                setCurrentActions(tmpCurr)

                //populate available actions
                const tmpAvail:any = []
                userDets.additional_actions.forEach((action:any)=>{
                    tmpAvail.push({
                        optionName:action.action_title,
                        optionId:action.action_code
                    })
                })
                setAvailableActions(tmpAvail)

                //populate supplementary actions
                const tmpSup:any = []
                userDets.current_supp_actions.forEach((action:any)=>{
                    tmpSup.push({
                        optionName:action.action_title,
                        optionId:action.action_code
                    })
                })
                setSupActions(tmpSup)

                setLoading(false)
            }
    },[userDets])


    function editUsrInfo(){
        if (!expDate){
            setErrMsg(t('common:users.specify_expiration_date'))
            return
        }else {
            setErrMsg('')
        }

        const formData = {
            usrName1:userDets.data[0].usr_name1,
            usrName2:userDets.data[0].usr_name2,
            usrAddress:userDets.data[0].usr_address,
            usrCountry:userDets.data[0].usr_country,
            usrAddPostCode:userDets.data[0].usr_addr_post_code,
            usrAddProvince:userDets.data[0].usr_addr_province,
            usrAddCity:userDets.data[0].usr_addr_city,
            usrPhone:userDets.data[0].usr_ph_no,
            usrMobile:userDets.data[0].usr_mb_no,
            addSupAction:selections.added,
            remSupAction:selections.removed,
            supExpDate:expDate,
            action:'savUser'
        }

        setLoading(true)
        axiosSend({
            url:`/fx/admin/users/${userDets.data[0].usr_id}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStep(2)
        })
    }


    return (
        <>
            {loading ?
                <div className="flex flex-col flex-1 justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
                :
                <>
                    {stage === 1 &&
                        <>
                            <div className="mb-[40px]">
                                <p className="font-bold text-[19px] mb-[4px]">{t('common:users.edit_user_actions')}</p>
                                <p className="text-[14px]">{`${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2}`}</p>
                            </div>
                            <form onSubmit={(e)=>{
                                e.preventDefault()
                                editUsrInfo()
                            }}>
                                <div className="flex flex-col gap-[40px]">
                                    <div className="flex flex-col gap-[32px]">
                                        <UserActions availableOptions={availableActions} currentOptions={currentActions} supplementaryOptions={supActions} selOptions={(e: any)=>{setSelections(e)}} titleAvailable={t('common:users.available_options')} titleCurrent={t('common:users.current_options')} labelOverrides={{
                                            noActionsAdded:t('common:users.no_actions_added'),
                                            noActionsAvailable:t('common:users.no_actions_available')
                                        }}/>
                                        <div>
                                            <DatePicker onChange={(e)=>{setExpDate(dayjs(e).locale(i18n.language).format('YYYY-MM-DD HH:mm'))}} placeholder={t('common:general.select_date')} label={t('common:general.valid_to')} disablePastDays={true}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-[4px]">
                                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                                        <Button size="lg" variant="primary" type="submit" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.save_changes')}</Button>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                    {stage === 2 &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                {resp.Status === 'SUCC' &&
                                    // <ActionSuccess headingText={t('common:users.user_actions_success')} subText={`User actions for ${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2} was updated successfully`}/>
                                    <ActionSuccess headingText={t('common:users.user_actions_success')} subText={t('common:users.user_actions_success_msg',{name:`${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2}`})}/>
                                }
                                {resp.Status === 'FAIL' &&
                                    <ActionFailed headingText={resp.Message}/>
                                }
                            </div>
                            <Button type="button" size="lg" variant="primary" onClick={()=>{
                                if (resp.Status === 'SUCC'){
                                    window.location.reload()
                                }else {
                                    setStage(1)
                                }
                            }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                        </div>
                    }
                </>
            }

        </>
    )
}

export default AssignUserActions
