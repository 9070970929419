import HDivider from "../HDivider";
import {Button, DisplayField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";


interface BrokerDetsContentProps{
    brokerDets:any,
    editBtnFxn:()=>void
}

const BrokerDetsContent = (props:BrokerDetsContentProps) => {
    const {brokerDets,editBtnFxn} = props
    const {t} = useTranslation('common')

    return (
        <>
            {/*BASIC INFO*/}
            <div className="flex gap-[24px] flex-col mt-[32px]">
                <h6 className="font-bold">{t('common:liquidity_providers.basic_info')}</h6>
                <HDivider twBorderColor="border-neutral-900"/>
                <DisplayField label={`${t('common:reports.broker_name')} #1`}>
                    {brokerDets.data[0].org_name1}
                </DisplayField>
                <DisplayField label={`${t('common:reports.broker_name')} #2`}>
                    {brokerDets.data[0].org_name2 ? brokerDets.data[0].org_name2 : '-'}
                </DisplayField>
                <DisplayField label={t('common:dashboard.address')}>
                    {brokerDets.data[0].org_address1 && <p>{brokerDets.data[0].org_address1}</p>}
                    {brokerDets.data[0].org_address2 && <p>{brokerDets.data[0].org_address2}</p>}
                    {brokerDets.data[0].org_address3 && <p>{brokerDets.data[0].org_address3}</p>}
                    {brokerDets.data[0].org_cty && <p>{brokerDets.data[0].org_cty}</p>}
                </DisplayField>
                <DisplayField label={t('common:login.email')}>
                    {brokerDets.data[0].org_email ? brokerDets.data[0].org_email : '-'}
                </DisplayField>
                <DisplayField label={t('common:general.phone')}>
                    {brokerDets.data[0].org_mph_no ? brokerDets.data[0].org_mph_no : '-'}
                </DisplayField>
                <DisplayField label={t('common:brokers.tin')}>
                    {brokerDets.data[0].org_tin ? brokerDets.data[0].org_tin : '-'}
                </DisplayField>
                <DisplayField label={t('common:brokers.vat_number')}>
                    {brokerDets.data[0].org_vat_reg ? brokerDets.data[0].org_vat_reg : '-'}
                </DisplayField>
                <DisplayField label={t('common:general.remarks')}>
                    {brokerDets.data[0].org_upd_rem ? brokerDets.data[0].org_upd_rem : '-'}
                </DisplayField>
            </div>
            {/*CONTACT 1 INFO*/}
            <div className="flex gap-[24px] flex-col mt-[24px]">
                <h6 className="font-bold">{t('common:brokers.contact_person')} #1</h6>
                <HDivider twBorderColor="border-neutral-900"/>
                <DisplayField label={t('common:general.name')}>
                    {brokerDets.data[0].org_con1_name ? brokerDets.data[0].org_con1_name : '-'}
                </DisplayField>
                <DisplayField label={t('common:login.email')}>
                    {brokerDets.data[0].org_con1_email ? brokerDets.data[0].org_con1_email : '-'}
                </DisplayField>
                <DisplayField label={t('common:general.phone')}>
                    {brokerDets.data[0].org_con1_mb_no ? brokerDets.data[0].org_con1_mb_no : '-'}
                </DisplayField>
                <DisplayField label={t('common:brokers.role')}>
                    {brokerDets.data[0].org_con1_grade ? brokerDets.data[0].org_con1_grade : '-'}
                </DisplayField>
            </div>
            {/*CONTACT 2 INFO*/}
            <div className="flex gap-[24px] flex-col mt-[24px]">
                <h6 className="font-bold">{t('common:brokers.contact_person')} #2</h6>
                <HDivider twBorderColor="border-neutral-900"/>
                <DisplayField label={t('common:general.name')}>
                    {brokerDets.data[0].org_con2_name ? brokerDets.data[0].org_con2_name : '-'}
                </DisplayField>
                <DisplayField label={t('common:login.email')}>
                    {brokerDets.data[0].org_con2_email ? brokerDets.data[0].org_con2_email : '-'}
                </DisplayField>
                <DisplayField label={t('common:general.phone')}>
                    {brokerDets.data[0].org_con2_mb_no ? brokerDets.data[0].org_con2_mb_no : '-'}
                </DisplayField>
                <DisplayField label={t('common:brokers.role')}>
                    {brokerDets.data[0].org_con2_grade ? brokerDets.data[0].org_con2_grade : '-'}
                </DisplayField>
            </div>
            <div className="mt-[40px]">
                <Button size="lg" variant="primary" onClick={()=>{editBtnFxn()}}>{t('common:general.edit')}</Button>
            </div>
        </>
    )
}

export default BrokerDetsContent
