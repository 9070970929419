import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    DropdownList,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AddMethodProps{
    showPanelTitle:(a:boolean)=>void
}

const AddPaymentMethod = (props:AddMethodProps) => {
    const {showPanelTitle} = props
    const [name,setName] = useState('')
    const [code,setCode] = useState('')
    const [shortName,setShortName] = useState('')
    const [isActive,setIsActive] = useState('Y')
    const [remarks,setRemarks] = useState('')
    const [stage,setStage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [errMsg,setErrMsg] = useState('')
    const [disableBtn,setDisableBtn] = useState(true)
    const {t} = useTranslation('common')
    const isActiveOptions = [
        {
            label:t('common:general.yes'),
            value:'Y'
        },
        {
            label:t('common:general.no'),
            value:'N'
        },
    ]

    useEffect(()=>{
        if (!name||!code||!shortName||!isActive){
            setDisableBtn(true)
        }else{
            setDisableBtn(false)
        }
    },[name,code,shortName,isActive])

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    function saveMethod(){
        if (!name || !code || !shortName || !isActive){
            setErrMsg(t('common:general.some_required_fields_empty'))
            return null
        }

        setLoading(true)
        let formData = {
            paymentCode:code,
            paymentDesc:name,
            paymentShortDesc:shortName,
            paymentRefReqFlag:isActive,
            paymentUpdRem:remarks,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/payment-modes/`,
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setStage(2)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    saveMethod()
                }} className="flex flex-col flex-1 gap-[40px]">
                    <div className="flex flex-col flex-1 gap-[24px]">
                        <TextField label={t('common:payment_methods.payment_method_name')} required={true} value={name} onChange={(e)=>{setName(e.target.value)}}/>
                        <TextField label={t('common:payment_methods.payment_method_code')} required={true} value={code} onChange={(e)=>{setCode(e.target.value)}}/>
                        <TextField label={t('common:payment_methods.short_name')} required={true} value={shortName} onChange={(e)=>{setShortName(e.target.value)}}/>
                        <DropdownList label={t('common:payment_methods.active_flag')} options={isActiveOptions} initialText={isActive === 'Y' ? t('common:general.yes') : t('common:general.no')} returnedSelection={(e)=>{setIsActive(e.value)}} inputName="flag"/>
                        <TextArea label={t('common:general.remarks')} value={remarks ? remarks : ''} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}}/>
                    </div>
                    {errMsg && <p className="text-error-500 mb-[4px] text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                    <Button type="submit" size="lg" variant="primary" disabled={loading||disableBtn}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:payment_methods.add_payment_method')}</Button>
                </form>
            }
            {stage === 2 &&
                <>
                    {resp.Status === 'SUCC' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionSuccess headingText={t('common:payment_methods.payment_method_added')} subText={t('common:payment_methods.payment_method_added_msg',{name:name})}/>
                            </div>
                            <Button size="lg" variant="primary" onClick={()=>{window.location.reload()}}>{t('common:general.sounds_good')}</Button>
                        </div>
                    }
                    {resp.Status === 'FAIL' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionFailed headingText={resp.Message}/>
                            </div>
                            <Button size="lg" variant="primary" onClick={()=>{setStage(1)}}>{t('common:general.okay')}</Button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default AddPaymentMethod