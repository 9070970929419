import styled from "styled-components";
import theme from "../../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`

export const Heading = styled.div`
  h3{
    font-family: "Circular Book Bold", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: ${theme.colors["black-100"]};
    margin-bottom:8px;
  }
  
  p{
    font-family: "Circular Book",sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    color: ${theme.colors["neutral-900"]};
  }
`

export const DropDown = styled.div`
  width: 200px;
`

export const OffersContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: ${theme.colors.white};
  min-height: 597px;
`

export const NoDataContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  height: 597px;
`

export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 597px;
`

export const Offers = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  gap: 24px;
`

export const OfferItem = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  p.recommended{
    margin: 0;
    position: absolute;
    top: -7px;
    left: 0;
    background: #93F9B9;
    padding: 4px 8px;
    border-radius: 4px 4px 4px 0;
    font-size: 12px;
    font-family: "Circular Book",sans-serif;
    line-height: 12px;
    font-weight: 400;
    color: #011627;
  }
  button{
    width: 58px;
  }
`

export const OfferInfo = styled.div`
  width: 100%;
  height:88px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  background: ${theme.colors["neutral-50"]};
`

export const ProviderInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`

export const ProviderNameDelivery = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h4{
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: ${theme.colors["neutral-900"]};
  }
  p{
    margin: 0;
    font-family: "Circular Book",sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors["neutral-400"]};
  }
`

export const RateInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  text-align: right;
  h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.02em;
    font-family: "Circular Pro Book",sans-serif;
    margin: 0;
    color: ${theme.colors["neutral-900"]};
  }
  p{
    font-family: "Circular Pro Book",sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${theme.colors["neutral-600"]};
    margin: 0;
  }
`
