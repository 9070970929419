import {useState} from "react";
import {Button, DropdownList, RateHistory} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface RateHistoryProps{
    active:{
        baseFlag:string,
        baseCurrency:string,
        exchangeFlag:string,
        exchangeCurrency:string,
        baseRate:number,
        exchangeRate:number,
        startDate:string,
        endDate:string
    }[],
    archived:{
        baseFlag:string,
        baseCurrency:string,
        exchangeFlag:string,
        exchangeCurrency:string,
        baseRate:number,
        exchangeRate:number,
        startDate:string,
        endDate:string
    }[],
    switchView:(a:string)=>void
}
const LPRateHistory = (props:RateHistoryProps) => {
    const {active,archived,switchView} = props
    const {t} = useTranslation('common')

    active.sort((a:any,b:any)=> (new Date(b.startDate) as any) - (new Date(a.startDate) as any))

    const [view,setView] = useState('active')
    const options = [
        {
            label:t('common:general.active'),
            value:'active'
        },
        {
            label:t('common:general.archived'),
            value:'archived'
        }
    ]

    return (
        <div className="pt-[32px]">
            <div className="flex gap-[24px] mb-[24px]">
                <div className="flex flex-1">
                    <DropdownList options={options} initialText={t('common:general.active')} returnedSelection={(e)=>{setView(e.value)}} inputName="active" labelOverrides={{
                        search:t('common:general.search'),
                        requiredField:t('common:general.required_field')
                    }}/>
                </div>
                <div className="w-[125px]">
                    <Button size="lg" variant="primary" type="button" onClick={()=>{switchView('add-rates')}}>{t('common:liquidity_providers.add_rates')}</Button>
                </div>
            </div>
            {view === 'active' &&
                <RateHistory ratePairs={active} labelOverrides={{
                    to:t('common:general.to'),
                    emptyMsg:t('common:liquidity_providers.rates_empty_msg')
                }}/>
            }
            {view === 'archived' &&
                <RateHistory ratePairs={archived} labelOverrides={{
                    to:t('common:general.to'),
                    emptyMsg:t('common:liquidity_providers.rates_empty_msg')
                }}/>
            }
        </div>
    )
}

export default LPRateHistory