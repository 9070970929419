import DashboardBaseLayout from "../components/DashboardBaseLayout";
import AppActionsTable from "../components/AppActionsTable/AppActionsTable";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import EditAppAction from "../components/AppActionsContent/EditAppAction";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import AddAppAction from "../components/AppActionsContent/AddAppAction";
import dayjs from "dayjs";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const AppActions = () => {
    const userDets:any = useContext(UserContext)
    const [loading,setLoading] = useState(true)
    const [appActions,setAppActions]:any = useState([])
    const [appActionsOri,setAppActionsOri]:any = useState([])
    const [srchQry,setSrchQry] = useState('')
    const [actionDets,setActionDets]:any = useState({})
    const [actionDetsPnl,setActionDetsPnl] = useState(false)
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [addActionPnl,setAddActionPnl] = useState(false)
    const [logsPnl,setLogsPnl] = useState(false)
    const [logs,setLogs]:any = useState([])
    const [hasAccess,setHasAccess] = useState(true)
    const {t,i18n} = useTranslation('common')

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadAppActions()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function loadAppActions(){
        document.title = `${appName} - ${t('common:code_maintenance.app_actions')}`
        axiosSend({
            url:'/fx/admin/app-actions',
            method:'GET',
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        title:action.aa_action_title,
                        description:action.aa_action_descr,
                        clearanceLevel:action.aa_action_clear_level_code,
                        actionCode:action.aa_action_code
                    })
                })
                setAppActions(tmp)
                setAppActionsOri(tmp)

                //populate logs
                let tmpLogs:any = []
                res.data.logs.forEach((log:any)=>{
                    tmpLogs.push({
                        timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id
                    })
                })
                setLogs(tmpLogs)
            }
            setLoading(false)
        })
    }

    function getActionDets(actionCode:string){
        axiosSend({
            url:`/fx/admin/app-actions/${actionCode}`,
            method:"GET"
        }).then(res => {
            if (res.data.data){
                setActionDets(res.data.data[0])
            }
            setActionDetsPnl(true)
        })
    }

    function search(qry:string){
        setSrchQry(qry)
        setAppActions(appActionsOri.filter((action:any)=>action.title.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="app-actions">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.app_actions')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setAddActionPnl(true)}}>{t('common:app_actions.add_app_action')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setLogsPnl(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:app_actions.search_app_actions')} onChange={(e)=>{search(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <>
                    <AppActionsTable actions={appActions} callback={(e)=>{getActionDets(e.actionCode)}}/>
                </>

            }
            {actionDetsPnl &&
                <SidePanel closeFxn={()=>{setActionDetsPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:app_actions.edit_action') : ''} subtext={showPanelTitle ? actionDets.aa_action_title : ''}>
                    <EditAppAction appActionDets={actionDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {addActionPnl &&
                <SidePanel closeFxn={()=>{setAddActionPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:app_actions.add_app_action') : ''}>
                    <AddAppAction showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {logsPnl &&
                <SidePanel closeFxn={()=>{setLogsPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={t('common:app_actions.activity_log')}>
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default AppActions