import React, {useEffect, useState} from "react"
import {Container} from "./style"
import {RequestFormProps} from "./type";
import {formatNumber, moneyfy} from "../../helpers";
import {Button, CountrySelector, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const RequestFormOldFormat = (props:RequestFormProps) => {
    const {currencies,returnedData} = props
    const [baseCurrency,setBaseCurrency]:any = useState({})
    const [exchCurrency,setExchCurrency]:any = useState({})
    const [baseAmount,setBaseAmount] = useState('')
    const [rawAmount,setRawAmount] = useState('')
    const [baseCurrs,setBaseCurrs]:any = useState([])
    const [exchangeCurrs,setExchangeCurrs]:any = useState([])
    const [oriCurrs,setOriCurrs]:any = useState([])
    const [reqData,setReqData]:any = useState({})
    const [blankFields,setBlankFields] = useState('')
    const {t} = useTranslation('common')

    useEffect(()=>{
        let tempFmtd:any = []
        currencies.forEach((currency:{currencyShortName:string, currencyLongName:string, countryFlag?:string}) => {
            let temp = {
                optionName:currency.currencyLongName,
                optionShortName:currency.currencyShortName,
                optionFlag:currency.countryFlag
            }
            tempFmtd.push(temp)
        })
        setBaseCurrs(tempFmtd)
        setExchangeCurrs(tempFmtd)
        setOriCurrs(tempFmtd)
    },[currencies])

    //same currency shouldn't be selected in both fields
    useEffect(()=>{
        if (baseCurrency.optionShortName === exchCurrency.optionShortName) {
            setExchCurrency({})
        }
    },[baseCurrency])

    function compileInput(){
        setBlankFields('')
        if (Object.keys(baseCurrency).length > 0 && Object.keys(exchCurrency).length > 0 && baseAmount){
            const data = {
                baseAmount:Number(rawAmount),
                baseCurrency:baseCurrency.optionShortName,
                exchangeCurrency:exchCurrency.optionShortName,
                baseFlag:baseCurrency.optionFlag,
                exchFlag:exchCurrency.optionFlag
            }
            returnedData(data)
            setReqData(data)
        }else{
            setBlankFields(t('common:request_lp.all_fields_required'))
        }
    }

    return (
        <>
            <Container>
                <CountrySelector options={baseCurrs} searchable={true} selectedOption={(curr)=>{
                    setBaseCurrency(curr)
                    setExchangeCurrs(oriCurrs.filter((currency:any) => currency.optionShortName !== curr.optionShortName))
                    if (baseCurrency.optionShortName === exchCurrency.optionShortName) {
                        setExchCurrency({})
                    }
                }} placeholder={t('common:request_lp.select_currency')} label={t('common:request_lp.base_currency')} initialCountry={baseCurrency.optionShortName} inputName="baseCurrency"/>
                <CountrySelector options={exchangeCurrs} searchable={true} selectedOption={(curr)=>{setExchCurrency(curr)}} placeholder={t('common:request_lp.select_currency')} label={t('common:request_lp.exchange_currency')} initialCountry={exchCurrency.optionName} inputName="exchangeCurrency"/>
                <TextField required={true} placeholder={t('common:request_lp.base_amount')} label={t('common:request_lp.base_amount')} value={baseAmount} onChange={(e)=>{
                    if (isNaN(Number(e.target.value))) return
                    setBaseAmount(e.target.value)
                    setRawAmount(e.target.value)
                }} onBlur={()=>{setBaseAmount(formatNumber(Number(baseAmount)))}} onFocus={()=>{setBaseAmount(rawAmount)}}/>
                <Button type="button" size="lg" variant="primary" onClick={compileInput}>{t('common:request_lp.view_offers')}</Button>
                {blankFields && <p className="text-error-500">{blankFields}</p>}
            </Container>
            {/*CALCULATIONS*/}
            {reqData.amount && reqData.totalAmount &&
                <div className="pt-0 p-[24px] flex gap-[16px] justify-start items-end">
                    <div className="flex flex-col gap-[8px] trade-calcs">
                        <p className="caption-book text-neutral-500">{t('common:request_lp.equity_amount')}</p>
                        <p>{`${moneyfy(reqData.amount,reqData.symbCurrency)}`}</p>
                    </div>
                    <div>
                        +
                    </div>
                    <div className="flex flex-col gap-[8px] trade-calcs">
                        <p className="caption-book text-neutral-500">{t('common:request_lp.taxes_fees')}</p>
                        <p>{`${moneyfy(reqData.fees,reqData.symbCurrency)}`}</p>
                    </div>
                    <div>
                        =
                    </div>
                    <div className="flex flex-col gap-[8px] trade-calcs">
                        <p className="caption-book text-neutral-500">{t('common:request_lp.total_estimate')}</p>
                        <p className="font-[700]">{`${moneyfy(reqData.totalAmount,reqData.symbCurrency)}`}</p>
                    </div>
                </div>
            }
        </>

    )
}

export default RequestFormOldFormat