import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ucFirstAll} from "../../helpers";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface EditBankAccountProps {
    acctDets: any,
    step:number,
    setStep:(a:number)=>void
}

const EditBankAccount = (props:EditBankAccountProps) => {
    const {acctDets,setStep,step} = props
    const [currencies,setCurrencies]:any = useState([])
    const [country,setCountry] = useState(acctDets.prt_bank_ctr_code)
    const [bank,setBank] = useState(acctDets.prt_bank_name)
    const [bankCode,setBankCode] = useState(acctDets.bank_code)
    const [branch,setBranch] = useState(acctDets.prt_bank_branch)
    const [acctNo,setAcctNo] = useState(acctDets.prt_bank_account_number)
    const [acctName,setAcctName] = useState(acctDets.prt_bank_account_name)
    const [currency,setCurrency] = useState(acctDets.prt_curr_code)
    const [swift,setSwift] = useState(acctDets.prt_bank_swift_code)
    const [address,setAddress] = useState(acctDets.prt_bank_address)
    const [mainAcct,setMainAcct] = useState(acctDets.prt_main_account)
    const [loading,setLoading] = useState(false)
    const [countryErr,setCountryErr] = useState(false)
    const [currencyErr,setCurrencyErr] = useState(false)
    const [countries,setCountries]:any = useState([])
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [countryFullName,setCountryFullName]:any = useState({})
    const [currencyFullName,setCurrencyFullName]:any = useState({})
    const [banks,setBanks]:any = useState([])
    const {t} = useTranslation('common')

    const mainAcctOpts = [
        {
            label: t('common:general.yes'),
            value:'Y'
        },
        {
            label:t('common:general.no'),
            value:'N'
        },
    ]

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })

        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name){
                        if (country.cty_flag_name && country.cty_upd_rem){
                            tmp.push({
                                optionName:ucFirstAll(country.cty_name),
                                optionShortName:country.cty_code,
                                optionFlag:country.cty_flag_name
                            })
                        }
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:ucFirstAll(bank.bank_name),
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        //get the full name of the bank country
        setTimeout(()=>{
            let tmpCntry = countries.filter((country:any)=>country.optionShortName.toLowerCase() === acctDets.prt_bank_ctr_code.toLowerCase())
            if (tmpCntry.length > 0) setCountryFullName(tmpCntry[0])
        },300)

        //get the full name of the account currency
        let tmpCurr:any = []
        tmpCurr = currencies.filter((currency:any) => currency.optionShortName.toLowerCase() === acctDets.prt_curr_code.toLowerCase())
        if(tmpCurr.length > 0) setCurrencyFullName(tmpCurr[0])
    },[currencies,countries])

    function updateBankAccount(){
        //validate dropdownlists
        if (!country){
            setCountryErr(true)
            return
        }
        if (!currency){
            setCurrencyErr(true)
            return
        }

        let formData = {
            prtCode:acctDets.prt_code,
            prtCurrCode:currency,
            prtBankCtrCode:country,
            prtBankName:bank,
            bankCode:bankCode,
            prtBankBranch:branch,
            prtBankSwift:swift,
            prtBankAddress:address,
            prtBankAccName:acctName,
            prtBankAccNum:acctNo,
            prtMainAcc:mainAcct,
            action:'savPrtAcc'
        }

        axiosSend({
            url:`/fx/admin/part-acc-v2/${acctDets.prt_code}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStep(2)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    updateBankAccount()
                }}>
                    <div className="flex flex-col gap-[40px]">
                        <h4 className="font-bold text-[19px]">{t('common:liquidity_providers.edit_bank_acct')}</h4>
                        <CountrySelector options={banks} selectedOption={(a)=>{
                            setBank(a.optionName)
                            setBankCode(a.optionShortName)
                            a.swiftCode ? setSwift(a.swiftCode) : setSwift('')
                        }} initialCountry={bank} label={t('common:liquidity_providers.bank_name')} searchable={true} inputName="bankName"/>
                        <TextField label={t('common:liquidity_providers.acct_number')} value={acctNo} onChange={(e)=>{
                            if (!Number(e.target.value)){
                                setAcctNo('')
                                return
                            }
                            setAcctNo(e.target.value)
                        }} required={true}/>
                        <TextField label={t('common:liquidity_providers.account_name')} value={acctName} onChange={(e)=>{setAcctName(e.target.value)}} required={true}/>
                        <div className="flex flex-col gap-[8px]">
                            <CountrySelector options={currencies} selectedOption={(e)=>{setCurrency(e.optionShortName)}} initialCountry={currencyFullName.optionName} label={t('common:brokers.acct_currency')} inputName="accountCurrency"/>
                            {currencyErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500"/> {t('common:general.this_field_required')}</p>}
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <CountrySelector options={countries} selectedOption={(e)=>{setCountry(e.optionShortName)}} initialCountry={countryFullName.optionName} label={t('common:brokers.bank_country')} inputName="bankCountry"/>
                            {countryErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500"/> {t('common:general.this_field_required')}</p>}
                        </div>
                        <TextField label={t('common:general.branch')} value={branch} onChange={(e)=>{setBranch(e.target.value)}} required={true}/>
                        <TextField label={t('common:liquidity_providers.swift_code')} value={swift} onChange={(e)=>{setSwift(e.target.value)}} required={true}/>
                        <TextField label={t('common:provider_dets.address')} value={address} onChange={(e)=>{setAddress(e.target.value)}} required={true}/>
                        <div className="flex flex-col gap-[8px]">
                            <DropdownList options={mainAcctOpts} initialText={mainAcct === 'Y' ? t('common:general.yes') : t('common:general.no')} returnedSelection={(e)=>{setMainAcct(e.value)}} label={`${t('common:liquidity_providers.main_account')}?`} inputName="mainAccount"/>
                        </div>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:brokers.update_bank_acct_dets')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:brokers.bank_acct_updated')}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={t('common:brokers.update_failed')} subText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStep(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default EditBankAccount
