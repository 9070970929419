import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LanguageSwitcher, Ribbon, SSXBar} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const NavigationSection = () => {
    const [toggleSidenav,setToggleSidenav] = useState(false)
    const [navLinks,setNavLinks]:any = useState([])
    const [t,i18n] = useTranslation('common')

    useEffect(()=>{
        setNavLinks([
            {
                label: t('pill_menu.equity'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/trade`
            },
            {
                label: t('pill_menu.fixed_income'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/trade`
            },
            {
                label: t('pill_menu.my_accounts'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/accounts`
            },
            {
                label: t('pill_menu.newsroom'),
                url: process.env.REACT_APP_NEWS_APP_URL
            },
            {
                label: t('pill_menu.research'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/research`
            },
            {
                label: t('pill_menu.mkt_data'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/market-data`
            },
            {
                label: t('pill_menu.analytics'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/analytics`
            },
            {
                label: t('pill_menu.liquidity'),
                url: process.env.REACT_APP_URL
            }
        ])
    },[t])

  return (
      <div className="flex flex-col right-0 left-0 absolute">
          <SSXBar background="light" className="py-[16px] flex justify-between">
              <div>
                  <div className="flex gap-[16px] items-center">
                      <button className="p-[8px] border-1 border-white block md:hidden" onClick={()=>{setToggleSidenav(!toggleSidenav)}}>
                          <i className="icon-bars text-neutral-900"/>
                      </button>
                      {/*<Link to="/"><img src="https://res.cloudinary.com/dxivfczqg/image/upload/v1680257873/dashboard-logo-no-name-dark_vrkbad.svg"
                                    alt="SSX Logo" className="w-[55px]"/></Link>*/}
                  </div>
                  <div className="hidden md:block justify-between">
                      <Ribbon links={navLinks} bgColor="light" currentPageItem="Liquidity"/>
                  </div>
              </div>
              <div>
                  <LanguageSwitcher languages={[{language:t('common:general.english'),shortName:'en',flag:`${process.env.REACT_APP_NEWS_APP_URL}/api/img/uk_cty.svg`},{language:t('common:general.french'),shortName:'fr',flag:`${process.env.REACT_APP_NEWS_APP_URL}/api/img/france_cty.svg`}]} onClick={(a)=>{
                      i18n.changeLanguage(a.shortName)
                          .then(()=>{
                              localStorage.setItem('userLang',a.shortName)
                              document.documentElement.setAttribute('lang',a.shortName)
                          })
                  }} initialLanguage={i18n.language}/>
              </div>

          </SSXBar>
          {toggleSidenav &&
              <div className="bg-neutral-900 flex flex-col w-[188px] h-screen flex-1 z-10 text-neutral-50 overflow-y-auto nav-menu">
                  {navLinks.map((navLink:{label:string,url:string},ind:number) => (
                      <div key={ind} className="text-white active:bg-primary-500">
                          <Link to={navLink.url} className=" p-[16px] block">{navLink.label}</Link>
                      </div>
                  ))}
              </div>
          }
      </div>
  )
}

export default NavigationSection
