import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, CountrySelector, LoadingSpinner, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface CurrPairProps {
    currPairDets:any,
    showPanelTitle:(a:boolean)=>void
}

const EditCurrPairs = (props:CurrPairProps) => {
    const {currPairDets,showPanelTitle} = props
    const [baseCurr,setBaseCurr] = useState(currPairDets.cp_base_curr_code)
    const [exchangeCurr,setExchangeCurr] = useState(currPairDets.cp_exch_curr_code)
    const [pairName,setPairName] = useState(currPairDets.cp_pair_name)
    const [currencies,setCurrencies]:any = useState([])
    const [errMsg,setErrMsg] = useState('')
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const {t} = useTranslation('common')

    useEffect(()=>{
        //load currencies
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },[])

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    useEffect(()=>{
        //get the full name of the currencies
        if (currencies.length > 0 && Object.keys(currPairDets).length > 0){
            let tmpBase
            tmpBase = currencies.filter((curr:any)=>curr.optionShortName.toLowerCase().includes(currPairDets.cp_base_curr_code.toLowerCase()))
            setBaseCurr(tmpBase[0].optionName)

            let tmpExch
            tmpExch = currencies.filter((curr:any)=>curr.optionShortName.toLowerCase().includes(currPairDets.cp_exch_curr_code.toLowerCase()))
            setExchangeCurr(tmpExch[0].optionName)
        }
    },[currencies,currPairDets])

    useEffect(()=>{
        if (baseCurr && exchangeCurr && baseCurr === exchangeCurr){
            setErrMsg(t('common:currency_pairs.base_exchange_curr_cant_be_same'))
        }else{
            setErrMsg('')
        }
    },[baseCurr,exchangeCurr])

    function updateCurr(){
        //validation
        if (baseCurr && exchangeCurr && baseCurr === exchangeCurr){
            setErrMsg(t('common:currency_pairs.base_exchange_rate_cant_be_same'))
            return null
        }else if (!pairName){
            setErrMsg(t('common:currency_pairs.pair_name_required'))
            return null
        }else{
            setErrMsg('')
        }

        setLoading(true)
        let combPair = `${baseCurr}/${exchangeCurr}` //combined pair

        let formData = {
            pairCode:combPair,
            pairName:pairName,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/currency-pairs/${currPairDets.cp_pair_code.replace('/','%2F')}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    updateCurr()
                }} className="flex flex-col flex-1">
                    <div className="flex flex-col gap-[40px] flex-1">
                        <div className="flex flex-col gap-[8px]">
                            <label className="paragraph2Medium">{t('common:request_lp.base_currency')}</label>
                            <CountrySelector options={currencies} initialCountry={baseCurr} selectedOption={(val)=>{setBaseCurr(val.optionShortName)}} inputName="baseCurrency"/>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <label className="paragraph2Medium">{t('common:request_lp.exchange_currency')}</label>
                            <CountrySelector options={currencies} initialCountry={exchangeCurr} selectedOption={(val)=>{setExchangeCurr(val.optionShortName)}} inputName="exchangeCurrency"/>
                        </div>
                        <TextField label={t('common:currency_pairs.pair_name')} placeholder={t('common:currency_pairs.pair_name')} value={pairName ? pairName : ''} onChange={(e)=>{setPairName(e.target.value)}} required/>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:currency_pairs.update_currency_pair')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:currency_pairs.curr_pair_updated')} subText={t('common:currency_pairs.curr_pair_updated_successfully',{pairCode:currPairDets.cp_pair_code})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={t('common:general.error_occurred')} subText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default EditCurrPairs