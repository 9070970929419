import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    DropdownList,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface ActionProps{
    showPanelTitle:(a:boolean)=>void
}
const AddAppAction = (props:ActionProps) => {
    const {showPanelTitle} = props
    const [stage,setStage] = useState(1)
    const [title,setTitle] = useState('')
    const [code,setCode] = useState('')
    const [clearance,setClearance] = useState('1')
    const [desc,setDesc] = useState('')
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [disableBtn,setDisableBtn] = useState(true)
    const {t} = useTranslation('common')
    const clearanceVals = [
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        }
    ]

    useEffect(()=>{
        if (!title || !code || !clearance || !desc) {
            setDisableBtn(true)
        }else{
            setDisableBtn(false)
        }
    },[title,code,clearance,desc])

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    function addAction(){
        setLoading(true)
        let formData = {
            actionCode:code,
            actionTitle:title,
            actionDesc:desc,
            actionClrLvl:clearance,
            action:'codeMaintenance'
        }

        axiosSend({
            url:'/fx/admin/app-actions',
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form className="flex flex-col flex-1" onSubmit={(e)=>{
                    e.preventDefault()
                    addAction()
                }}>
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TextField labelOverrides={{
                            errMsg:t('common:general.this_field_required')
                        }} value={title} label={t('common:app_actions.action_title')} required={true} onChange={(e)=>{setTitle(e.target.value)}} placeholder={t('common:app_actions.enter_action_title')} autoFocus={true}/>
                        <TextField labelOverrides={{
                            errMsg:t('common:general.this_field_required')
                        }} value={code} label={t('common:app_actions.action_code')} required={true} onChange={(e)=>{setCode(e.target.value)}} placeholder={t('common:app_actions.enter_action_code')}/>
                        <div className="flex flex-col gap-[12px]">
                            <label className="paragraph2Medium">{t('common:general.clearance_level')}</label>
                            <DropdownList options={clearanceVals} initialText={clearance} returnedSelection={(e)=>{setClearance(e.value)}} inputName="clearanceLevel"/>
                        </div>
                        <TextArea value={desc} label={t('common:general.description')} required={true} onChange={(e)=>{setDesc(e.target.value)}} placeholder={t('common:general.enter_description')} style={{height:133}}/>
                    </div>
                    <Button type="submit" size="lg" variant="primary" disabled={loading||disableBtn}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:app_actions.add_app_action')}</Button>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    {resp.Status === 'SUCC' &&
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <ActionSuccess headingText={t('common:app_actions.app_action_added')} subText={t('common:app_actions.app_action_added_successfully',{title:title})}/>
                        </div>
                    }
                    {resp.Status === 'FAIL' &&
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <ActionFailed headingText={resp.Message}/>
                        </div>
                    }
                    <Button type="button" size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else {
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default AddAppAction