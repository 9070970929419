import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface AddAccountProps {
    entityId:string
}

const AddBankAccount = (props:AddAccountProps) => {
    const {entityId} = props
    const [currencies,setCurrencies]:any = useState([])
    const [country,setCountry] = useState('')
    const [bank,setBank] = useState('')
    const [bankCode,setBankCode] = useState('')
    const [branch,setBranch] = useState('')
    const [acctNo,setAcctNo] = useState('')
    const [acctName,setAcctName] = useState('')
    const [currency,setCurrency] = useState('')
    const [swift,setSwift] = useState('')
    const [address,setAddress] = useState('')
    const [mainAcct,setMainAcct] = useState('Y')
    const [loading,setLoading] = useState(false)
    const [countryErr,setCountryErr] = useState(false)
    const [currencyErr,setCurrencyErr] = useState(false)
    const [countries,setCountries]:any = useState([])
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [banks,setBanks]:any = useState([])
    const {t} = useTranslation('common')
    const mainAcctOpts = [
        {
            label:t('common:general.yes'),
            value:'Y'
        },
        {
            label:t('common:general.no'),
            value: 'N'
        }
    ]

    useEffect(()=>{
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })

        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name && country.cty_upd_rem){
                        tmp.push({
                            optionName:country.cty_name,
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    function addBankAcct(){
        //validate dropdownlists
        if (!country){
            setCountryErr(true)
            return
        }
        if (!currency){
            setCurrencyErr(true)
            return
        }

        setLoading(true)
        let formData = {
            prtCode:entityId,
            prtCurrCode:currency,
            prtBankCtrCode:country,
            prtBankName:bank,
            bankCode:bankCode,
            prtBankBranch:branch,
            prtBankSwift:swift,
            prtBankAddress:address,
            prtBankAccName:acctName,
            prtBankAccNum:acctNo,
            prtMainAcc:mainAcct,
            action:'savPrtAcc'
        }

        axiosSend({
            url:`/fx/admin/part-acc-v2`,
            method:'POST',
            data:formData
        }).then(res => {
            setLoading(false)
            setResp(res.data)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    addBankAcct()
                }}>
                    <div className="flex flex-col gap-[40px]">
                        <h4 className="font-bold text-[19px]">{t('common:liquidity_providers.add_bank_acct')}</h4>
                        <div className="flex flex-col gap-[8px]">
                            <CountrySelector options={countries} selectedOption={(e)=>{setCountry(e.optionShortName)}} placeholder={t('common:liquidity_providers.select_country')} label={t('common:general.country')} inputName="country"/>
                            {countryErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500"/> {t('common:general.this_field_required')}</p>}
                        </div>
                        <CountrySelector options={banks} selectedOption={(a)=>{
                            setBank(a.optionName)
                            setBankCode(a.optionShortName)
                            a.swiftCode ? setSwift(a.swiftCode) : setSwift('')
                        }} placeholder={t('common:liquidity_providers.select_bank')} label={t('common:general.bank')} inputName="bank"/>
                        <TextField label={t('common:general.branch')} value={branch} onChange={(e)=>{setBranch(e.target.value)}} required={true}/>
                        <TextField label={t('common:liquidity_providers.acct_number')} value={acctNo} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))){
                                return
                            }
                            setAcctNo(e.target.value)
                        }} required={true}/>
                        <TextField label={t('common:liquidity_providers.account_name')} value={acctName} onChange={(e)=>{setAcctName(e.target.value)}} required={true}/>
                        <div className="flex flex-col gap-[8px]">
                            <CountrySelector options={currencies} selectedOption={(e)=>{setCurrency(e.optionShortName)}} placeholder={t('common:general.select_currency')} label={t('common:general.currency')} inputName="currency"/>
                            {currencyErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500"/> {t('common:general.this_field_required')}</p>}
                        </div>
                        <TextField label={t('common:liquidity_providers.swift_code')} value={swift} onChange={(e)=>{setSwift(e.target.value)}} required={true}/>
                        <TextField label={t('common:provider_dets.address')} value={address} onChange={(e)=>{setAddress(e.target.value)}} required={true}/>
                        <div className="flex flex-col gap-[12px]">
                            <DropdownList options={mainAcctOpts} initialText={t('common:general.yes')} returnedSelection={(e)=>{setMainAcct(e.value)}} label={t('common:liquidity_providers.main_account')} inputName="mainAccount"/>
                        </div>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.add_account')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <>
                    {resp.Status === 'SUCC' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionSuccess headingText={t('common:brokers.bank_acct_added')}/>
                            </div>
                            <Button size="lg" variant="primary" onClick={()=>{window.location.reload()}}>{t('common:general.sounds_good')}</Button>
                        </div>
                    }
                    {resp.Status === 'FAIL' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionFailed headingText={resp.Message}/>
                            </div>
                            <Button size="lg" variant="primary" onClick={()=>{setStage(1)}}>{t('common:general.okay')}</Button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default AddBankAccount
