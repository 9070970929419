import DashboardBaseLayout from "../components/DashboardBaseLayout";
import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import RequestFormSecurity from "../components/RequestForm/RequestForm";
import BaseCurrencyOffers from "../components/OffersCard/BaseCurrencyOffers";
import {AnimatePresence, motion} from "framer-motion";
import HDivider from "../components/HDivider";
import {appName, moneyfy} from "../helpers";
import BackButton from "../components/BackButton";
import {useNavigate} from "react-router-dom";
import RequestFormBaseCurrency from "../components/RequestFormOldFormat/RequestFormOldFormat";
import SecuritiesOffers from "../components/OffersCard/SecuritiesOffers";
import {ActionFailed, ActionSuccess, BankSelector, Button, LoadingSpinner, Tab, TextField} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const Trade = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.request_lp')}`
    const [loadingProvs,setLoadingProvs] = useState(false)
    const [securitiesProvsData,setSecuritiesProvsData]:any = useState([])
    const [baseCurrProvsData,setBaseCurrProvsData]:any = useState([])
    const [rawProvsData,setRawProvsData]:any = useState([])
    const [symbols,setSymbols]:any = useState([])
    const [baseCurrencies,setBaseCurrencies]:any = useState([])
    const [requestCard,setRequestCard] = useState(false)
    const [stage,setStage] = useState(1)
    const [sbmtnReq,setSbmtnReq] = useState(false)
    const [reqData,setReqData]:any = useState({})
    const [reqRes,setReqRes]:any = useState({})
    const [offersAvailable,setOffersAvailable] = useState(false)
    const [bankAccts,setBankAccts]:any = useState({})
    const [bankSel,setBankSel] = useState(false)
    const [clientId,setClientId] = useState('')
    const [selectedTab,setSelectedTab] = useState(0) //to specify whether the user has selected the tab with securities selection or to enter custom amount
    const navigate = useNavigate()

    function getRates(rqData:any){
        setLoadingProvs(true)

        axiosSend({
            //url to use is based on whether the user selected the tab with security selection or not
            url: selectedTab === 0 ? `/fx/admin/indicative-rates-symbol?symbol=${rqData.symbol.optionShortName}&quantity=${rqData.quantity}&baseCurr=${rqData.baseCurrency.optionShortName}&price=${rqData.price}&mrkCode=${rqData.mrktCode}` : `/fx/admin/indicative-rates?baseCurr=${rqData.baseCurrency}&exchCurr=${rqData.exchangeCurrency}&baseCurrAmount=${rqData.baseAmount}`,
            method: "GET"
        }).then(provs => {
            if(provs.data.Status && provs.data.Status === 'SUCC'){
                setRawProvsData(provs.data.data)
                let tempProvs:any = []
                provs.data.data.forEach((prov:any) => {
                    tempProvs.push({
                        providerName:prov.lip_name,
                        providerLogo:prov.lp_logos ? prov.lp_logos[44] : '',
                        deliveryTime:prov.lpr_delivery_type,
                        rate:prov.exch_rate,
                        recommended:false,
                        providerId: prov.lpr_lip_code,
                        exchangeCurrency:prov.lpr_exch_curr,
                        exchangeAmount:prov.exch_amt,
                        baseCurrency:prov.lpr_base_curr,
                        baseAmount:prov.base_amount,
                    })
                })

                //if the selected tab is the one with the securities selection
                if (selectedTab === 0){
                    rqData.amount = provs.data.amount
                    rqData.totalAmount = provs.data.total_amount
                    rqData.fees = provs.data.fees
                    rqData.currency = provs.data.curr
                    rqData.feeCode = provs.data.fee_code
                }

                setReqData(rqData)
                //let the offers components have their separate offers providers
                if (selectedTab === 0) setSecuritiesProvsData(tempProvs)
                if (selectedTab === 1) setBaseCurrProvsData(tempProvs)

                setOffersAvailable(true)
                setLoadingProvs(false)
            }
        }).catch(err => {
            console.log(err)
            setLoadingProvs(false)
        })
    }

    useEffect(()=>{
        if (requestCard){
            (document as any).querySelector('body').classList.add('side-panel-open')
            let overlay = document.querySelector('.trans-overlay') as HTMLElement
            overlay.addEventListener('click',function(e:any){
                setRequestCard(!e.target.contains(overlay))
            })
        }
        if (!requestCard){
            (document as any).querySelector('body').classList.remove('side-panel-open')
            setStage(1)
        }
    },[requestCard])

    useEffect(()=>{
        //load symbols
        axiosSend({
            url:'/fx/lov/symb-prices',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempSymb:any = []
                    res.data.data.forEach((symbol:any) => {
                        let tmp = {
                            name:symbol.description,
                            shortName:symbol.symbol,
                            countryFlag:symbol.file_path,
                            price:symbol.price.toString(),
                            currency:symbol.cur_code,
                            mrktCode:symbol.mrk_code,
                            ticker:symbol.symbol
                        }
                        tempSymb.push(tmp)
                    })
                    setSymbols(tempSymb)
                }
            })
            .catch(err => {
                console.log(err)
            })

        //load currencies
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            currencyLongName:currency.cur_name,
                            currencyShortName:currency.cur_code,
                            countryFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setBaseCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },[])

    useEffect(()=>{
        if (Object.keys(reqData).length > 0){
            //get the list of bank accounts for the logged-in user's organisation
            axiosSend({
                url:'/fx/lov/acc-dtls',
                method:"GET"
            })
                .then(res => {
                    setBankSel(false) //set the state of whether a bank is selected to false
                    if (res.data.Status === 'SUCC' && res.data.data){
                        if (res.data.data.length > 0){
                            let bankAcct:any = []
                            for(let i = 0; i < res.data.data.length; i++){
                                if (((reqData.symbol && res.data.data[i].prt_curr_code === reqData.symbol.symbCurrency) || res.data.data[i].prt_curr_code === reqData.exchangeCurrency) && res.data.data[i].prt_bank_status === 'Y'){
                                    let bankInfo = {
                                        bankLogo:res.data.data[i].logos[44],
                                        acctNo:res.data.data[i].prt_bank_account_number,
                                        bankName:res.data.data[i].prt_bank_name,
                                        acctName:res.data.data[i].prt_bank_account_name,
                                        currency:res.data.data[i].prt_curr_code
                                    }
                                    bankAcct.push(bankInfo)
                                }
                            }
                            setBankAccts(bankAcct)
                        }
                    }
                    if (!res.data.data){
                        setBankAccts(res.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },[reqData])

    function submitRequest(){
        setSbmtnReq(true)
        let data = {
            transStatus:'NW',
            accNo:reqData.bankAcctInfo.acctNo,
            action:'savFxReq',
            baseCurr:reqData.providerInfo.baseCurrency,
            exchCurr:reqData.providerInfo.exchangeCurrency,
            baseAmount:reqData.providerInfo.baseAmount,
            exchAmount:reqData.providerInfo.exchangeAmount,
            lipCode:reqData.providerInfo.providerId,
            exchRate:reqData.providerInfo.rate,
            feeCode:reqData.feeCode,
            indicativeRateJson:rawProvsData,
            clientID:clientId
        }

        axiosSend({
            url:selectedTab === 0 ? "/fx/save-actions-symbol" : "/fx/save-actions",
            method:"POST",
            data:data
        }).then(res => {
            setReqRes(res.data)
            localStorage.removeItem('fx_data')
            setStage(stage + 1)
            setSbmtnReq(false)
        }).catch(err => {
            setReqRes(err.response.data)
            console.log(err)
            setStage(stage + 1)
            setSbmtnReq(false)
        })
    }

    const tabs = [
        {
            tabName:t('common:request_lp.liquidity_for_security'),
            tabComponent:<RequestFormSecurity symbols={symbols} currencies={baseCurrencies} returnedData={(val)=>{getRates(val)}}/>,
            activeBgHexColor:'#E8EEF7'
        },
        {
            tabName:t('common:request_lp.liquidity_for_base_currency'),
            tabComponent:<RequestFormBaseCurrency currencies={baseCurrencies} returnedData={(val)=>{getRates(val)}}/>,
            activeBgHexColor:'#E8EEF7'
        }
    ]

    return (
        <DashboardBaseLayout pageName="trade">
            <div className="mb-[16px]">
                <h6 className={typography.heading6Bold}>{t('common:request_lp.lets_start_here')}</h6>
            </div>
            <div className="rounded-[8px] bg-white mb-[43px]">
                <Tab tabs={tabs} inactiveTabBgHexColor="#F7F9FC" selectedTab={(val)=>{setSelectedTab(val as number)}}/>
            </div>

            {/*lp offers for securities*/}
            <div className={selectedTab !== 0 ? 'hidden' : ''}>
                <SecuritiesOffers offers={securitiesProvsData} loading={loadingProvs} selectedOffer={(a)=>{
                    //tag the selected provider in the raw data
                    let rawProvs = rawProvsData
                    rawProvs.forEach((prov:any) => {
                        if (prov.lpr_lip_code === a.providerId){
                            prov.client_select = 'Y'
                        }
                    })
                    setRawProvsData(rawProvs)
                    setRequestCard(true)
                    let tmp = reqData
                    tmp.providerInfo = a
                    setReqData(tmp)
                    setBankSel(false) //set the state of whether a bank is selected to false
                }} dataAvailable={offersAvailable}/>
            </div>

            {/*lp offers for base currency*/}
            <div className={selectedTab !== 1 ? 'hidden' : ''}>
                <BaseCurrencyOffers offers={baseCurrProvsData} loading={loadingProvs} selectedOffer={(a)=>{
                    //tag the selected provider in the raw data
                    let rawProvs = rawProvsData
                    rawProvs.forEach((prov:any) => {
                        if (prov.lpr_lip_code === a.providerId){
                            prov.client_select = 'Y'
                        }
                    })
                    setRawProvsData(rawProvs)
                    setRequestCard(true)
                    let tmp = reqData
                    tmp.providerInfo = a
                    setReqData(tmp)
                    setBankSel(false) //set the state of whether a bank is selected to false
                }} dataAvailable={offersAvailable}/>
            </div>

            {/*OVERLAY FOR MAKING AN LP REQUEST*/}
            <AnimatePresence>
                {requestCard &&
                    <>
                        <div className="fixed w-full h-full bg-black/40 top-0 left-0 z-[999] trans-overlay">
                            <motion.div className="absolute h-full w-[510px] right-0 top-0 bg-white"
                                        animate={{
                                            x:0,
                                            opacity:1
                                        }}
                                        initial={{
                                            x:100,
                                            opacity:0
                                        }}
                                        exit={{
                                            x:100,
                                            opacity:0
                                        }}
                                        transition={{
                                            duration:0.3
                                        }}
                            >
                                <div className="p-[32px] flex justify-between items-center">
                                    <BackButton disabled={stage === 1 || stage === 4} onClick={()=>{
                                        if (stage > 1){
                                            setStage(stage - 1)
                                        }
                                    }}/>
                                    <button onClick={()=> {
                                        setRequestCard(false)
                                        if (stage === 4){
                                            setStage(1)
                                        }
                                    }}>
                                        <i className="icon-times"/>
                                    </button>
                                </div>
                                <HDivider twBorderColor="border-neutral-100"/>
                                <div>
                                    {stage === 1 &&
                                        <div className="p-[32px]">
                                            <div className="mb-[40px]">
                                                <h6 className="paragraph-md-regular">Client ID</h6>
                                                <p className="text-[14px] leading-[17px] font-[400]">Enter a name and/or number to help you identify this client.</p>
                                            </div>
                                            <TextField placeholder="Enter client ID" required={true} value={clientId} onChange={(e)=>{setClientId(e.target.value)}}/>
                                            <div className="absolute bottom-0 left-0 right-0 p-[32px]">
                                                <Button size="lg" variant="primary" disabled={!clientId} onClick={()=>{
                                                    setStage(stage + 1)
                                                }}>Confirm & Proceed</Button>
                                            </div>
                                        </div>
                                    }
                                    {stage === 2 && //bank selection
                                        <>
                                            <div className="p-[32px]">
                                                <div className="mb-[40px]">
                                                    <h6 className="text-neutral-900 font-bold text-[19px]">Select Bank Account</h6>
                                                    <p className="text-neutral-900 text-[14px]">Where should your exchanged funds be deposited?</p>
                                                </div>
                                                <BankSelector bankAcct={bankAccts} selectedBank={(bnk)=>{
                                                    let tmp = reqData
                                                    tmp.bankAcctInfo = bnk
                                                    setReqData(tmp)
                                                    setBankSel(true)
                                                }}/>
                                            </div>
                                            <div className="absolute bottom-0 w-full p-[16px]">
                                                <Button size="lg" variant="primary" disabled={!bankSel} onClick={()=>{
                                                    setStage(stage + 1)
                                                }}>Confirm & Proceed</Button>
                                            </div>
                                        </>
                                    }
                                    {stage === 3 && //summary page
                                        <>
                                            <div className="p-[32px]">
                                                <div className="mb-[40px]">
                                                    <h6 className="text-neutral-900 font-bold text-[19px]">Transaction Summary</h6>
                                                    <p className="text-neutral-900 text-[14px]">Verify if the information below is correct</p>
                                                </div>
                                                {/*SELECTED CURRENCIES*/}
                                                <div className="flex flex-col gap-[8px] mb-[40px]">
                                                    <p className="text-neutral-900 text-[14px] font-bold">Amount</p>
                                                    <div className="p-[16px] rounded-[8px] bg-neutral-50 flex justify-between items-center">
                                                        <div className="flex gap-[8px] items-center">
                                                            {reqData.baseFlag && <img src={reqData.baseFlag} alt="Currency flag"/>} {/*base flag*/}
                                                            {reqData.baseCurrency.optionFlag && <img src={reqData.baseCurrency.optionFlag} alt="Currency flag"/>} {/*symbol flag*/}
                                                            <span>{`${moneyfy(reqData.providerInfo.baseAmount,reqData.providerInfo.baseCurrency)}`}</span>
                                                        </div>
                                                        <div><i className="icon-arrows-left-right text-neutral-300 text-2xl"/></div>
                                                        <div className="flex gap-[8px] items-center">
                                                            {reqData.exchFlag && <img src={reqData.exchFlag} alt="Currency flag"/>} {/*exchange flag*/}
                                                            {reqData.symbol && <img src={reqData.symbol.optionFlag} alt="Currency flag"/>} {/*symbol flag*/}
                                                            <span>{`${moneyfy(reqData.providerInfo.exchangeAmount,reqData.providerInfo.exchangeCurrency)}`}</span>
                                                        </div>
                                                    </div>
                                                    <p className="text-neutral-900">{`${reqData.providerInfo.baseCurrency} 1.0000  = ${reqData.providerInfo.exchangeCurrency} ${reqData.providerInfo.rate}`}</p>
                                                </div>

                                                {/*SELECTED PROVIDER*/}
                                                <div className="flex flex-col gap-[8px] mb-[40px]">
                                                    <p className="text-neutral-900 text-[14px] font-bold">Provider</p>
                                                    <div className="p-[16px] flex items-center gap-[16px] bg-neutral-50 rounded-[8px]">
                                                        {reqData.providerInfo.providerLogo ?
                                                            <img src={reqData.providerInfo.providerLogo} alt="provider" className="w-[32px] h-[32px]"/>
                                                            :
                                                            <div className="w-[32px] h-[32px] bg-neutral-100 rounded-[8px]"/>
                                                        }
                                                        <span>{reqData.providerInfo.providerName}</span>
                                                    </div>
                                                </div>

                                                {/*SELECTED BANK ACCOUNT*/}
                                                <div className="flex flex-col gap-[8px] mb-[40px]">
                                                    <p className="text-neutral-900 text-[14px] font-bold">Bank Account</p>
                                                    <div className="rounded-[8px] p-[16px] flex gap-[12px] bg-neutral-50">
                                                        <div className="rounded-[8px] w-[44px] h-[44px] overflow-hidden">
                                                            {reqData.bankAcctInfo.bankLogo ?
                                                                <img src={reqData.bankAcctInfo.bankLogo} alt="Bank logo" className="w-[44px] h-[44px]"/>
                                                                :
                                                                <div className="w-[44px] h-[44px] bg-neutral-100 rounded-[8px]"/>
                                                            }
                                                        </div>
                                                        <div className="w-full">
                                                            <p className="font-bold text-[16px] mb-[8px]">{reqData.bankAcctInfo.acctNo}</p>
                                                            <p className="text-[14px] mb-[8px]">{reqData.bankAcctInfo.bankName}</p>
                                                            <p className="text-[14px] mb-[8px]">{reqData.bankAcctInfo.acctName}</p>
                                                            <p className="text-[14px] mb-[8px]">{reqData.bankAcctInfo.currency}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-[32px] absolute bottom-0 w-full flex flex-col gap-[8px]">
                                                {!sbmtnReq &&
                                                    <Button type="button" variant="primary" size="lg" onClick={()=>{submitRequest()}}>Confirm & Send Request</Button>
                                                }
                                                {sbmtnReq &&
                                                    <Button type="button" variant="primary" size="lg" disabled={true}><LoadingSpinner variant={"primary"}/></Button>
                                                }
                                                <p className="flex items-center gap-[5px] text-[14px]"><i className="icon-info-circle text-neutral-500"/> <span className="text-neutral-500">Delivery for this transaction may take 24hrs - 48hrs</span></p>
                                            </div>
                                        </>
                                    }
                                    {stage === 4 &&
                                        <>
                                            <div className="absolute left-0 right-0 h-full flex justify-center items-center">
                                                {reqRes.Message && reqRes.Status === 'SUCC' &&
                                                    <ActionSuccess headingText="Request Sent!" subText={reqRes.Message}/>
                                                }
                                                {reqRes.Message && reqRes.Status === 'ERR' &&
                                                    <ActionFailed headingText="Request Failed" subText="An error occurred. Kindly contact Admin if the problem persists."/>
                                                }
                                                {reqRes.Message  && reqRes.Status === 'FAIL' &&
                                                    <ActionFailed headingText="Request Sent!" subText={reqRes.Message}/>
                                                }
                                            </div>
                                            <div className="absolute bottom-0 left-0 right-0 p-[32px]">
                                                {reqRes.Message && reqRes.Status === 'SUCC' &&
                                                    <Button type="button" size="lg" variant="primary" onClick={()=>{navigate('/transaction-history')}}>Sounds Good</Button>
                                                }
                                                {reqRes.Message && (reqRes.Status === 'ERR' || reqRes.Status === 'FAIL') &&
                                                    <Button type="button" size="lg" variant="primary" onClick={()=>{setStage(stage - 1)}}>Try Again</Button>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </motion.div>
                        </div>
                    </>
                }
            </AnimatePresence>
        </DashboardBaseLayout>
    )
}
export default Trade
