import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import AppModulesTable from "../components/AppModulesTable/AppModulesTable";
import {axiosSend} from "../utils/request";
import EditAppModule from "../components/AppModulesContent/EditAppModule";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import dayjs from "dayjs";
import AddAppModule from "../components/AppModulesContent/AddAppModule";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const AppModules = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.app_modules')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [modules,setModules]:any  = useState([])
    const [modulesOri,setModulesOri]:any  = useState([])
    const [loading,setLoading] = useState(true)
    const [editModulePnl,setEditModulePnl] = useState(false)
    const [moduleDets,setModuleDets]:any = useState({})
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [showLogs,setShowLogs] = useState(false)
    const [logs,setLogs]:any = useState([])
    const [addModulePnl,setAddModulePnl] = useState(false)
    const [hasAccess,setHasAccess] = useState(true)

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadAppModules()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function loadAppModules(){
        axiosSend({
            url:'/fx/admin/apps',
            method:'GET',
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((module:any)=>{
                    tmp.push({
                        moduleName:module.app_name,
                        moduleCode:module.app_code,
                        remarks:module.app_upd_rem
                    })
                })
                setModules(tmp)
                setModulesOri(tmp)
            }

            if (res.data.logs && res.data.logs.length > 0){
                let tmp:any = []
                res.data.logs.forEach((log:any)=>{
                    tmp.push({
                        timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        logDescription:log.aud_action,
                        user:log.aud_usr_id,
                        reference:log.aud_id
                    })
                })
                setLogs(tmp)
            }
            setLoading(false)
        })
    }

    function searchModule(qry:string){
        setSrchQry(qry)
        // qry ? setSearchActive(true) : setSearchActive(false)
        setModules(modulesOri.filter((module:any)=>module.moduleName.toLowerCase().includes(qry.toLowerCase())))
    }

    function getModule(moduleCode:string){
        axiosSend({
            url:`/fx/admin/apps/${moduleCode}`,
            method:'GET'
        }).then(res => {
            if (res.data.data){
                setModuleDets(res.data.data[0])
                setEditModulePnl(true)
            }
        })
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="app-modules">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.app_modules')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setAddModulePnl(true)}}>{t('common:app_modules.add_app_module')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:app_modules.search_app_modules')} onChange={(e)=>{searchModule(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <AppModulesTable modules={modules} callback={(e)=>{getModule(e.moduleCode)}}/>
            }
            {editModulePnl &&
                <SidePanel closeFxn={()=>{setEditModulePnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={`${showPanelTitle ? t('common:app_modules.edit_app_module') : ''}`} subtext={moduleDets.app_name}>
                    <EditAppModule moduleDets={moduleDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={true}/>} title={t('common:general.activity_logs')}>
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {addModulePnl &&
                <SidePanel closeFxn={()=>{setAddModulePnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={`${showPanelTitle ? t('common:app_modules.add_app_module') : ''}`}>
                    <AddAppModule showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default AppModules