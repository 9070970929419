import React from 'react'
import {user as userType} from './type'
import {axiosSend} from './request'

function UpdateUser() {
  const [user, setUser] = React.useState<userType>();

  async function UpdateUser() {
    //perform axios request to
  try{
    const response = await axiosSend({
      url: '/users/self',
      method: 'GET'})
      if(response.data.Status === "FAIL"){
        setUser({
          profile:[],
          userActions:[],
          apps:[],
          forms:[],
          token:''
        })
      }else {
        //populate list of user actions into an array
        let uActions:any = []
        for (var i = 0; i < response.data.actions.length; i++){
          if (!uActions.includes(Object.keys(response.data.actions[i])[0])){
            uActions.push(Object.keys(response.data.actions[i])[0])
          }
        }
        //insert the populated list into the response data
        response.data.userActions = uActions
        setUser(response.data)
      }

  }catch(err) {
    setUser({
      profile:[],
      userActions:[],
      apps:[],
      forms:[],
      token:''
    })
  }

  }

  React.useEffect(()=>{
    //faking request
    setTimeout(()=>{
      UpdateUser()
    }, 2000)
  }, [])

  return [user, UpdateUser];
}


function RefreshMenus():any{
  const [menus, setMenus] = React.useState<Array<any>>([])

  React.useEffect(()=>{
    //faking request
    setTimeout(()=>{
      setMenus([])
    }, 2000)
  }, [])


  return [menus, setMenus]
}

export const useUpdateUser = UpdateUser
export const useRefreshMenus = RefreshMenus
