import React, {useEffect} from "react"
import {Backdrop, CardBody, CardFooter, CardHead, ModalCard} from "./style";
import {PopupModalProps} from "./type";
import {Button, ButtonOutline} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const ExportPreviewModal = (props:PopupModalProps) => {
    const {title,children,secondaryAction,primaryAction,closeFxn,primaryBtnText,secondaryBtnText} = props
    const {t} = useTranslation('common')

    useEffect(()=>{
        (document.querySelector('body') as HTMLElement).classList.add('side-panel-open')
    },[])

    function enableScroll(){
        (document.querySelector('body') as HTMLElement).classList.remove('side-panel-open')
    }

    return (
        <Backdrop>
            <ModalCard>
                <CardHead>
                    <h3>{title}</h3>
                    <button onClick={()=>{
                        closeFxn()
                        enableScroll()
                    }}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.88 16L23.6133 10.28C23.8644 10.0289 24.0055 9.6884 24.0055 9.33333C24.0055 8.97826 23.8644 8.63773 23.6133 8.38666C23.3623 8.13559 23.0217 7.99454 22.6667 7.99454C22.3116 7.99454 21.9711 8.13559 21.72 8.38666L16 14.12L10.28 8.38666C10.0289 8.13559 9.6884 7.99454 9.33333 7.99454C8.97826 7.99454 8.63773 8.13559 8.38666 8.38666C8.13559 8.63773 7.99454 8.97826 7.99454 9.33333C7.99454 9.6884 8.13559 10.0289 8.38666 10.28L14.12 16L8.38666 21.72C8.26169 21.8439 8.1625 21.9914 8.09481 22.1539C8.02712 22.3164 7.99226 22.4906 7.99226 22.6667C7.99226 22.8427 8.02712 23.0169 8.09481 23.1794C8.1625 23.3419 8.26169 23.4894 8.38666 23.6133C8.51061 23.7383 8.65808 23.8375 8.82056 23.9052C8.98304 23.9729 9.15731 24.0077 9.33333 24.0077C9.50934 24.0077 9.68362 23.9729 9.8461 23.9052C10.0086 23.8375 10.156 23.7383 10.28 23.6133L16 17.88L21.72 23.6133C21.8439 23.7383 21.9914 23.8375 22.1539 23.9052C22.3164 23.9729 22.4906 24.0077 22.6667 24.0077C22.8427 24.0077 23.017 23.9729 23.1794 23.9052C23.3419 23.8375 23.4894 23.7383 23.6133 23.6133C23.7383 23.4894 23.8375 23.3419 23.9052 23.1794C23.9729 23.0169 24.0077 22.8427 24.0077 22.6667C24.0077 22.4906 23.9729 22.3164 23.9052 22.1539C23.8375 21.9914 23.7383 21.8439 23.6133 21.72L17.88 16Z" fill="#070B12"/>
                        </svg>
                    </button>
                </CardHead>
                <CardBody>
                    <div className="card-content">
                        {children}
                    </div>
                </CardBody>
                <CardFooter>
                    {secondaryBtnText && secondaryAction &&
                        <div className="w-[200px]">
                            <ButtonOutline size="md" variant="primary" onClick={()=>{secondaryAction()}}>{secondaryBtnText}</ButtonOutline>
                        </div>
                    }
                    <div className="w-[200px]">
                        <Button size="md" variant="primary" onClick={()=>{primaryAction()}}>{primaryBtnText ? primaryBtnText : t('common:general.okay')}</Button>
                    </div>
                </CardFooter>
            </ModalCard>
        </Backdrop>
    )
}

export default ExportPreviewModal