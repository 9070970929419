import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextArea, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface EditConfigProps{
    sysConfigDets:any,
    showPanelTitle:(a:boolean)=>void
}

const EditSysConfig = (props:EditConfigProps) => {
    const {sysConfigDets,showPanelTitle} = props
    const [code,setCode] = useState(sysConfigDets.config_code)
    const [name,setName] = useState(sysConfigDets.config_name)
    const [value,setValue] = useState(sysConfigDets.value)
    const [remarks,setRemarks] = useState(sysConfigDets.config_up_rem)
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [stage,setStage] = useState(1)
    const [errMsg,setErrMsg] = useState('')
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    function submitForm() {
        if (!code || !name || !value) {
            setErrMsg(t('common:general.some_required_fields_empty'))
            return null
        }

        setLoading(true)
        let formData = {
            configCode: code,
            configName: name,
            configValue: value,
            configUpdRem: remarks,
            action: 'codeMaintenance'
        }

        axiosSend({
            url: `/fx/admin/sys-config/${sysConfigDets.config_code}`,
            method: 'PUT',
            data: formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    submitForm()
                }} className="flex flex-col flex-1 gap-[40px]">
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TextField label={t('common:system_config.config_code')} required={true} value={code} onChange={(e)=>{setCode(e.target.value)}}/>
                        <TextField label={t('common:system_config.config_name')} required={true} value={name} onChange={(e)=>{setName(e.target.value)}}/>
                        <TextField label={t('common:system_config.config_value')} required={true} value={value} onChange={(e)=>{setValue(e.target.value)}}/>
                        <TextArea label={t('common:general.remarks')} value={remarks ? remarks : ''} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}}/>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 mb-[4px] text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:system_config.update_system_config')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:system_config.system_config_updated')} subText={t('common:system_config.system_config_updated_msg',{configName:sysConfigDets.config_name})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={t('common:general.error_occurred')} subText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default EditSysConfig