//create an axios request in typescript with parameters
import {iApiRequest} from './type'
import {apiUrl} from "../helpers";
import axios from "axios";

axios.defaults.withCredentials = true

function createRequest(params: iApiRequest): Promise<any> {
    const locale:string = localStorage.getItem('userLang')!

    return axios({
        url: `${apiUrl}${params.url}`,
        // url: params.url,
        method: params.method,
        data: params.data,
        headers: params.headers ? params.headers : {
            'Content-Type': 'application/json',
            'Accept-Language': locale
        },
        withCredentials: true
    })
}

export const axiosSend = createRequest