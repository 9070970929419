import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useState} from "react";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import {exportExcel, exportPDF} from "../../helpers";
import dayjs from "dayjs";
import UserContext from "../../utils/userContext";
import UnauthorisedAccess from "../../components/UnauthorisedAccess";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, LoadingSpinner, TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const AvgLpTrxTime = () => {
    const [avgTime,setAvgTime]:any = useState([])
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [loading,setLoading] = useState(false)
    const [errMsg,setErrMsg] = useState('')
    const [emptyData,setEmptyData] = useState(false)
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const userDets:any = useContext(UserContext)
    const [reportPeriod,setReportPeriod] = useState('')
    const {t} = useTranslation('common')

    function getAvgTimes(){
        if (!startDate || !endDate){
            setErrMsg(t('common:reports.start_end_date_required'))
            return
        }else{
            setErrMsg('')
        }

        setLoading(true)
        axiosSend({
            url:`/fx/reports/lp-avg-trx-time?startDate=${startDate}&endDate=${endDate}`,
            method:'GET'
        }).then(res => {
            if(res.data.data){
                setAvgTime(res.data.data)
                setEmptyData(false)
                setReportPeriod(dayjs(startDate).format('MMM D YYYY')+' - '+dayjs(endDate).format('MMM D YYYY'))
            }else{
                setEmptyData(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        avgTime.forEach((item:any) => {
            formattedData.push({
                'Provider':item.lip_name,
                'Average Transaction Time':item.hrs+' hrs'
            })
        })
        exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,t('common:reports.avg_lp_trx_time'))
    }

    if (Object.keys(userDets).length > 0 && userDets.profile[0].user_type !== 'SX'){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal && <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,t('common:reports.avg_lp_trx_time'))
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
                <div id="reportPreview">
                    <TableAlternative thead={[t('common:reports.provider_name'),t('common:reports.avg_trx_time')]}>
                        {avgTime.length > 0 && avgTime.map((item:any,index:number)=> (
                            <tr key={index}>
                                <td>{item.lip_name}</td>
                                <td>{item.hrs ? item.hrs : 0} hrs</td>
                            </tr>
                        ))}
                    </TableAlternative>
                </div>
            </ExportPreviewModal>}
            <div className="mb-[16px]">
                <div className="mb-[16px]">
                    <h6 className={typography.heading6Bold}>{t('common:reports.avg_lp_trx_time')}</h6>
                </div>
                <div className="flex gap-[16px] justify-between">
                    <div className="w-1/2">
                        <form onSubmit={(e)=> {
                            e.preventDefault()
                            getAvgTimes()
                        }} className="flex gap-[8px]">
                            <div className="w-full">
                                <DateRangePicker selectedDateRange={(a)=>{
                                    setStartDate(dayjs(a.startDate).format('YYYY-MM-DD'))
                                    if (a.endDate) setEndDate(dayjs(a.endDate).format('YYYY-MM-DD'))
                                }} placeholder={t('common:reports.pick_date_range')}/>
                            </div>
                            <div className="w-1/2">
                                <Button size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>
                            </div>
                        </form>
                        {errMsg && <p className="text-error-500 mt-[8px]">{errMsg}</p>}
                    </div>
                    {/*<VDivider height={50}/>*/}
                    <div className="w-[250px]">
                        <ButtonOutline size="lg" variant="primary" type="button" disabled={avgTime.length === 0 || loading} onClick={()=>{setExportModal(true)}}>Export</ButtonOutline>
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                    <p className="text-white">{t('common:reports.provider_name')}</p>
                    <p className="text-white">{t('common:reports.avg_trx_time')}</p>
                </div>
                <div className="flex flex-col gap-[8px]">
                    {!loading &&
                        <>
                            {avgTime.length === 0 && !emptyData &&
                                <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                            }
                            {avgTime.length === 0 && emptyData &&
                                <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                            }
                            {avgTime.length > 0 && avgTime.map((item:any,index:number)=> (
                                <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                    <p>{item.lip_name}</p>
                                    <p>{item.hrs ? item.hrs : 0} hrs</p>
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>
        </DashboardBaseLayout>
    )
}

export default AvgLpTrxTime