import DashboardBaseLayout from "../components/DashboardBaseLayout";
import {useContext, useEffect, useState} from "react";
import UserContext from "../utils/userContext";
import dayjs from "dayjs";
import {axiosSend} from "../utils/request";
import SearchField from "../components/SearchField";
import {appName} from "../helpers";
import {DropdownList, Button, SideModal, LoadingSpinner, TextField,/*, AmountInput*/
NumberField,} from "@secondstaxorg/sscomp";
import PaymentHistoryTable from "../components/PaymentHistoryTable/Table";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const PaymentHistory = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.transaction_history')}`
    const userDets:any = useContext(UserContext)
    const [txns,setTxns]:any = useState([])
    const [txnsOri,setTxnsOri]:any = useState([])
    const [filter,setFilter] = useState('all')

    const [isSendMoneyModalOpen, setSendMoneyModalOpen] = useState(false)

    useEffect(()=>{
        if(Object.keys(userDets).length > 0 && userDets.profile.length > 0){
            let url = "/mf/all-transactions"

            axiosSend({
                url:url as string,
                method:'GET'
            }).then(res=> {
                if (res.data.data){
                    let tmp:any = []
                    res.data.data.forEach((item:any)=>{
                        tmp.push({
                            broker:item.mt_txn_broker_name,
                            baseAmount:item.mt_txn_amount,
                            baseCurrency:item.mt_txn_curr,
                            status:item.mt_txn_status,
                            transactionId:item.mt_txn_id,
                            date:dayjs(item.mt_txn_date).format('MMM DD, YYYY'),
                        })
                    })
                    let srtTmp = tmp.sort((a:any,b:any)=>(b.transactionId - a.transactionId))
                    setTxns(srtTmp)
                    setTxnsOri(srtTmp)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    },[userDets,isSendMoneyModalOpen])

    useEffect(()=>{
            if(filter === 'all'){
                setTxns(txnsOri)
            }else{
                setTxns(txnsOri.filter((txn:any)=>txn.status === filter))
            }
    },[txnsOri,filter])

    const options = [
        {
            label:t('common:payment_history.filter_by_status'),
            value: 'all'
        },
        {
            label:t('common:payment_history.status_new'),
            value: 'N'
        },
        {
            label:t('common:payment_history.status_successful'),
            value: 'S'
        },
        {
            label:t('common:payment_history.status_failed'),
            value: 'F'
        },
    ]

    let debounce: NodeJS.Timeout | null = null // Declare a debounce timer variable outside the function

    function srchTxn(qry: string) {
            if (debounce) {
                clearTimeout(debounce) // Cancel the previous timer if it exists
            }

            debounce = setTimeout(() => {
                if (!qry)
                    setTxns(txnsOri)
                else
                    setTxns(txnsOri.filter((txn:any)=>txn.broker.toLowerCase().includes(qry.toLowerCase())))
            }, 1200);

        return () => {
            if (debounce) {
                clearTimeout(debounce)
            }
        };
    }

    function TopLevelButtons () {
        return <Button
            size="sm"
            variant="primary"
            width="fit-content"
            onClick={() => {
                setSendMoneyModalOpen(true)
            }}>
                {t("common:payment_history.send_money")}
        </Button>
    }

    return (
        <DashboardBaseLayout pageName="payment-history" topLevelButtons={<TopLevelButtons />}>
            <div className="flex justify-between items-center">
                <h6 className={typography.heading6Bold}>{t("common:payment_history.flutterwave_payments")}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="w-[200px]">
                        <DropdownList options={options} initialText={t('common:payment_history.filter_by_status')} returnedSelection={(e)=>{
                            setFilter(e.value)
                        }} inputName="sortBy"/>
                    </div>
                    <SearchField placeholder={t('common:payment_history.search_by')} onChange={(e)=>{srchTxn(e.target.value)}}/>
                </div>
            </div>
            {/*TRANSACTION HISTORY TABLE WITH TRANSACTION DETAILS PANEL*/}
            <PaymentHistoryTable transactions={txns}/>
            {isSendMoneyModalOpen && <SendMoneyModal closeModal={() => {
                setSendMoneyModalOpen(false)
            }} />}
        </DashboardBaseLayout>
    )
}

function SendMoneyModal (props: SendModalProps) {

    const {t} = useTranslation('common')

    const [broker, setBroker] = useState('')
    const [amount, setAmount] = useState(0)
    const [amountCurrency, setAmountCurrency] = useState('')
    const [refNumber, setRefNumber] = useState('')

    const [stage, setStage] = useState(0)
    const [confirming, setConfirming] = useState(false)
    const [banks, setBanks] = useState([] as { value: string, label: string, broker_id: string, bank_code: string, prt_bank_account_number: string }[])
    const [paymentInitData, setPaymentInitData] = useState(null as unknown as {Status: string, link: string, tx_ref: string})
    const [paymentStatus, setPaymentStatus] = useState(null as unknown as boolean)
    const [iframeLoading, setIframeLoading] = useState(false)

    const stages = [
        {
            title: t("common:payment_history.send_money_step1_title"),
            subtitle: t("common:payment_history.send_money_step1_subtitle"),
            canGoBack: false,
        },
        {
            title: t("common:payment_history.send_money_step2_title"),
            subtitle: t("common:payment_history.send_money_step2_subtitle"),
            canGoBack: true,
        },
        {
            title: '',
            subtitle: '',
            canGoBack: true,
        },
        {
            title: '',
            subtitle: '',
            canGoBack: false,
        },
    ]

    function goNext () {
        setConfirming(true)
        setTimeout(() => {
            setConfirming(false)
            setStage(stage + 1)
        }, 0)
    }

    function goBack () {
        setStage(stage - 1)
    }

    useEffect(()=>{
        axiosSend({
            url:'/mf/lov/mf-brokers',
            method:'GET'
        }).then(res=> {
            if (res.data.data){
                let tmp:any = []
                res.data.data.forEach((item:any)=>{
                    tmp.push({
                        value: item.broker_id,
                        label: item.prt_bank_account_name,
                        ...item,
                    })
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        if (stage !== 2) return;

        setIframeLoading(true)

        let _bank = banks.filter(n => n.broker_id === broker)[0];
        if (!_bank) return;

        axiosSend({
            url:'/mf/fund-account',
            method:'POST',
            data: {
                action: 'mfFundAccount',
                currency: amountCurrency,
                amount: amount,
                payRef: refNumber,
                broker: _bank.broker_id,
                bankName: _bank.bank_code,
                bankAccNo: _bank.prt_bank_account_number,
                redirectURL: window.location.href.replace('payment-history', 'payment-redirect'),
            }
        }).then(res=> {
            if (res.data.Status !== 'SUCC') {
                console.log(res.data.Message)
                return
            }
            
            const tx_ref = res.data.tx_ref;
            setPaymentInitData(res.data);

            const interval = setInterval(() => {
                axiosSend({
                    url:'/mf/verify-payment-status?tx_ref=' + tx_ref,
                    method:'GET',
                }).then(res => {
                    
                    if (res.data.Status === "SUCCESS") {
                        clearInterval(interval)

                        setPaymentInitData({
                            link: window.location.href.replace('payment-history', 'payment-redirect') + '?tx_ref=' + tx_ref,
                            Status: 'SUCCESS',
                            tx_ref: tx_ref
                        })

                        setPaymentStatus(true)
                        
                        // if (res.data.Status === 'SUCCESS') {
                        //     setPaymentStatus(true)
                        //     setStage(3)
                        // }

                        return;
                    }

                    // if failed, it could be because payment hasnt been processed yet. keep polling if thats so. else show fail screen

                }).catch(err => {
                    console.log(err)
                })
            }, 2000);
    
            return () => clearInterval(interval);
        }).catch(err => {
            console.log(err)
        })
    },[stage])

    return (
        <SideModal showModal={false}
            modalTitle={stages[stage].title}
            modalSubText={stages[stage].subtitle}
            disabledNav={!stages[stage].canGoBack}
            showNavigation={true}
            navButtonAction={goBack}
            closeModal={props.closeModal}>
            <>
                {stage === 0 && <>
                    <DropdownList
                        options={banks}
                        placeholder={t("common:payment_history.select_bank")}
                        required
                        initialText={broker}
                        returnedSelection={(e) => setBroker(e.value)}
                        width={430}
                        label={t("common:payment_history.select_broker")}
                        inputName="broker"
                    />

                    <div className="p-[32px] w-full absolute bottom-0 right-0 flex justify-between gap-[8px] bg-white">
                        <Button size="lg" variant="primary" disabled={confirming || !broker} onClick={()=>{goNext()}}>{confirming ? <LoadingSpinner variant={"primary"}/> : t('common:general.confirm_proceed')}</Button>
                    </div>
                </>}
                {stage === 1 && <>
                    {/* <AmountInput
                        initialCurrencyCode="GHS"
                        onChange={a => setAmount(a.amount)}
                        label={t("common:payment_history.enter_amount")}
                        placeholder={t("common:payment_history.enter_amount")}
                        priorityCurrencies={[
                            'GHS',
                            'NGN',
                            'ZAR'
                        ]}
                        selectedCurrency={a => setAmountCurrency(a.currency)}
                        required
                        width={430}
                    /> */}

                    <NumberField
                        value={amount}
                        onChange={a => { setAmount(parseFloat(a)); setAmountCurrency('GHS') }}
                        label={t("common:payment_history.enter_amount")}
                        placeholder={t("common:payment_history.enter_amount")}
                        required
                        width={430}
                    />

                    <TextField type="text"
                        label={t('common:payment_history.payment_reference')}
                        placeholder={t('common:payment_history.enter_reference')}
                        value={refNumber}
                        onChange={(e)=>{
                           setRefNumber(e.target.value)
                        }}
                    />

                    <div className="p-[32px] w-full absolute bottom-0 right-0 flex justify-between gap-[8px] bg-white">
                        <Button size="lg" variant="primary" disabled={confirming || !amount || !amountCurrency} onClick={()=>{goNext()}}>{confirming ? <LoadingSpinner variant={"primary"}/> : t('common:general.confirm_proceed')}</Button>
                    </div>
                </>}
                {stage === 2 && <>
                    <div className="flex flex-col h-full gap-[24px]">
                        <div className="flex-1 w-full relative">
                            {iframeLoading && 
                                <div className="flex-1 w-full flex justify-center items-center absolute h-full bg-white">
                                    {t("common:payment_history.loading_payment_form")}
                                </div>
                            }
                            <iframe 
                                src={paymentInitData?.link} 
                                title="Payment processing" 
                                className="w-full h-full" 
                                onLoad={() => setIframeLoading(false)}
                            />
                        </div>
                        <div className="ph-[32px] w-full flex justify-between gap-[8px] bg-white">
                            <Button size="lg" variant="primary" disabled={confirming || !amount || !amountCurrency || paymentStatus === null } onClick={()=>{props.closeModal(true)}}>{confirming ? <LoadingSpinner variant={"primary"}/> : t('common:general.confirm_proceed')}</Button>
                        </div>
                    </div>
                </>}
                {/* {stage === 3 &&       
                    <div className="flex flex-col h-full items-center">
                        <div className="flex-1 flex justify-center">
                            <ActionSuccess
                                headingText={t("common:payment_history.payment_confirmed")}
                                subText={t("common:payment_history.payment_confirmed_subtitle")}
                            />
                        </div>
                        <div className="ph-[32px] w-full flex justify-between gap-[8px] bg-white">
                            <Button size="lg" variant="primary" disabled={confirming || !amount || !amountCurrency || paymentStatus !== true} onClick={()=>{props.closeModal(true)}}>{confirming ? <LoadingSpinner variant={"primary"}/> : t('common:general.confirm_proceed')}</Button>
                        </div>
                    </div>
                } */}
            </>
        </SideModal>
    )
}

interface SendModalProps {
    closeModal: (a: boolean) => void;
}

export default PaymentHistory