import React from "react";
import styled from "styled-components";
import theme from "../theme";
import {useTranslation} from "react-i18next";

interface BackButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{

}

const BackBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${theme.colors["neutral-900"]};
  :disabled{
    cursor: not-allowed;
    color: ${theme.colors["neutral-100"]};
  }
  :disabled i{
    color:${theme.colors["neutral-100"]}
  }
`

const BackButton = (props:BackButtonProps) => {
    const {t} = useTranslation('common')
    return (
        <BackBtn {...props}>
            <i className="icon-arrow-left text-neutral-900"/> {t('common:general.go_back')}
        </BackBtn>
    )
}

export default BackButton
