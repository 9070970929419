import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import {useNavigate} from "react-router-dom";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextArea} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface ConfirmBrokerFundsProps{
    txnNo:string|number,
    setBackBtnDisabled:(a:boolean)=>void
}

const ConfirmBrokerRecvFunds = (props:ConfirmBrokerFundsProps) => {
    const {txnNo,setBackBtnDisabled} = props
    const [stage,setStage] = useState(1)
    const [confirming,setConfirming] = useState(false)
    const [remarks,setRemarks] = useState('')
    const [confRes,setConfRes]:any = useState({})
    const navigate = useNavigate()
    const {t} = useTranslation('common')

    useEffect(()=>{
        setBackBtnDisabled(stage === 2)
    },[stage])

    const confirmLPFunds = () => {
        setConfirming(true)
        let formBody = {
            action: "cfmBRRecpt",
            tranxNo: txnNo,
            updRem: remarks
        }

        axiosSend({
            url: '/fx/save-actions-symbol',
            method: "POST",
            data: JSON.stringify(formBody)
        }).then(res => {
            setConfRes(res.data)
            setStage(stage + 1)
            setConfirming(false)
        })
    }

    return (
        <>
            {stage === 1 &&
                <>
                    <div className="mb-[40px]">
                        <h6 className="text-neutral-900 font-bold text-[19px]">{t('common:transaction_history.confirm_broker_rec_funds')}</h6>
                        <p className="text-neutral-900 text-[14px]">{t('common:transaction_history.youre_about_to_confirm_broker_rec_funds',{txnNo:txnNo})}</p>
                    </div>
                    <div className="mb-[24px]">
                        <TextArea label={t('common:general.remarks')} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}} placeholder={t('common:general.enter_remarks')}/>
                    </div>

                    <div className="p-[32px] w-full absolute bottom-0 right-0 flex justify-between gap-[8px] bg-white">
                        {!confirming &&
                            <button className="primary-button-lg w-full" disabled={!remarks} onClick={()=>{confirmLPFunds()}}>{t('common:general.confirm')}</button>
                        }
                        {confirming &&
                            <button className="primary-button-lg w-full" disabled={true}><LoadingSpinner variant={"primary"}/></button>
                        }
                    </div>
                </>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {confRes.Status === 'SUCC' &&
                            <ActionSuccess headingText={confRes.Message}/>
                        }
                        {confRes.Status === 'FAIL' &&
                            <ActionFailed headingText={confRes.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if(confRes.Status === 'SUCC'){
                            navigate(0)
                        }else{
                            setStage(1)
                        }
                    }}>{confRes.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default ConfirmBrokerRecvFunds
