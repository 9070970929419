import {useEffect, useState} from "react";
import HDivider from "../HDivider";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    EmailField,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";

interface ProvDetProps{
    provDets:any,
    step:number,
    stepFxn:(a:number)=>void
}

const EditProvider = (props:ProvDetProps) => {
    const {provDets,step,stepFxn} = props
    const [providerName,setProviderName] = useState(provDets.lip_name)
    const [providerPhone,setProviderPhone] = useState(provDets.lip_phone)
    const [providerEmail,setProviderEmail] = useState(provDets.lip_email)
    const [providerAddress,setProviderAddress] = useState(provDets.lip_address)
    const [contactName,setContactName] = useState(provDets.lip_contact_name)
    const [contactPhone,setContactPhone] = useState(provDets.lip_contact_phone)
    const [contactEmail,setContactEmail] = useState(provDets.lip_contact_email)
    const [apiUrl,setApiUrl] = useState(provDets.lip_curr_rate_api_url)
    const [contactRemarks,setContactRemarks] = useState('')
    const [numDecimals,setNumDecimals] = useState(provDets.decimal_places)
    const [stage,setStage] = useState(step)
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [errMsg,setErrMsg] = useState('')

    useEffect(()=>{
        setStage(step)
    },[step])

    function updateProvider(){
        if (!providerName || !providerEmail || !providerPhone || !providerAddress){
            setErrMsg('Some fields are required')
            return
        }else{
            setErrMsg('')
        }

        setLoading(true)
        const formData = {
            lpName:providerName,
            lpAddress:providerAddress,
            lpPhone:providerPhone,
            lpEmail:providerEmail,
            lpContactName:contactName,
            lpContactPhone:contactPhone,
            lpContactEmail:contactEmail,
            lpCurrRateApiUrl:apiUrl,
            lpUpdRem:contactRemarks,
            lpDecimalPlaces:numDecimals,
            action:'savLiqProvider'
        }

        axiosSend({
            url:`/fx/admin/liq-prov/${provDets.lip_code}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            stepFxn(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    updateProvider()
                }}>
                    <div className="flex flex-col gap-[24px]">
                        <h6 className="font-bold">Basic Information</h6>
                        <HDivider twBorderColor="border-neutral-900"/>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <TextField label="Name" value={providerName} onChange={(e)=>{setProviderName(e.target.value)}} required={true} placeholder="Enter name"/>
                        <TextField label="Phone Number" value={providerPhone} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setProviderPhone(e.target.value)
                        }} required={true} placeholder="Enter phone number"/>
                        <EmailField label="Email" value={providerEmail} onChange={(e)=>{setProviderEmail(e.target.value)}} required={true} placeholder="Enter email"/>
                        <TextField label="Address" value={providerAddress} onChange={(e)=>{setProviderAddress(e.target.value)}} required={true} placeholder="Enter address"/>
                        <TextArea label="Additional Contact Remarks" value={contactRemarks} onChange={(e)=>{setContactRemarks(e.target.value)}} style={{height:133}} placeholder="Enter remarks"/>

                        <h6 className="font-bold">Contact Information</h6>
                        <HDivider twBorderColor="border-neutral-900"/>
                        <TextField label="Contact Name" value={contactName} onChange={(e)=>{setContactName(e.target.value)}} placeholder="Enter contact name"/>
                        <TextField label="Contact Phone Number" value={contactPhone} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setContactPhone(e.target.value)
                        }} placeholder="Enter contact phone number"/>
                        <EmailField label="Contact Email" value={contactEmail} onChange={(e)=>{setContactEmail(e.target.value)}} placeholder="Enter contact email"/>

                        <h6 className="font-bold">Contact Information</h6>
                        <HDivider twBorderColor="border-neutral-900"/>
                        <TextField label="API URL" value={apiUrl} onChange={(e)=>{setApiUrl(e.target.value)}} placeholder="Enter API URL"/>
                        <TextField label="Forex Rate Decimal Places" type="number" value={numDecimals} onChange={(e)=>{setNumDecimals(e.target.value)}} placeholder="Enter API URL"/>
                        <div className="flex flex-col gap-[4px]">
                            {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                            <Button size="lg" variant="primary" type="submit" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : 'Confirm and Update'}</Button>
                        </div>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    {Object.keys(resp).length > 0 &&
                        <>
                            <div className="flex-1 flex flex-col justify-center items-center">
                                {resp.Status === 'SUCC' &&
                                    <ActionSuccess headingText="Liquidity Provider Updated" subText={`${provDets.lip_name} was updated successfully`}/>
                                }
                                {resp.Status === 'FAIL' &&
                                    <ActionFailed headingText="An Error Occurred" subText={resp.Message}/>
                                }
                                {resp.Status === 'ERR' &&
                                    <ActionFailed headingText="An unexpected error occurred" subText="If the situation persists, you may report it as a bug."/>
                                }
                            </div>
                            <Button size="lg" variant="primary" onClick={()=>{
                                if (resp.Status === 'SUCC'){
                                    window.location.reload()
                                }else {
                                    stepFxn(stage - 1)
                                }
                            }}>{resp.Status === 'SUCC' ? 'Sounds Good' : 'Okay'}</Button>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default EditProvider