import React from "react";

interface EmptyStateProps{
    height:number,
    message:string
}

const EmptyState = (props:EmptyStateProps) => {
    const {height,message} = props
    return (
        <div className="flex flex-col gap-[8px] justify-center items-center bg-white rounded-[8px]" style={{height:height}}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2049_1555)">
                    <path d="M39 6H25V14H39V6Z" fill="#E8EEF7"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M41.0007 6C41.0007 4.895 40.1047 4 39.0001 4H25.0001C23.8955 4 22.9995 4.895 22.9995 6V14C22.9995 15.105 23.8955 16 25.0001 16H39.0001C40.1047 16 41.0007 15.105 41.0007 14V6ZM27.0007 8V12H36.9995V8H27.0007Z" fill="#D2DDEE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M49 56C49 54.896 49.896 54 51 54C52.104 54 53 54.896 53 56V58C53 59.105 52.105 60 51 60H13C11.895 60 11 59.105 11 58V10C11 8.895 11.895 8 13 8H25C26.105 8 27 8.895 27 10V12H37V10C37 8.895 37.895 8 39 8H51C52.105 8 53 8.895 53 10V48C53 49.104 52.104 50 51 50C49.896 50 49 49.104 49 48V12H41V14C41 15.105 40.105 16 39 16H25C23.895 16 23 15.105 23 14V12H15V56H49Z" fill="#D2DDEE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 27H41C42.104 27 43 26.104 43 25C43 23.896 42.104 23 41 23H23C21.896 23 21 23.896 21 25C21 26.104 21.896 27 23 27Z" fill="#D2DDEE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 38H41C42.104 38 43 37.104 43 36C43 34.896 42.104 34 41 34H23C21.896 34 21 34.896 21 36C21 37.104 21.896 38 23 38Z" fill="#D2DDEE"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23 49H41C42.104 49 43 48.104 43 47C43 45.896 42.104 45 41 45H23C21.896 45 21 45.896 21 47C21 48.104 21.896 49 23 49Z" fill="#D2DDEE"/>
                </g>
                <defs>
                    <clipPath id="clip0_2049_1555">
                        <rect width="64" height="64" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <p>{message}</p>
        </div>
    )
}

export default EmptyState