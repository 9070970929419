import PreLoginContent from "../components/PreLoginContent";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import {appName} from "../helpers";
import {Button, LoadingSpinner, PasswordField, EmailField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const Login = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - Login`
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [loading,setLoading] = useState(false)
    const [loginMsg,setLoginMsg] = useState('')
    const [logoutReason,setLogoutReason] = useState('')

    useEffect(()=>{
       if(localStorage.getItem('logoutReason')){
           if (localStorage.getItem('logoutReason') === 'inactivity') setLogoutReason(t('common:login.inactivity_logout'))
       }
       localStorage.removeItem('logoutReason')
    },[])

    function submitLogin(e:any){
        e.preventDefault()
        setLoginMsg('')

        setLoading(true)
        let credentials = {
            username:email,
            password:password
        }

        axiosSend({
            url:'/login',
            method:'POST',
            data:credentials
        })
            .then(
                res => {
                    if (res.data.Status === 'SUCC'){
                        window.location.replace('/dashboard')
                    }else if(res.data.Status === 'FAIL'){
                        setLoading(false)
                        if(res.data.Message){
                            if (res.data.Message === 'Invalid Password'){
                                setLoginMsg(t('common:login.non_match_pw_username'))
                            }else{
                                setLoginMsg(res.data.Message)
                            }
                        }
                    }
                }
            )
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    const emailFieldLabelOverrides = {
      emailFieldLabel: t('common:login.email'),
      requiredlabelOverride: t('common:general.this_field_required'),
      validEmailLabelOveride: t('common:general.enter_valid_email'),
      
    }

    return (
        <PreLoginContent>
            <div className="flex justify-center items-center" style={{minHeight: "100vh", paddingTop: "91px", paddingBottom: "15px"}}>
                <div className="w-[510px] mb-5 mx-5 bg-white py-5 md:px-10 px-5">
                    <form onSubmit={submitLogin}>
                        <h1 className="text-center font-bold text-2xl mt-3 mb-10 text-neutral-900">{t('common:login.login')}</h1>
                        <div className="my-5">
                            <EmailField
                              label={emailFieldLabelOverrides.emailFieldLabel}
                              onChange={(e)=> {setEmail(e.target.value)}}
                              value={email}
                              required={true}
                              labelOverrides={{requiredField: emailFieldLabelOverrides?.requiredlabelOverride, enterValidEmail:emailFieldLabelOverrides?.validEmailLabelOveride}}
                            />
                        </div>
                        <div className="my-5">
                            <PasswordField onChange={(e)=>{setPassword(e.target.value)}} value={password} errMsg={t('common:login.enter_your_password')} disabled={false} label={t('common:login.password')}/>
                        </div>
                        <div className="my-10">
                            <Button size="md" variant="primary" onClick={submitLogin} disabled={loading}>{!loading ? t('common:login.login') : <LoadingSpinner variant={"primary"}/>}</Button>
                            {loginMsg && <p className="text-error-500 flex items-center gap-[4px] mt-[16px] font-[400]"><i className="icon-exclamation-triangle text-error-500"/> {loginMsg}</p>}
                            {logoutReason && <p className="text-error-500 font-[400] mt-[16px] text-center">{logoutReason}</p>}
                            <div className="text-center mt-3">
                                <Link to="/forgot-password" className="text-primary-500">{t('common:login.forgot_pw')}</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </PreLoginContent>
    )
}

export default Login
