import {axiosSend} from "../../utils/request";
import {useContext, useEffect, useState} from "react";
import UserContext from "../../utils/userContext";
import {BankAccounts, Button, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface MgeBankAcctProps{
    provId:string,
    getAcctDets:(a:{curr:string,provId:string})=>void,
    switchView:(a:string)=>void
}

const ProviderBankAccounts = (props:MgeBankAcctProps) => {
    const userDets:any = useContext(UserContext)
    const {provId,getAcctDets,switchView} = props
    const [accts,setAccts]:any = useState([])
    const [loading,setLoading] = useState(true)
    const [bankLdn,setBankLdn] = useState(false)
    const {t} = useTranslation('common')

    function ldBankAccts(){
        axiosSend({
            url:`/fx/admin/part-acc-v2/${provId}`,
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                const tmp:any = []
                res.data.data.forEach((acct:any)=>{
                    tmp.push(
                        {
                            acctNumber:acct.prt_bank_account_number,
                            bankName:acct.prt_bank_name,
                            acctName:acct.prt_bank_account_name,
                            currency:acct.prt_curr_code,
                            swiftCode:acct.prt_bank_swift_code,
                            isActive:acct.prt_bank_status === 'Y',
                            isMainAccount:acct.prt_main_account === 'Y',
                            bankLogo:acct.bank_logos[44],
                            editFxn:()=>{
                                setBankLdn(true)
                                getAcctDets({curr:acct.prt_curr_code,provId:acct.prt_code})
                            },
                            deactivateFxn:()=>{deactAcct(acct.prt_curr_code,acct.prt_code)},
                            reactivateFxn:()=>{reactAcct(acct.prt_curr_code,acct.prt_code)}
                        }
                    )
                })
                setAccts(tmp)
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        ldBankAccts()
    },[provId])

    function deactAcct(acctCurr:string,brokerId:string){
        setBankLdn(true)
        let formData = {
            prtCode:brokerId,
            prtCurrCode:acctCurr,
            action:'savPrtAcc'
        }
        axiosSend({
            url:`/fx/admin/part-acc-v2/deact/${brokerId}`,
            method:'PATCH',
            data:formData
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                ldBankAccts()
            }
            setBankLdn(false)
        })
    }

    function reactAcct(acctCurr:string,brokerId:string){
        setBankLdn(true)
        let formData = {
            prtCode:brokerId,
            prtCurrCode: acctCurr,
            action: 'savPrtAcc'
        }
        axiosSend({
            url:`/fx/admin/part-acc-v2/act/${brokerId}`,
            method:'PATCH',
            data:formData
        }).then(res => {
            if (res.data.Status === 'SUCC') {
                ldBankAccts()
            }
            setBankLdn(false)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="flex flex-col flex-1 gap-[40px]">
            {!loading &&
                <>
                    <div className="flex-col flex-1">
                        <BankAccounts acctDets={accts} loading={bankLdn} labelOverrides={{
                            acctName:t('common:general.account_name'),
                            currency:t('common:general.currency'),
                            swiftCode:t('common:liquidity_providers.swift_code'),
                            edit:t('common:general.edit'),
                            reactivate:t('common:general.reactivate'),
                            deactivate:t('common:general.deactivate')
                        }}/>
                    </div>
                    {Object.keys(userDets).length && userDets.userActions.includes('savPrtAcc') &&
                        <Button variant="primary" size="lg" type="button" onClick={()=>{switchView('add-bank')}}>{t('common:liquidity_providers.add_bank_acct')}</Button>
                    }
                </>
            }
            {loading &&
                <div className="flex flex-col flex-1 justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
        </div>
    )
}

export default ProviderBankAccounts