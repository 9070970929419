import { Chart, registerables } from 'chart.js';
import {Line } from 'react-chartjs-2'
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {axiosSend} from "../utils/request";
import {dayDiff} from "../helpers";
import {CountrySelector} from "@secondstaxorg/sscomp";
import typography from '@secondstaxorg/sscomp/dist/typography.module.css'
import {useTranslation} from "react-i18next";
import ('dayjs/locale/fr')
Chart.register(...registerables);

const LandingTrendingCurrency = () => {
    const [usdData,setUsdData]:any = useState({})
    const [eurData,setEurData]:any = useState({})
    const [gbpData,setGbpData]:any = useState({})
    const [ghsDateRange,setGhsDateRange] = useState('')
    const [ngnDateRange,setNgnDateRange] = useState('')
    const [kesDatesRange,setKesDatesRange] = useState('')
    const [zarDatesRange,setZarDatesRange] = useState('')
    const [usdChange,setUsdChange] = useState(0)
    const [eurChange,setEurChange] = useState(0)
    const [gbpChange,setGbpChange] = useState(0)
    const [ratesData,setRatesData]:any = useState([])
    const [dateRange,setDateRange] = useState('')
    const {t,i18n} = useTranslation('common')
    const [selectedCurrency,setSelectedCurrency] = useState({
        optionShortName: "GHS",
        optionName: t('common:currencies.ghanaian_cedi'),
        optionFLag: "/assets/images/flags/ghana.svg"
    })
    const currencies = [
        {
            optionShortName: "GHS",
            optionName: t('common:currencies.ghanaian_cedi'),
            optionFlag: "/assets/images/flags/ghana.svg"
        },
        {
            optionShortName: "NGN",
            optionName: t('common:currencies.nigerian_naira'),
            optionFlag: "/assets/images/flags/nigeria.svg"
        },
        {
            optionShortName: "KES",
            optionName: t('common:currencies.kenyan_shilling'),
            optionFlag: "/assets/images/flags/kenya.svg"
        },
        {
            optionShortName: "ZAR",
            optionName: t('common:currencies.sa_rand'),
            optionFlag: "/assets/images/flags/sa.svg"
        }
    ]

    //generate graph colour based on performance of the currency
    const performanceColor = (rateChange:number) => {
        if(rateChange < 0){ // reduction in currency performance
            return {
                borderColor:'#F80000',
                fillColor:'#FFF2F2'
            }
        }else if(rateChange > 0){ // rise in currency performance
            return {
                borderColor:'#06BA63',
                fillColor:'#F0FFF7'
            }
        }else{ //no change in performance
            return {
                borderColor:'#365B91',
                fillColor:'#F7F9FC'
            }
        }
    }

    useEffect(()=>{
        const c:any = currencies.find((curr:any) => curr.optionShortName === selectedCurrency.optionShortName)
        setSelectedCurrency(c)
    },[i18n.language])

    useEffect(()=>{
            if (ratesData.data && ratesData.data.length > 0){
                if (selectedCurrency.optionShortName.toUpperCase() === 'GHS') setDateRange(ghsDateRange)
                if (selectedCurrency.optionShortName.toUpperCase() === 'NGN') setDateRange(ngnDateRange)
                if (selectedCurrency.optionShortName.toUpperCase() === 'KES') setDateRange(kesDatesRange)
                if (selectedCurrency.optionShortName.toUpperCase() === 'ZAR') setDateRange(zarDatesRange)
            }
    },[selectedCurrency, ratesData, ghsDateRange, ngnDateRange, kesDatesRange, zarDatesRange])

    useEffect(()=>{
                axiosSend({
            url:`/fx/lov/hist-currency-rates`,
            method:'GET',
        }).then(res => {
            setRatesData(res.data)
        })
    },[])

    useEffect(()=>{
        let usd:any = {
            dates:[],
            data:[],
            change:0,
            lastUpdated:''
        }
        let eur:any = {
            dates:[],
            data:[],
            change:0,
            lastUpdated:''
        }
        let gbp:any = {
            dates:[],
            data:[],
            change:0,
            lastUpdated:''
        }

        if (ratesData.data && ratesData.data.length > 0){
            //usd
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].exhg_currency === 'USD' && ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    if (usd.dates.length < 7 && usd.data.length < 7){
                        usd.dates.push(dayjs(ratesData.data[i].curr_date).locale(i18n.language).format('ddd, D MMM'))
                        usd.data.push(ratesData.data[i].rate)
                        usd.change = ratesData.data[i].curr_change
                        setUsdChange(ratesData.data[i].curr_change)
                    }
                    usd.lastUpdated = ratesData.data[ratesData.data.length - 1].curr_date
                }
            }

            let usaData = {
                labels:usd.dates,
                datasets:[{
                    label:'', //usd
                    data:usd.data,
                    borderColor:performanceColor(usd.change).borderColor,
                    backgroundColor:performanceColor(usd.change).fillColor,
                    fill:true,
                    tension:0.2,
                    borderWidth:1
                }],
                lastUpdated:usd.lastUpdated
            }

            //eur
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].exhg_currency === 'EUR' && ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    if (eur.dates.length < 7 && eur.data.length < 7){
                        eur.dates.push(dayjs(ratesData.data[i].curr_date).locale(i18n.language).format('ddd, D MMM'))
                        eur.data.push(ratesData.data[i].rate)
                        eur.change = ratesData.data[i].curr_change
                        setEurChange(ratesData.data[i].curr_change)
                    }
                    eur.lastUpdated = ratesData.data[ratesData.data.length - 1].curr_date
                }
            }

            let euroData = {
                labels:eur.dates,
                datasets:[{
                    label:'', //kenyan shilling
                    data:eur.data,
                    borderColor:performanceColor(eur.change).borderColor,
                    backgroundColor:performanceColor(eur.change).fillColor,
                    fill:true,
                    tension:0.2,
                    borderWidth:1
                }],
                lastUpdated:eur.lastUpdated
            }

            //gbp
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].exhg_currency === 'GBP' && ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    if (gbp.dates.length < 7 && gbp.data.length < 7){
                        gbp.dates.push(dayjs(ratesData.data[i].curr_date).locale(i18n.language).format('ddd, D MMM'))
                        gbp.data.push(ratesData.data[i].rate)
                        gbp.change = ratesData.data[i].curr_change
                        setGbpChange(ratesData.data[i].curr_change)
                    }
                    gbp.lastUpdated = ratesData.data[ratesData.data.length - 1].curr_date
                }
            }

            let britData = {
                labels:gbp.dates,
                datasets:[{
                    label:'', //nigerian naira
                    data:gbp.data,
                    borderColor:performanceColor(gbp.change).borderColor,
                    backgroundColor:performanceColor(gbp.change).fillColor,
                    fill:true,
                    tension:0.2,
                    borderWidth:1
                }],
                lastUpdated:gbp.lastUpdated
            }

            setUsdData(usaData)
            setEurData(euroData)
            setGbpData(britData)

            //SET DATES
            //ghs
            let ghsTempDates:string[] = []
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    ghsTempDates.push(ratesData.data[i].curr_date)
                }
            }
            setGhsDateRange(dayjs(ghsTempDates[0]).locale(i18n.language).format('D MMM YYYY')+' - '+dayjs(ghsTempDates[ghsTempDates.length - 1]).locale(i18n.language).format('D MMM YYYY'))

            //ngn
            let ngnTempDates:string[] = []
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    ngnTempDates.push(ratesData.data[i].curr_date)
                }
            }
            setNgnDateRange(dayjs(ngnTempDates[0]).locale(i18n.language).format('D MMM YYYY')+' - '+dayjs(ngnTempDates[ngnTempDates.length - 1]).locale(i18n.language).format('D MMM YYYY'))

            //kes
            let kesTempDates:string[] = []
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    kesTempDates.push(ratesData.data[i].curr_date)
                }
            }
            setKesDatesRange(dayjs(kesTempDates[0]).locale(i18n.language).format('D MMM YYYY')+' - '+dayjs(kesTempDates[kesTempDates.length - 1]).locale(i18n.language).format('D MMM YYYY'))

            //zar
            let zarTempDates:string[] = []
            for(let i = 0; i < ratesData.data.length; i++){
                if(ratesData.data[i].base_currency === selectedCurrency.optionShortName){
                    zarTempDates.push(ratesData.data[i].curr_date)
                }
            }
            setZarDatesRange(dayjs(zarTempDates[0]).locale(i18n.language).format('D MMM YYYY')+' - '+dayjs(zarTempDates[zarTempDates.length - 1]).locale(i18n.language).format('D MMM YYYY'))
        }

    },[ratesData.data, selectedCurrency.optionShortName,i18n.language])

        let options:any = {
            responsive: true,
            maintainAspectRatio:false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip:{
                    enabled:true,
                    intersect:false,
                    backgroundColor:'#F7F9FC',
                    bodyColor:'#070B12',
                    titleColor:'#070B12',
                    displayColors:false,
                    titleAlign:'center',
                    bodyAlign:'center',
                    xAlign:'center',
                    caretSize:0,
                    padding:10,
                    callbacks: {
                        label: (context: any) => {
                            return Number(context.raw).toFixed(4)
                        }
                    }
                }
            },
            scales:{
                y:{
                    display:false,
                    // min:0
                },
                x:{
                    display:false,
                }
            },
            elements:{
                point:{
                    radius:0
                }
            }
        };

    return (
        <>
            <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-[16px]">
                <div>
                    <h6 className={typography.heading6Bold}>{t('common:dashboard.exchange_rates')}</h6>
                    <p className="text-neutral-500">{t('common:dashboard.exchange_rates_info')} {selectedCurrency.optionName} {dateRange && <>({dateRange})</>}</p>
                </div>
                <div className="w-[300px]">
                    <CountrySelector options={currencies} selectedOption={(val)=>{setSelectedCurrency(val)}} initialCountry={selectedCurrency.optionName} inputName="currency"/>
                </div>
            </div>
            <div className="trendlist-container">
                <div className="flex gap-[32px] w-[900px] md:w-full mx-auto pb-3">
                    <div className="bg-white w-4/12 h-[174px] border border-neutral-100 rounded-[4px] relative overflow-hidden">
                        <div className="flex justify-between p-[16px] h-[66px]">
                            <div className="flex items-center gap-2">
                                <div>
                                    {/*usa flag*/}
                                    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_122_19148)">
                                            <rect width="21" height="15" rx="3" fill="white"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H9V7H0V0Z" fill="#1A47B8"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9 0V1H21V0H9ZM9 2V3H21V2H9ZM9 4V5H21V4H9ZM9 6V7H21V6H9ZM0 8V9H21V8H0ZM0 10V11H21V10H0ZM0 12V13H21V12H0ZM0 14V15H21V14H0Z" fill="#F93939"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1 1V2H2V1H1ZM3 1V2H4V1H3ZM5 1V2H6V1H5ZM7 1V2H8V1H7ZM6 2V3H7V2H6ZM4 2V3H5V2H4ZM2 2V3H3V2H2ZM1 3V4H2V3H1ZM3 3V4H4V3H3ZM5 3V4H6V3H5ZM7 3V4H8V3H7ZM1 5V6H2V5H1ZM3 5V6H4V5H3ZM5 5V6H6V5H5ZM7 5V6H8V5H7ZM6 4V5H7V4H6ZM4 4V5H5V4H4ZM2 4V5H3V4H2Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_122_19148">
                                                <rect width="21" height="15" rx="3" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="text-black leading-5">
                                    <div className={typography.paragraph2Medium}>{t('common:currencies.us_dollar')}</div>
                                    <div className={typography.paragraph2Regular}>USD</div>
                                </div>
                            </div>
                            <div className="text-right text-[12px]">
                                {usdData.datasets && usdData.datasets.length > 0 &&
                                    <>
                                        <div className="flex items-center gap-[8px]">
                                            <div>
                                                {Number(usdChange) < 0 && <i className="icon-arrow-down-right text-error-500"/>}
                                                {Number(usdChange) > 0 && <i className="icon-arrow-up-right text-success-500"/>}
                                                {Number(usdChange) === 0 && <i className="icon-dash text-neutral-500"/>}
                                            </div>
                                            <div className="text-black-100">USD 1.0000 = {`${selectedCurrency.optionShortName} ${usdData.datasets[0].data[usdData.datasets[0].data.length - 1]}`}</div>
                                        </div>
                                        <div className="text-neutral-400">
                                            {`${dayDiff(usdData.lastUpdated,t)}${dayjs(usdData.lastUpdated).locale(i18n.language).format('MMM D')}`}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="rate-graph">
                            {usdData.labels && usdData.labels.length > 0 &&
                                <>
                                    <Line data={usdData} options={options}/>
                                    {/*give the graph some height from the bottom*/}
                                    <div className="h-[25px]" style={{background:usdData.datasets[0].backgroundColor}}/>
                                </>
                            }
                        </div>
                    </div>
                    <div className="bg-white w-4/12 h-[174px] border border-neutral-100 rounded-[4px] relative overflow-hidden">
                        <div className="flex justify-between p-[16px] h-[66px]">
                            <div className="flex items-center gap-2">
                                <div>
                                    {/*euro flag*/}
                                    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="21" height="15" rx="3" fill="#1A47B8"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8 5H9V6H8V5ZM10 4H11V5H10V4ZM12 5H13V6H12V5ZM13 7H14V8H13V7ZM7 7H8V8H7V7ZM12 9H13V10H12V9ZM10 10H11V11H10V10ZM8 9H9V10H8V9Z" fill="#FFDA2C"/>
                                    </svg>
                                </div>
                                <div className="text-black leading-5">
                                    <div className={typography.paragraph2Medium}>{t('common:currencies.euro')}</div>
                                    <div className={typography.paragraph2Regular}>EUR</div>
                                </div>
                            </div>
                            <div className="text-right text-[12px]">
                                {eurData.datasets && eurData.datasets.length > 0 &&
                                    <>
                                        <div className="flex items-center gap-[8px]">
                                            <div>
                                                {Number(eurChange) < 0 && <i className="icon-arrow-down-right text-error-500"/>}
                                                {Number(eurChange) > 0 && <i className="icon-arrow-up-right text-success-500"/>}
                                                {Number(eurChange) === 0 && <i className="icon-dash text-neutral-500"/>}
                                            </div>
                                            <div className="text-black-100">EUR 1.0000 = {`${selectedCurrency.optionShortName} ${eurData.datasets[0].data[eurData.datasets[0].data.length - 1]}`}</div>
                                        </div>
                                        <div className="text-neutral-400">
                                            {`${dayDiff(eurData.lastUpdated,t)}${dayjs(eurData.lastUpdated).locale(i18n.language).format('MMM D')}`}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="rate-graph">
                            {eurData.labels && eurData.labels.length > 0 &&
                                <>
                                    <Line data={eurData} options={options}/>
                                    {/*give the graph some height from the bottom*/}
                                    <div className="h-[25px]" style={{background:eurData.datasets[0].backgroundColor}}/>
                                </>
                            }
                        </div>
                    </div>
                    <div className="bg-white w-4/12 h-[174px] border border-neutral-100 rounded-[4px] relative overflow-hidden">
                        <div className="flex justify-between p-[16px] h-[66px]">
                            <div className="flex items-center gap-2">
                                <div>
                                    {/*uk flag*/}
                                    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_122_19257)">
                                            <rect width="21" height="15" rx="3" fill="#1A47B8"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.23397 0H0V2.5L18.7539 15L21 15V12.5L2.23397 0Z" fill="white"/>
                                            <path d="M0.745098 0L21 13.5354V15H20.2724L0 1.45056V0H0.745098Z" fill="#F93939"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.0001 0H21.0001V2.5C21.0001 2.5 8.00986 10.8281 2.00006 15H6.10352e-05V12.5L19.0001 0Z" fill="white"/>
                                            <path d="M21 0H20.3218L0 13.5471V15H0.745098L21 1.46151V0Z" fill="#F93939"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.63708 0H13.3804V4.62682H21V10.3701H13.3804V15H7.63708V10.3701H0V4.62682H7.63708V0Z" fill="white"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.8421 0H12.1579V5.76923H21V9.23077H12.1579V15H8.8421V9.23077H0V5.76923H8.8421V0Z" fill="#F93939"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_122_19257">
                                                <rect width="21" height="15" rx="3" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <div className="text-black leading-5">
                                    <div className={typography.paragraph2Medium}>{t('common:currencies.pound_sterling')}</div>
                                    <div className={typography.paragraph2Regular}>GBP</div>
                                </div>
                            </div>
                            <div className="text-right text-[12px]">
                                {gbpData.datasets && gbpData.datasets.length > 0 &&
                                    <>
                                        <div className="flex items-center gap-[8px]">
                                            <div>
                                                {Number(gbpChange) < 0 && <i className="icon-arrow-down-right text-error-500"/>}
                                                {Number(gbpChange) > 0 && <i className="icon-arrow-up-right text-success-500"/>}
                                                {Number(gbpChange) === 0 && <i className="icon-dash text-neutral-500"/>}
                                            </div>
                                            <div className="text-black-100">GBP 1.0000 = {`${selectedCurrency.optionShortName} ${gbpData.datasets[0].data[gbpData.datasets[0].data.length - 1]}`} {/* always display the last item in the array*/}</div>
                                        </div>
                                        <div className="text-neutral-400">
                                            {`${dayDiff(gbpData.lastUpdated,t)}${dayjs(gbpData.lastUpdated).locale(i18n.language).format('MMM D')}`}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="rate-graph">
                            {gbpData.labels && gbpData.labels.length > 0 &&
                                <>
                                    <Line data={gbpData} options={options}/>
                                    {/*give the graph some height from the bottom*/}
                                    <div className="h-[25px]" style={{background:gbpData.datasets[0].backgroundColor}}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingTrendingCurrency
