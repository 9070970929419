import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useState} from "react";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import {exportExcel, exportPDF} from "../../helpers";
import dayjs from "dayjs";
import UnauthorisedAccess from "../../components/UnauthorisedAccess";
import UserContext from "../../utils/userContext";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, LoadingSpinner,TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const NumLpTransactions = () => {
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [loading,setLoading] = useState(false)
    const [errMsg,setErrMsg] = useState('')
    const [repList,setRepList]:any[] = useState([])
    const [emptyData,setEmptyData] = useState(false)
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const [reportPeriod,setReportPeriod] = useState('')
    const userDets:any = useContext(UserContext)
    const {t} = useTranslation('common')

    function genReport(){
        if (!startDate || !endDate){
            setErrMsg(t('common:reports.start_end_date_required'))
            return
        }else{
            setErrMsg('')
        }
        setLoading(true)
        axiosSend({
            url:`/fx/reports/lp-nbr-trx?startDate=${startDate}&endDate=${endDate}`,
            method:'GET'
        }).then(res => {
            if(res.data.data){
                let tempRep: {}[] = []
                res.data.data.forEach((rep:any)=>{
                    tempRep.push({
                        name:rep.lip_name,
                        number:rep.nbr_transactions
                    })
                })
                setRepList(tempRep)
                setReportPeriod(dayjs(startDate).format('MMM D YYYY')+' - '+dayjs(endDate).format('MMM D YYYY'))
                setEmptyData(false)
            }else{
                setEmptyData(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        repList.forEach((rep:any) => {
            formattedData.push({
                'Provider Name':rep.name,
                'Number of Transactions':Number(rep.number)
            })
        })
        exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,t('common:reports.num_lp_trx'))
    }

    if (Object.keys(userDets).length > 0 && userDets.profile[0].user_type !== 'SX'){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal &&
                <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                    primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,t('common:reports.num_lp_trx'))
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
                 <div id="reportPreview">
                     <TableAlternative thead={[t('common:reports.provider_name'),t('common:reports.num_trx')]}>
                         {repList.length > 0 && //when report is generated and data is returned
                             <>
                                 {repList.map((rep:any,index:number) => (
                                     <tr key={index}>
                                         <td>{rep.name}</td>
                                         <td>{rep.number}</td>
                                     </tr>
                                 ))}
                             </>
                         }
                     </TableAlternative>
                 </div>
                </ExportPreviewModal>}
            <div className="mb-[16px]">
                <div className="mb-[16px]">
                    <h6 className={typography.heading6Bold}>{t('common:reports.num_lp_trx')}</h6>
                </div>
                <div className="flex gap-[16px] justify-between">
                    <form onSubmit={(e)=>{
                        e.preventDefault()
                        genReport()
                    }} className="flex gap-[8px] w-8/12">
                        <div className="w-5/12">
                            <DateRangePicker selectedDateRange={(a)=>{
                                setStartDate(dayjs(a.startDate).format('YYYY-MM-DD'))
                                if (a.endDate) setEndDate(dayjs(a.endDate).format('YYYY-MM-DD'))
                            }} placeholder={t('common:reports.pick_date_range')}/>
                        </div>
                        <div className="w-3/12">
                            <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>
                        </div>
                    </form>
                    <div className="w-[200px]">
                        <ButtonOutline size="lg" variant="primary" type="button" disabled={repList.length === 0 || loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                    </div>
                </div>
                {errMsg && <p className="mt-[8px] text-error-500">{errMsg}</p>}
            </div>
            <div>
                <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                    <p className="text-white">{t('common:reports.provider_name')}</p>
                    <p className="text-white">{t('common:reports.num_trx')}</p>
                </div>
                {!loading &&
                    <>
                        {repList.length === 0 && !emptyData && //initial state when report is yet to be generated
                            <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                        }
                        {repList.length === 0 && emptyData && //when report is generated but no data is returned
                            <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                        }
                        {repList.length > 0 && //when report is generated and data is returned
                            <>
                                <div className="flex flex-col gap-[8px]">
                                    {repList.map((rep:any,index:number) => (
                                        <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                            <p>{rep.name}</p>
                                            <p>{rep.number}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            {loading &&
                <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant={"primary"}/></div>
            }
        </DashboardBaseLayout>
    )
}

export default NumLpTransactions