import React, {useEffect, useState} from "react";
import {TableContainer, Table, NothingContainer, NothingContent} from "./style";
import {CurrencyPairsProps} from "./type";
import {Pagination} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const CurrencyPairsTable = (props:CurrencyPairsProps) => {
    const {pairs,callback} = props
    const [dispPairs,setDispPairs]:any = useState([])
    const [dispPairsOri,setDispPairsOri] = useState(pairs)
    const [sortPairDir,setSortPairDir] = useState('desc')
    const [sortBaseDir,setSortBaseDir] = useState('desc')
    const [sortExchDir,setSortExchDir] = useState('desc')
    const {t} = useTranslation('common')

    useEffect(()=>{
        setDispPairsOri(pairs.sort((a:any,b:any) => {
            if(a.pairCode < b.pairCode) return -1
            return 1
        }))
    },[pairs])

    function sortPair(){
        if (sortPairDir === 'asc'){
            setSortPairDir('desc')
            dispPairs.sort((a:any,b:any) => {
                if(a.pairCode < b.pairCode) return -1
                return 1
            })
        }
        if (sortPairDir === 'desc'){
            setSortPairDir('asc')
            dispPairs.sort((a:any,b:any) => {
                if(b.pairCode < a.pairCode) return -1
                return 1
            })
        }
    }

    function sortBase(){
        if (sortBaseDir === 'asc'){
            setSortBaseDir('desc')
            dispPairs.sort((a:any,b:any) => {
                if(a.baseCurrency < b.baseCurrency) return -1
                return 1
            })
        }
        if (sortBaseDir === 'desc'){
            setSortBaseDir('asc')
            dispPairs.sort((a:any,b:any) => {
                if(b.baseCurrency < a.baseCurrency) return -1
                return 1
            })
        }
    }

    function sortExch(){
        if (sortExchDir === 'asc'){
            setSortExchDir('desc')
            dispPairs.sort((a:any,b:any) => {
                if(a.exchangeCurrency < b.exchangeCurrency) return -1
                return 1
            })
        }
        if (sortExchDir === 'desc'){
            setSortExchDir('asc')
            dispPairs.sort((a:any,b:any) => {
                if(b.exchangeCurrency < a.exchangeCurrency) return -1
                return 1
            })
        }
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <thead>
                    <tr className="rounded-row">
                        <th>
                            <div onClick={()=>{sortPair()}}>
                                {t('common:currency_pairs.pair_code')}
                                {sortPairDir === 'desc' &&
                                    <>
                                        {/*arrow-up*/}
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.80671 3.52664L5.47338 0.193305C5.40997 0.132611 5.33521 0.0850345 5.25338 0.0533049C5.09107 -0.0133738 4.90902 -0.0133738 4.74671 0.0533049C4.66487 0.0850345 4.59011 0.132611 4.52671 0.193305L1.19338 3.52664C1.13122 3.5888 1.08191 3.66259 1.04827 3.74381C1.01463 3.82502 0.997314 3.91207 0.997314 3.99997C0.997314 4.17751 1.06784 4.34777 1.19338 4.47331C1.31891 4.59884 1.48917 4.66937 1.66671 4.66937C1.84424 4.66937 2.01451 4.59884 2.14004 4.47331L4.33338 2.2733V7.3333C4.33338 7.51012 4.40361 7.67969 4.52864 7.80471C4.65366 7.92973 4.82323 7.99997 5.00004 7.99997C5.17685 7.99997 5.34642 7.92973 5.47145 7.80471C5.59647 7.67969 5.66671 7.51012 5.66671 7.3333V2.2733L7.86004 4.47331C7.92202 4.53579 7.99575 4.58539 8.07699 4.61923C8.15823 4.65308 8.24537 4.6705 8.33337 4.6705C8.42138 4.6705 8.50852 4.65308 8.58976 4.61923C8.671 4.58539 8.74473 4.53579 8.80671 4.47331C8.86919 4.41133 8.91879 4.3376 8.95264 4.25636C8.98648 4.17512 9.00391 4.08798 9.00391 3.99997C9.00391 3.91196 8.98648 3.82483 8.95264 3.74359C8.91879 3.66235 8.86919 3.58861 8.80671 3.52664Z" fill="#ffffff"/>
                                        </svg>
                                    </>
                                }
                                {sortPairDir === 'asc' &&
                                    <>
                                        {/*arrow-down*/}
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.80671 3.52667C8.74473 3.46418 8.671 3.41459 8.58976 3.38074C8.50852 3.34689 8.42138 3.32947 8.33337 3.32947C8.24537 3.32947 8.15823 3.34689 8.07699 3.38074C7.99575 3.41459 7.92202 3.46418 7.86004 3.52667L5.66671 5.72667V0.666667C5.66671 0.489856 5.59647 0.320287 5.47145 0.195262C5.34642 0.070238 5.17685 0 5.00004 0C4.82323 0 4.65366 0.070238 4.52864 0.195262C4.40361 0.320287 4.33338 0.489856 4.33338 0.666667V5.72667L2.14004 3.52667C2.01451 3.40113 1.84424 3.33061 1.66671 3.33061C1.48917 3.33061 1.31891 3.40113 1.19338 3.52667C1.06784 3.6522 0.997314 3.82247 0.997314 4C0.997314 4.17753 1.06784 4.3478 1.19338 4.47333L4.52671 7.80667C4.59011 7.86736 4.66487 7.91494 4.74671 7.94667C4.82651 7.98194 4.91279 8.00016 5.00004 8.00016C5.08729 8.00016 5.17357 7.98194 5.25338 7.94667C5.33521 7.91494 5.40997 7.86736 5.47338 7.80667L8.80671 4.47333C8.86919 4.41136 8.91879 4.33762 8.95264 4.25638C8.98648 4.17515 9.00391 4.08801 9.00391 4C9.00391 3.91199 8.98648 3.82486 8.95264 3.74362C8.91879 3.66238 8.86919 3.58864 8.80671 3.52667Z" fill="#ffffff"/>
                                        </svg>
                                    </>
                                }
                            </div>
                        </th>
                        <th>
                            <div onClick={()=>{sortBase()}}>
                                {t('common:request_lp.base_currency')}
                                {sortBaseDir === 'desc' &&
                                    <>
                                        {/*arrow-up*/}
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.80671 3.52664L5.47338 0.193305C5.40997 0.132611 5.33521 0.0850345 5.25338 0.0533049C5.09107 -0.0133738 4.90902 -0.0133738 4.74671 0.0533049C4.66487 0.0850345 4.59011 0.132611 4.52671 0.193305L1.19338 3.52664C1.13122 3.5888 1.08191 3.66259 1.04827 3.74381C1.01463 3.82502 0.997314 3.91207 0.997314 3.99997C0.997314 4.17751 1.06784 4.34777 1.19338 4.47331C1.31891 4.59884 1.48917 4.66937 1.66671 4.66937C1.84424 4.66937 2.01451 4.59884 2.14004 4.47331L4.33338 2.2733V7.3333C4.33338 7.51012 4.40361 7.67969 4.52864 7.80471C4.65366 7.92973 4.82323 7.99997 5.00004 7.99997C5.17685 7.99997 5.34642 7.92973 5.47145 7.80471C5.59647 7.67969 5.66671 7.51012 5.66671 7.3333V2.2733L7.86004 4.47331C7.92202 4.53579 7.99575 4.58539 8.07699 4.61923C8.15823 4.65308 8.24537 4.6705 8.33337 4.6705C8.42138 4.6705 8.50852 4.65308 8.58976 4.61923C8.671 4.58539 8.74473 4.53579 8.80671 4.47331C8.86919 4.41133 8.91879 4.3376 8.95264 4.25636C8.98648 4.17512 9.00391 4.08798 9.00391 3.99997C9.00391 3.91196 8.98648 3.82483 8.95264 3.74359C8.91879 3.66235 8.86919 3.58861 8.80671 3.52664Z" fill="#ffffff"/>
                                        </svg>
                                    </>
                                }
                                {sortBaseDir === 'asc' &&
                                    <>
                                        {/*arrow-down*/}
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.80671 3.52667C8.74473 3.46418 8.671 3.41459 8.58976 3.38074C8.50852 3.34689 8.42138 3.32947 8.33337 3.32947C8.24537 3.32947 8.15823 3.34689 8.07699 3.38074C7.99575 3.41459 7.92202 3.46418 7.86004 3.52667L5.66671 5.72667V0.666667C5.66671 0.489856 5.59647 0.320287 5.47145 0.195262C5.34642 0.070238 5.17685 0 5.00004 0C4.82323 0 4.65366 0.070238 4.52864 0.195262C4.40361 0.320287 4.33338 0.489856 4.33338 0.666667V5.72667L2.14004 3.52667C2.01451 3.40113 1.84424 3.33061 1.66671 3.33061C1.48917 3.33061 1.31891 3.40113 1.19338 3.52667C1.06784 3.6522 0.997314 3.82247 0.997314 4C0.997314 4.17753 1.06784 4.3478 1.19338 4.47333L4.52671 7.80667C4.59011 7.86736 4.66487 7.91494 4.74671 7.94667C4.82651 7.98194 4.91279 8.00016 5.00004 8.00016C5.08729 8.00016 5.17357 7.98194 5.25338 7.94667C5.33521 7.91494 5.40997 7.86736 5.47338 7.80667L8.80671 4.47333C8.86919 4.41136 8.91879 4.33762 8.95264 4.25638C8.98648 4.17515 9.00391 4.08801 9.00391 4C9.00391 3.91199 8.98648 3.82486 8.95264 3.74362C8.91879 3.66238 8.86919 3.58864 8.80671 3.52667Z" fill="#ffffff"/>
                                        </svg>
                                    </>
                                }
                            </div>
                        </th>
                        <th><div onClick={()=>{sortExch()}}>
                            {t('common:request_lp.exchange_currency')}
                            {sortExchDir === 'desc' &&
                                <>
                                    {/*arrow-up*/}
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.80671 3.52664L5.47338 0.193305C5.40997 0.132611 5.33521 0.0850345 5.25338 0.0533049C5.09107 -0.0133738 4.90902 -0.0133738 4.74671 0.0533049C4.66487 0.0850345 4.59011 0.132611 4.52671 0.193305L1.19338 3.52664C1.13122 3.5888 1.08191 3.66259 1.04827 3.74381C1.01463 3.82502 0.997314 3.91207 0.997314 3.99997C0.997314 4.17751 1.06784 4.34777 1.19338 4.47331C1.31891 4.59884 1.48917 4.66937 1.66671 4.66937C1.84424 4.66937 2.01451 4.59884 2.14004 4.47331L4.33338 2.2733V7.3333C4.33338 7.51012 4.40361 7.67969 4.52864 7.80471C4.65366 7.92973 4.82323 7.99997 5.00004 7.99997C5.17685 7.99997 5.34642 7.92973 5.47145 7.80471C5.59647 7.67969 5.66671 7.51012 5.66671 7.3333V2.2733L7.86004 4.47331C7.92202 4.53579 7.99575 4.58539 8.07699 4.61923C8.15823 4.65308 8.24537 4.6705 8.33337 4.6705C8.42138 4.6705 8.50852 4.65308 8.58976 4.61923C8.671 4.58539 8.74473 4.53579 8.80671 4.47331C8.86919 4.41133 8.91879 4.3376 8.95264 4.25636C8.98648 4.17512 9.00391 4.08798 9.00391 3.99997C9.00391 3.91196 8.98648 3.82483 8.95264 3.74359C8.91879 3.66235 8.86919 3.58861 8.80671 3.52664Z" fill="#ffffff"/>
                                    </svg>
                                </>
                            }
                            {sortExchDir === 'asc' &&
                                <>
                                    {/*arrow-down*/}
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.80671 3.52667C8.74473 3.46418 8.671 3.41459 8.58976 3.38074C8.50852 3.34689 8.42138 3.32947 8.33337 3.32947C8.24537 3.32947 8.15823 3.34689 8.07699 3.38074C7.99575 3.41459 7.92202 3.46418 7.86004 3.52667L5.66671 5.72667V0.666667C5.66671 0.489856 5.59647 0.320287 5.47145 0.195262C5.34642 0.070238 5.17685 0 5.00004 0C4.82323 0 4.65366 0.070238 4.52864 0.195262C4.40361 0.320287 4.33338 0.489856 4.33338 0.666667V5.72667L2.14004 3.52667C2.01451 3.40113 1.84424 3.33061 1.66671 3.33061C1.48917 3.33061 1.31891 3.40113 1.19338 3.52667C1.06784 3.6522 0.997314 3.82247 0.997314 4C0.997314 4.17753 1.06784 4.3478 1.19338 4.47333L4.52671 7.80667C4.59011 7.86736 4.66487 7.91494 4.74671 7.94667C4.82651 7.98194 4.91279 8.00016 5.00004 8.00016C5.08729 8.00016 5.17357 7.98194 5.25338 7.94667C5.33521 7.91494 5.40997 7.86736 5.47338 7.80667L8.80671 4.47333C8.86919 4.41136 8.91879 4.33762 8.95264 4.25638C8.98648 4.17515 9.00391 4.08801 9.00391 4C9.00391 3.91199 8.98648 3.82486 8.95264 3.74362C8.91879 3.66238 8.86919 3.58864 8.80671 3.52667Z" fill="#ffffff"/>
                                    </svg>
                                </>
                            }
                        </div></th>
                        <th>{t('common:currency_pairs.pair_name')}</th>
                    </tr>
                    </thead>
                    {dispPairs && dispPairs.length > 0 &&
                        <tbody>
                        {dispPairs.map(( pair: { pairCode:string,baseCurrency:string,baseFlag:string,exchangeCurrency:string,exchangeFlag:string,pairName:string },index:number) => (
                            <tr key={index} className="rounded-row" onClick={()=>{callback(pair)}}>
                                <td>{pair.pairCode}</td>
                                <td>
                                    <div style={{display:'flex',gap:'8px'}}>
                                        {pair.baseFlag ? <img src={pair.baseFlag} alt="Base currency flag"/> : ''}
                                        {pair.baseCurrency}
                                    </div>
                                </td>
                                <td>
                                    <div style={{display:'flex',gap:'8px'}}>
                                        {pair.exchangeFlag ? <img src={pair.exchangeFlag} alt="Exchange currency flag"/> : ''}
                                        {pair.exchangeCurrency}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {pair.pairName ? pair.pairName : '-'}
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3592 7.52685L6.58584 3.76019C6.52387 3.6977 6.45013 3.64811 6.36889 3.61426C6.28765 3.58041 6.20052 3.56299 6.11251 3.56299C6.0245 3.56299 5.93736 3.58041 5.85612 3.61426C5.77488 3.64811 5.70115 3.6977 5.63917 3.76019C5.51501 3.8851 5.44531 4.05406 5.44531 4.23019C5.44531 4.40631 5.51501 4.57528 5.63917 4.70019L8.93917 8.03352L5.63917 11.3335C5.51501 11.4584 5.44531 11.6274 5.44531 11.8035C5.44531 11.9796 5.51501 12.1486 5.63917 12.2735C5.70092 12.3365 5.77455 12.3866 5.8558 12.421C5.93705 12.4553 6.0243 12.4732 6.11251 12.4735C6.20071 12.4732 6.28797 12.4553 6.36922 12.421C6.45047 12.3866 6.5241 12.3365 6.58584 12.2735L10.3592 8.50685C10.4268 8.44443 10.4808 8.36866 10.5178 8.28432C10.5547 8.19999 10.5738 8.10892 10.5738 8.01685C10.5738 7.92479 10.5547 7.83372 10.5178 7.74938C10.4808 7.66505 10.4268 7.58928 10.3592 7.52685Z" fill="#A4BBDD"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    }
                </Table>
                {dispPairs && dispPairs.length === 0 &&
                    <NothingContainer>
                        <NothingContent>
                            <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_651_2393)">
                                    <path d="M39.5 6H25.5V14H39.5V6Z" fill="#E8EEF7"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.5006 6C41.5006 4.895 40.6046 4 39.5 4H25.5C24.3954 4 23.4994 4.895 23.4994 6V14C23.4994 15.105 24.3954 16 25.5 16H39.5C40.6046 16 41.5006 15.105 41.5006 14V6ZM27.5006 8V12H37.4994V8H27.5006Z" fill="#D2DDEE"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M49.5 56C49.5 54.896 50.396 54 51.5 54C52.604 54 53.5 54.896 53.5 56V58C53.5 59.105 52.605 60 51.5 60H13.5C12.395 60 11.5 59.105 11.5 58V10C11.5 8.895 12.395 8 13.5 8H25.5C26.605 8 27.5 8.895 27.5 10V12H37.5V10C37.5 8.895 38.395 8 39.5 8H51.5C52.605 8 53.5 8.895 53.5 10V48C53.5 49.104 52.604 50 51.5 50C50.396 50 49.5 49.104 49.5 48V12H41.5V14C41.5 15.105 40.605 16 39.5 16H25.5C24.395 16 23.5 15.105 23.5 14V12H15.5V56H49.5Z" fill="#D2DDEE"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 27H41.5C42.604 27 43.5 26.104 43.5 25C43.5 23.896 42.604 23 41.5 23H23.5C22.396 23 21.5 23.896 21.5 25C21.5 26.104 22.396 27 23.5 27Z" fill="#D2DDEE"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 38H41.5C42.604 38 43.5 37.104 43.5 36C43.5 34.896 42.604 34 41.5 34H23.5C22.396 34 21.5 34.896 21.5 36C21.5 37.104 22.396 38 23.5 38Z" fill="#D2DDEE"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 49H41.5C42.604 49 43.5 48.104 43.5 47C43.5 45.896 42.604 45 41.5 45H23.5C22.396 45 21.5 45.896 21.5 47C21.5 48.104 22.396 49 23.5 49Z" fill="#D2DDEE"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_651_2393">
                                        <rect width="64" height="64" fill="white" transform="translate(0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <p>{t('common:currency_pairs.empty_currency_pairs')}</p>
                        </NothingContent>
                    </NothingContainer>
                }
            </TableContainer>
            <Pagination itemsPerPage={10} items={dispPairsOri} paginatedItems={(a)=>{setDispPairs(a)}}/>
        </>
    )
}

export default CurrencyPairsTable
