import DashboardBaseLayout from "../components/DashboardBaseLayout";
import React, {useContext, useEffect, useState} from "react";
import {appName} from "../helpers";
import {Link} from "react-router-dom";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const Report = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.reports')}`
    const userDets:any = useContext(UserContext)
    const [hasAccess, setHasAccess] = useState(false)

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_REP){
                    usrHasAccess = true
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            <div className="mb-[16px]">
                <h3 className={typography.heading5Bold}>{t('common:dashboard_side_menu.reports')}</h3>
                <p className={`${typography.paragraph2Regular} text-neutral-500`}>{t('common:reports.intro_text')}</p>
            </div>
            <div className="flex flex-col gap-[8px]">
                <Link to="/reports/broker-trx-list" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.broker_trx_list')} <i className="icon-angle-right"/></Link>
                {userDets.profile[0].user_type === 'SX' &&
                    <>
                        <Link to="/reports/num-broker-trx" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.num_broker_trx')} <i className="icon-angle-right"/></Link>
                        <Link to="/reports/num-lp-trx" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.num_lp_trx')} <i className="icon-angle-right"/></Link>
                        <Link to="/reports/sum-lp-trx-by-base-curr" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.sum_trx_by_base_curr_by_lp')} <i className="icon-angle-right"/></Link>
                        <Link to="/reports/lp-trx-list" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.lp_trx_list')} <i className="icon-angle-right"/></Link>
                        <Link to="/reports/avg-lp-trx-time" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.avg_lp_trx_time')} <i className="icon-angle-right"/></Link>
                    </>
                }
                <Link to="/reports/sum-broker-trx-by-base-curr" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.sum_trx_by_base_curr_by_broker')} <i className="icon-angle-right"/></Link>
                <Link to="/reports/trx-list-by-client-id" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.client_id_trx_list')} <i className="icon-angle-right"/></Link>
                <Link to="/reports/num-broker-trx-by-status" className="rounded-[8px] p-[16px] bg-white flex items-center justify-between">{t('common:reports.num_broker_trx_by_status')} <i className="icon-angle-right"/></Link>
            </div>
        </DashboardBaseLayout>
    )
}

export default Report
