import DashboardBaseLayout from "../components/DashboardBaseLayout";
import LandingTrendingCurrency from "../components/LandingTrendingCurrency";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import UserContext from "../utils/userContext";
import {axiosSend} from "../utils/request";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import {appName} from "../helpers";
import {ButtonOutline, DropdownList} from "@secondstaxorg/sscomp";
import TradingHistoryTable from "../components/TradingHistoryTable/TradingHistoryTable";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";


const Dashboard = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${t('common:dashboard_side_menu.dashboard')}`
    const [txns,setTxns]:any = useState([])
    const [txnsOri,setTxnsOri]:any = useState([])
    const [loading,setLoading] = useState(true)
    const [filter,setFilter] = useState('NW')
    const userDets:any = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(()=>{
        if(Object.keys(userDets).length > 0 && userDets.profile.length > 0){
            axiosSend({
                url:`/all-trnx`,
                method:'GET'
            }).then(res=> {
                if (res.data.data && res.data.data.length){
                    let tmp:any = []
                    res.data.data.map((item:any) => {
                        tmp.push({
                            providerLogo:item.LP_logos[24] ? item.LP_logos[24] : '',
                            providerName:item.lip_name,
                            broker:item.broker,
                            baseCurrFlag:item.base_curr_flag,
                            baseAmount:item.base_amount,
                            baseCurrency:item.base_curr,
                            exchangeCurrFlag:item.exch_curr_flag,
                            exchangeAmount:item.exch_amount,
                            exchangeCurrency:item.exch_curr,
                            transactionId:item.ref_no,
                            status:item.trans_status,
                            date:dayjs(item.trans_date).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                            clientId: item.client_id
                        })
                    })
                    setTxnsOri(tmp.sort((a:any,b:any)=>(b.transactionId - a.transactionId)))
                }
                setLoading(false)
            })
        }
    },[userDets])

    useEffect(()=>{
        if (txnsOri.length > 0){
            const filtered = txnsOri.filter((txn:any)=>txn.status === filter)
            if (filtered.length > 0){
                let tmp:any[] = []

                for (let i = 0; i < 5; i++){
                    if (filtered[i]) tmp.push(filtered[i])
                }
                setTxns(tmp)
            }else{
                setTxns([])
            }
        }
    },[txnsOri,filter])

    const filterOptions = [
        {
            label:t('common:transaction_statuses.new'),
            value: 'NW'
        },
        {
            label:t('common:transaction_statuses.pending_payment'),
            value: 'PCP'
        },
        {
            label:t('common:transaction_statuses.sent_to_lp'),
            value: 'PLC'
        },
        {
            label:t('common:transaction_statuses.confirmed_with_lp'),
            value: 'PLP'
        },
        {
            label:t('common:transaction_statuses.processing_at_lp'),
            value: 'PBC'
        },
        {
            label:t('common:transaction_statuses.complete'),
            value: 'ST'
        },
        {
            label:t('common:transaction_statuses.canceled'),
            value: 'CAN'
        }
    ]

    return (
        <DashboardBaseLayout pageName="dashboard">
            <LandingTrendingCurrency/>
            {/*REQUESTS HISTORY*/}
            <div className="my-[16px]">
                <div className="flex justify-between items-center mb-[24px]">
                    <h6 className={`${typography.heading6Bold} "text-black-100"`}>{t('common:dashboard.recent_reqs')}</h6>
                    <div className="flex items-center gap-[8px]">
                        <DropdownList options={filterOptions} returnedSelection={(a)=>{setFilter(a.value)}} initialText={filter} inputName="recentRequests"/>
                        <div className="w-[350px]">
                            <ButtonOutline size="lg" variant="primary" type="button" onClick={()=>{
                                navigate('/transaction-history')
                            }}>{t('common:dashboard.view_all_reqs')}</ButtonOutline>
                        </div>
                    </div>
                </div>
                <TradingHistoryTable loadingState={loading} transactions={txns}/>
            </div>
        </DashboardBaseLayout>
    )
}

export default Dashboard
