import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import AppFormsTable from "../components/AppFormsTable/AppFormsTable";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import EditAppForm from "../components/AppFormsContent/EditAppForm";
import dayjs from "dayjs";
import AddAppForm from "../components/AppFormsContent/AddAppForm";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const AppForms = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.app_forms')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [appForms,setAppForms]:any = useState([])
    const [appFormsOri,setAppFormsOri]:any = useState([])
    const [loading,setLoading] = useState(true)
    const [formDets,setFormDets]:any = useState({})
    const [editFormPnl,setEditFormPnl] = useState(false)
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [showLogs,setShowLogs] = useState(false)
    const [logs,setLogs]:any = useState([])
    const [addFormPnl,setAddFormPnl] = useState(false)
    const [hasAccess,setHasAccess] = useState(true)

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadAppForms()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function loadAppForms(){
        axiosSend({
            url:'/fx/admin/app-forms',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((form:any)=>{
                    tmp.push({
                        formName:form.af_form_name,
                        formCode:form.af_form_code,
                        userType:form.af_user_id
                    })
                })
                setAppForms(tmp)
                setAppFormsOri(tmp)
            }

            if (res.data.logs && res.data.logs.length > 0){
                let tmpLogs:any = []
                res.data.logs.forEach((log:any)=>{
                    tmpLogs.push({
                        timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id
                    })
                })
                setLogs(tmpLogs)
            }
            setLoading(false)
        })
    }

    function getAppForm(formCode:string){
        axiosSend({
            url:`/fx/admin/app-forms/${formCode}`,
            method:'GET'
        }).then(res => {
            if (res.data.data){
                setFormDets(res.data.data[0])
            }
            setEditFormPnl(true)
        })
    }

    function searchForm(qry:string){
        setSrchQry(qry)
        setAppForms(appFormsOri.filter((form:any)=>form.formName.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="app-forms">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.app_forms')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setAddFormPnl(true)}}>{t('common:app_forms.add_app_form')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:app_forms.search_app_forms')} onChange={(e)=>{searchForm(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <AppFormsTable forms={appForms} callback={(e)=>{getAppForm(e.formCode)}}/>
            }
            {editFormPnl &&
                <SidePanel closeFxn={()=>{setEditFormPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:app_forms.edit_form') : ''} subtext={formDets.af_form_name}>
                    <EditAppForm formDets={formDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={true}/>} title={t('common:app_actions.activity_log')}>
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {addFormPnl &&
                <SidePanel closeFxn={()=>{setAddFormPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:app_forms.add_app_form') : ''}>
                    <AddAppForm showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default AppForms