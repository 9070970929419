import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import {exportExcelMulti, exportPDF} from "../../helpers";
import dayjs from "dayjs";
import UserContext from "../../utils/userContext";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, DropdownList, LoadingSpinner, TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const NumBrokerTrxStatus = () => {
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [loading,setLoading] = useState(false)
    const [emptyData,setEmptyData] = useState(false)
    const [transactions,setTransactions]:any = useState([])
    const [errMsg,setErrMsg] = useState('')
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const [brokers,setBrokers]:any = useState([])
    const [selBroker,setSelBroker]:any = useState('')
    const [reportPeriod,setReportPeriod] = useState('')
    const [selReportOrg,setSelReportOrg] = useState('')
    const {t} = useTranslation('common')
    const userDets:any = useContext(UserContext)

    useEffect(()=>{
        if (Object.keys(userDets).length > 0) setSelBroker(userDets.profile[0].user_org)
    },[userDets])

    useEffect(()=>{
        axiosSend({
            url:`/fx/admin/org/`,
            method:'GET'
        }).then(res => {
            if(res.data.data){
                let tempBrkr:any[] = []
                tempBrkr.push({
                    label:t('common:general.all'),
                    value:'all'
                })
                res.data.data.forEach((broker:any)=>{
                    tempBrkr.push({
                        label:`${broker.org_name1} ${broker.org_name2}`,
                        value:broker.org_code
                    })
                })
                setBrokers(tempBrkr)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        setTransactions([])
    },[selBroker])

    function getTrx(){
        if (!startDate || !endDate){
            setErrMsg(t('common:general.all_fields_required'))
            return
        }else {
            setErrMsg('')
        }

        setLoading(true)
        axiosSend({
            url:`/fx/reports/broker-status-nbr-trx?startDate=${startDate}&endDate=${endDate}&OrgCode=${selBroker}`,
            method:'GET'
        }).then(res => {
            if (res.data.data){
                setTransactions(res.data.data)
                setSelReportOrg(selBroker !== 'all' ? res.data.data[0].org_name : t('common:general.all'))
                setReportPeriod(dayjs(startDate).format('MMM D YYYY')+' - '+dayjs(endDate).format('MMM D YYYY'))
                setEmptyData(false)
            }else if(!res.data.data || res.data.data.length === 0){
                setEmptyData(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        transactions.forEach((data:any) => {
            let entityName = data.org_name
            let tempData:any = []
            data.transactions.map((trx:any)=>{
                tempData.push({
                    'Status':trx.trans_status_name,
                    'Number of Transactions':Number(trx.nbr_transactions)
                })
            })
            let grouping = {
                entityName:entityName,
                trxs:tempData
            }
            formattedData.push(grouping)
        })
        exportExcelMulti(formattedData,reportPeriod,userDets.profile[0].user_name,`${t('common:reports.num_broker_trx_by_status')}: ${selReportOrg}`)
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal && <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,`${t('common:reports.num_broker_trx_by_status')}: ${selReportOrg}`)
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
                <div id="reportPreview">
                    {transactions.length > 0 &&
                    <div className="bg-neutral-100 mt-[12px]">
                        {transactions.map((data:any,index:number) => (
                            <div key={index}>
                                {selBroker === 'all' && <p className="font-bold px-[8px] py-[4px]">{data.org_name}</p>}
                                <TableAlternative thead={[t('common:general.status'),t('common:reports.num_trx')]}>
                                    {data.transactions.map((trx:any,index:number)=>(
                                        <tr key={index}>
                                            <td>{trx.trans_status_name}</td>
                                            <td>{trx.nbr_transactions}</td>
                                        </tr>
                                    ))}
                                </TableAlternative>
                            </div>
                        ))}
                    </div>
                    }
                </div>
            </ExportPreviewModal>}
            <div className="mb-[16px]">
                <div className="mb-[16px]">
                    <h6 className={typography.heading6Bold}>{t('common:reports.num_broker_trx_by_status')}</h6>
                </div>
                <div className="flex gap-[16px]">
                    <div className="w-full">
                        <form onSubmit={(e)=> {
                            e.preventDefault()
                            getTrx()
                        }} className="grid grid-cols-3 gap-[8px] w-10/12">
                            {Object.keys(userDets).length > 0 && userDets.profile[0].user_type === 'SX' &&
                                <DropdownList options={brokers} returnedSelection={(a)=>{setSelBroker(a.value)}} placeholder={t('common:reports.select_broker')} searchable={true} inputName="broker"/>
                            }
                            <DateRangePicker selectedDateRange={(a)=>{
                                setStartDate(dayjs(a.startDate).format('YYYY-MM-DD'))
                                if (a.endDate) setEndDate(dayjs(a.endDate).format('YYYY-MM-DD'))
                            }} placeholder={t('common:reports.pick_date_range')}/>
                            <Button size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>
                        </form>
                        {errMsg && <p className="text-error-500 mt-[8px]">{errMsg}</p>}
                    </div>
                    <div className="w-[250px]">
                        <ButtonOutline size="lg" variant="primary" type="button" disabled={transactions.length === 0 || loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                    </div>
                </div>
            </div>
            <div>
                {!loading &&
                    <>
                        {transactions.length === 0 && !emptyData &&
                            <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                        }
                        {transactions.length === 0 && emptyData &&
                            <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                        }
                        {transactions.length > 0 &&
                            <>
                            {transactions.map((data:any,index:number) => (
                                <div key={index}>
                                    {selBroker === 'all' && <p className="font-bold px-[8px] pb-[4px] pt-[16px]">{data.org_name}</p>}
                                    <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                                        <p className="text-white">{t('common:general.status')}</p>
                                        <p className="text-white">{t('common:reports.num_trx')}</p>
                                    </div>
                                    <div className="flex flex-col gap-[8px]">
                                        {data.transactions.length > 0 && data.transactions.map((trx:any,index:number)=>(
                                            <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                                <p>{trx.trans_status_name}</p>
                                                <p>{trx.nbr_transactions}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}

                            </>
                        }
                    </>
                }
            </div>

            {loading &&
                <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant="primary"/></div>
            }
        </DashboardBaseLayout>
    )
}

export default NumBrokerTrxStatus