import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import dayjs from "dayjs";
import VDivider from "../../components/VDivider";
import EmptyState from "../../components/EmptyState";
import ReportTrxListTable from "../../components/ReportTrxListTable";
import {exportExcel, exportExcelMulti, exportPDF, moneyfy} from "../../helpers";
import UnauthorisedAccess from "../../components/UnauthorisedAccess";
import UserContext from "../../utils/userContext";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, DropdownList, LoadingSpinner, TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const LpTransactionsList = () => {
    const [selLP,setSelLP] = useState('')
    const [lpList,setLpList]:any = useState([])
    const [loading,setLoading] = useState(false)
    const [emptyData,setEmptyData] = useState(false)
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [trxList,setTrxList]:any = useState([])
    const [errMsg,setErrMsg] = useState('')
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const userDets:any = useContext(UserContext)
    const [selReportLP,setSelReportLP] = useState('')
    const [reportPeriod,setReportPeriod] = useState('')
    const [repGrpData,setRepGrpData]:any = useState([])
    const {t,i18n} = useTranslation('common')

    useEffect(()=>{
        axiosSend({
            url:`/fx/admin/liq-prov/`,
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tempList:any = []
                tempList.push({
                    label:t('common:general.all'),
                    value:'all'
                })
                res.data.data.forEach((provider:any)=>{
                    tempList.push({
                        label:provider.lip_name,
                        value:provider.lip_code
                    })
                })
                setLpList(tempList)
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        setTrxList([])
        setRepGrpData([])
    },[selLP])

    function genReport(){
        if (!selLP || !startDate || !endDate){
            setErrMsg(t('common:reports.select_provider_start_date_end_date'))
            return
        }else {
            setErrMsg('')
        }

        axiosSend({
            url: `/fx/reports/lp-list-trx?startDate=${startDate}&endDate=${endDate}&lipCode=${selLP}`,
            method: 'GET'
        }).then(res => {
            if (res.data.data){
                if (selLP !== 'all'){
                    let tempTrx:any[] = []
                    res.data.data[0].transactions.forEach((trx:any)=>{
                        tempTrx.push({
                            providerLogo:'',
                            providerName:trx.lip_name,
                            baseCurrFlag:'',
                            baseAmount:trx.ptr_source_amount,
                            baseCurrency:trx.ptr_source_cur_code,
                            exchangeCurrFlag:'',
                            exchangeAmount:trx.ptr_dest_amount,
                            exchangeCurrency:trx.ptr_dest_cur_code,
                            transactionId:trx.ptr_ref_no,
                            status:trx.trans_status_name,
                            date:dayjs(trx.ptr_trans_date).locale(i18n.language).format('MMM DD YYYY, HH:mm'),
                            clientId:trx.client_id,
                            broker:trx.org_name
                        })
                    })
                    setTrxList(tempTrx)
                    setSelReportLP(tempTrx[0].providerName)
                }else{
                    let groupArr:any[] = []
                    res.data.data.map((data:any) => {
                        let tempData:any = []
                        let lpName = data.lp_name
                        data.transactions.map((trx:any) => {
                            tempData.push({
                                providerLogo:'',
                                providerName:trx.lip_name,
                                baseCurrFlag:'',
                                baseAmount:trx.ptr_source_amount,
                                baseCurrency:trx.ptr_source_cur_code,
                                exchangeCurrFlag:'',
                                exchangeAmount:trx.ptr_dest_amount,
                                exchangeCurrency:trx.ptr_dest_cur_code,
                                transactionId:trx.ptr_ref_no,
                                status:trx.trans_status_name,
                                date:dayjs(trx.ptr_trans_date).locale(i18n.language).format('MMM DD YYYY, HH:mm'),
                                clientId:trx.client_id,
                                broker:trx.org_name
                            })
                        })
                        let grouping = {
                            lpName:lpName,
                            trxs:tempData
                        }
                        groupArr.push(grouping)
                        setRepGrpData(groupArr)
                        setSelReportLP('All')
                    })
                }

                setReportPeriod(dayjs(startDate).locale(i18n.language).format('MMM D YYYY')+' - '+dayjs(endDate).locale(i18n.language).format('MMM D YYYY'))
                setEmptyData(false)
            }else if(!res.data.data || res.data.data.length === 0){
                setEmptyData(true)
                setTrxList([])
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    function convertExcelHeaders(){
        let formattedData: any[] = []
        if (selLP !== 'all'){
            trxList.forEach((trx:any) => {
                formattedData.push({
                    'Provider':trx.providerName,
                    'Base Currency':trx.baseCurrency,
                    'Base Amount':Number(trx.baseAmount),
                    'Exchange Currency':trx.exchangeCurrency,
                    'Exchange Amount':Number(trx.exchangeAmount),
                    'Transaction ID': trx.transactionId,
                    'Status': trx.status,
                    'Date':trx.date
                })
            })
            exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,`${t('common:reports.lp_trx_list')}: ${selReportLP}`)
        }else{
            repGrpData.map((data:any) => {
                let tempData:any = []
                let entityName = data.lpName
                data.trxs.map((trx:any) => {
                    tempData.push({
                        'Provider':trx.providerName,
                        'Base Amount':Number(trx.baseAmount),
                        'Base Currency':trx.baseCurrency,
                        'Exchange Amount':Number(trx.exchangeAmount),
                        'Exchange Currency':trx.exchangeCurrency,
                        'Transaction ID': trx.transactionId,
                        'Status': trx.status,
                        'Date':trx.date
                    })
                })
                let grouping = {
                    entityName:entityName,
                    trxs:tempData
                }
                formattedData.push(grouping)
            })
            exportExcelMulti(formattedData,reportPeriod,userDets.profile[0].user_name,`${t('common:reports.lp_trx_list')}: ${selReportLP}`)
        }
    }

    if (Object.keys(userDets).length > 0 && userDets.profile[0].user_type !== 'SX'){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            <div className="mb-[16px]">
                <h6 className={typography.heading6Bold}>{t('common:reports.lp_trx_list')}</h6>
            </div>
            <div className="mb-[16px]">
                <div className="flex gap-[16px] items-end">
                    <form onSubmit={(e)=>{
                        e.preventDefault()
                        genReport()
                    }} className="w-full grid grid-cols-4 gap-[16px] items-end">
                        <DropdownList options={lpList} returnedSelection={(val)=>{setSelLP(val.value)}} placeholder={t('common:reports.select_provider')} searchable={true} inputName="provider"/>
                        <DateRangePicker selectedDateRange={(a)=>{
                            setStartDate(dayjs(a.startDate).locale(i18n.language).format('YYYY-MM-DD'))
                            if (a.endDate) setEndDate(dayjs(a.endDate).locale(i18n.language).format('YYYY-MM-DD'))
                        }} placeholder="Pick date range"/>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>
                    </form>
                    <VDivider height={50}/>
                    <div className="w-[200px]">
                        <ButtonOutline size="lg" variant="primary" type="submit" disabled={(trxList.length === 0 && repGrpData.length === 0) || loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                    </div>
                </div>
                {errMsg && <p className="mt-[8px] text-error-500">{errMsg}</p>}
            </div>
            {!loading &&
                <>
                    {trxList.length === 0 && repGrpData.length === 0 && !emptyData && //initial state when report is yet to be generated
                        <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                    }
                    {trxList.length === 0 && repGrpData.length === 0 && emptyData && //when report is generated but no data is returned
                        <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                    }
                    {selLP !== 'all' &&
                        <>
                            {trxList.length > 0 && //when report is generated and data is returned
                                <ReportTrxListTable transactions={trxList} loadingState={false}/>
                            }
                        </>
                    }
                    {selLP === 'all' &&
                        <>
                            {repGrpData.length > 0 && repGrpData.map((grp:any,index:number) => (
                                <div key={index}>
                                    <p className="-mb-[20px] font-[700]">{grp.lpName}</p>
                                    <ReportTrxListTable transactions={grp.trxs} loadingState={false}/>
                                </div>
                            ))}
                        </>
                    }
                </>
            }
            {loading &&
                <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant="primary"/></div>
            }
            {exportModal &&
                <ExportPreviewModal title="Report Preview"
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,`${t('common:reports.lp_trx_list')}: ${selReportLP}`)
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                        closeFxn={()=>{setExportModal(false)}}>
                    <div id="reportPreview">
                        {selLP === 'all' &&
                            <div className="bg-neutral-100 mt-[12px]">
                                {repGrpData.map((data:any,index:number) => (
                                    <div key={index}>
                                        <p className="font-bold px-[8px] py-[4px]">{data.lpName}</p>
                                        <TableAlternative thead={[t('common:provider_rates.provider'),t('common:general.broker'),t('common:general.client_id'),t('common:request_lp.base_amount'),t('common:general.exchange_amount'),t('common:reports.transaction_id'),t('common:general.status'),t('common:general.date')]}>
                                            {data.trxs.map((trx:any,index:number)=>(
                                                <tr key={index}>
                                                    <td>{trx.providerName}</td>
                                                    <td>{trx.broker}</td>
                                                    <td>{trx.clientId}</td>
                                                    <td>{moneyfy(trx.baseAmount,trx.baseCurrency)}</td>
                                                    <td>{moneyfy(trx.exchangeAmount,trx.exchangeCurrency)}</td>
                                                    <td>{trx.transactionId}</td>
                                                    <td>{trx.status}</td>
                                                    <td>{trx.date}</td>
                                                </tr>
                                            ))}
                                        </TableAlternative>
                                    </div>

                                ))}
                            </div>
                        }
                        {selLP !== 'all' &&
                            <TableAlternative thead={[t('common:provider_rates.provider'),t('common:general.broker'),t('common:general.client_id'),t('common:request_lp.base_amount'),t('common:general.exchange_amount'),t('common:reports.transaction_id'),t('common:general.status'),t('common:general.date')]}>
                                {trxList.map((trx:any,index:number)=>(
                                    <tr key={index}>
                                        <td>{trx.providerName}</td>
                                        <td>{trx.broker}</td>
                                        <td>{trx.clientId}</td>
                                        <td>{moneyfy(trx.baseAmount,trx.baseCurrency)}</td>
                                        <td>{moneyfy(trx.exchangeAmount,trx.exchangeCurrency)}</td>
                                        <td>{trx.transactionId}</td>
                                        <td>{trx.status}</td>
                                        <td>{trx.date}</td>
                                    </tr>
                                ))}
                            </TableAlternative>
                        }
                    </div>
                </ExportPreviewModal>
            }
        </DashboardBaseLayout>
    )
}

export default LpTransactionsList