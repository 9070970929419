import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextArea, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface EditModuleProps {
    moduleDets:any,
    showPanelTitle:(a:boolean)=>void
}

const EditAppModule = (props:EditModuleProps) => {
    const {moduleDets,showPanelTitle} = props
    const [name,setName] = useState(moduleDets.app_name)
    const [code,setCode] = useState(moduleDets.app_code)
    const [remarks,setRemarks] = useState(moduleDets.app_upd_rem)
    const [stage,setStage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    function submitForm(){
        if (!name || !code) return null
        setLoading(true)
        let formData = {
            appName: name,
            appCode: code,
            appUpdRem: remarks,
            action: 'codeMaintenance'
        }

        axiosSend({
            url: `/fx/admin/apps/${moduleDets.app_code}`,
            method: 'PUT',
            data: formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    submitForm()
                }} className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 gap-[24px]">
                        <TextField label={t('common:app_modules.module_name')} value={name} onChange={(e)=>{setName(e.target.value)}} required={true}/>
                        <TextField label={t('common:app_modules.module_code')} value={code} onChange={(e)=>{setCode(e.target.value)}} required={true}/>
                        <TextArea label={t('common:general.remarks')} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}}/>
                    </div>
                    <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:app_modules.update_module')}</Button>
                </form>
            }
            {stage === 2 &&
                <>
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                {resp.Status === 'SUCC' &&
                                    <ActionSuccess headingText={t('common:app_modules.app_module_updated')} subText={t('common:app_modules.app_module_updated_msg',{moduleName:moduleDets.app_name})}/>
                                }
                                {resp.Status === 'FAIL' &&
                                    <ActionFailed headingText={resp.Message}/>
                                }
                            </div>
                            <Button type="button" size="lg" variant="primary" onClick={()=>{
                                if (resp.Status === 'SUCC'){
                                    window.location.reload()
                                }else {
                                    setStage(1)
                                }
                            }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                        </div>
                </>
            }
        </>
    )
}

export default EditAppModule