import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import dayjs from "dayjs";
import {
    ActionFailed,
    ActionSuccess,
    Button, CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

interface AddRatesProps {
    providerId:string,
    step:number,
    stepFxn:(a:number)=>void
}

const ProviderAddRates = (props:AddRatesProps) => {
    const {providerId,stepFxn,step} = props
    const {t,i18n} = useTranslation('common')
    const [currencies,setCurrencies]:any = useState([])
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [baseCurr,setBaseCurr] = useState('')
    const [exchCurr,setExchCurr] = useState('')
    const [buyRate,setBuyRate] = useState('')
    const fromDate = dayjs(new Date()).locale(i18n.language).format('YYYY-MM-DD HH:mm')
    const [toDate,setToDate] = useState('')
    const [provTotalBalance,setProvTotalBalance] = useState('')
    const [stage,setStage] = useState(step)
    const [errMsg,setErrMsg] = useState('')
    const [validity,setValidity] = useState('')
    const validityPeriods = [
        {
            label:t('common:liquidity_providers.30_mins'),
            value:'30min'
        },
        {
            label:t('common:liquidity_providers.1_hr'),
            value:'1hr'
        },
        {
            label:t('common:liquidity_providers.2_hrs'),
            value:'2hr'
        },
        {
            label:t('common:liquidity_providers.6_hrs'),
            value:'6hr'
        },
        {
            label:t('common:liquidity_providers.12_hrs'),
            value:'12hr'
        },
        {
            label:t('common:liquidity_providers.24_hrs'),
            value:'24hr'
        }
    ]

    useEffect(()=>{
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    },[])

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        if (validity){
            let vl
            switch (validity){
                case '30min':
                    vl = dayjs(new Date()).add(30,'minutes').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
                case '1hr':
                    vl = dayjs(new Date()).add(1,'hour').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
                case '2hr':
                    vl = dayjs(new Date()).add(2,'hours').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
                case '6hr':
                    vl = dayjs(new Date()).add(6,'hours').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
                case '12hr':
                    vl = dayjs(new Date()).add(12,'hours').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
                case '24hr':
                    vl = dayjs(new Date()).add(24,'hours').locale(i18n.language).format('YYYY-MM-DD HH:mm')
                    break
            }
            setToDate(vl as string)
        }
    },[validity])

    function addProvRate(){
        if (!baseCurr || !exchCurr || !fromDate || !toDate || !provTotalBalance){
            setErrMsg(t('common:general.some_required_fields_empty'))
            return
        }else if(!buyRate){
            setErrMsg(t('common:liquidity_providers.enter_base_curr_rate'))
            return
        }else{
            setErrMsg('')
        }

        const formBody = {
            lprCode:providerId,
            lprBaseCurr:baseCurr,
            lprExchCurr:exchCurr,
            lprExchBuyRate:buyRate,
            lprExchSellRate:1,
            lprDateFrom:fromDate,
            lprDateTo:toDate,
            lprDelType:"INS",
            lprAmount:provTotalBalance,
            action:"savIndRates"
        }

        setLoading(true)
        axiosSend({
            url:"/fx/admin/liq-prov-rate",
            method:"POST",
            data:JSON.stringify(formBody)
        }).then(res=> {
            setResp(res.data)
            stepFxn(2)
            setLoading(false)
        });
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    addProvRate()
                }} className="flex flex-col flex-1">
                    <div className="flex flex-col gap-[40px] flex-1">
                        <CountrySelector options={currencies} selectedOption={(e)=>{setBaseCurr(e.optionShortName)}} placeholder={t('common:general.select_currency')} label={t('common:request_lp.base_currency')} inputName="baseCurrency"/>
                        <CountrySelector options={currencies} selectedOption={(e)=>{setExchCurr(e.optionShortName)}} placeholder={t('common:general.select_currency')} label={t('common:request_lp.exchange_currency')} inputName="exchangeCurrency"/>
                        <TextField label={t('common:provider_rates.rate')} type="text" placeholder={t('common:liquidity_providers.enter_rate')} required={true} value={buyRate} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setBuyRate(e.target.value)
                        }}/>
                        <TextField label={t('common:liquidity_providers.provider_balance')} type="text" placeholder={t('common:liquidity_providers.enter_balance')} value={provTotalBalance} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setProvTotalBalance(e.target.value)
                        }}/>
                        <DropdownList options={validityPeriods} returnedSelection={(e)=>{setValidity(e.value)}} label={t('common:liquidity_providers.validity_period')} placeholder={t('common:liquidity_providers.select_validity_period')} inputName="validity"/>
                    </div>
                    <div>
                        {errMsg && <p className="text-error-500 text-[14px] mb-[4px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:general.save')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:liquidity_providers.rate_added')}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button type="button" size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else {
                            stepFxn(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default ProviderAddRates