import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import {useNavigate} from "react-router-dom";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextArea, TextField} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface ConfTxnProps{
    txnNo:string,
    backBtnDisabledFxn:(a:boolean)=>void
}

const ConfTxn = (props:ConfTxnProps) => {
    const {txnNo,backBtnDisabledFxn} = props

    const [confirming,setConfirming] = useState(false)
    const [refNo,setRefNo] = useState('')
    const [remarks,setRemarks] = useState('')
    const [confTxnRes,setConfTxnRes]:any = useState({})
    const [stage,setStage] = useState(1)
    const navigate = useNavigate()
    const [refNoErr,setRefNoErr] = useState('')
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (stage === 2) {
            backBtnDisabledFxn(true)
        }
    },[stage])

    function confirmTxn(){
        setRefNoErr('')
        if (Number(refNo) <= 1){
            setRefNoErr(t('common:transaction_history.invalid_ref_no'))
            return null
        }

        setConfirming(true)
        let formBody = {
            action:'cfmTrnx',
            tranxNo:txnNo,
            lpRefNumber:refNo,
            updRem:remarks
        }

        axiosSend({
            url:'/fx/save-actions-symbol',
            method:"POST",
            data:JSON.stringify(formBody)
        }).then(res => {
            setConfTxnRes(res.data)
            setConfirming(false)
            setStage(2)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
       <>
           {stage === 1 &&
               <>
                   <div className="mb-[40px]">
                       <h6 className="text-neutral-900 font-bold text-[19px]">{t('common:transaction_history.confirm_transaction')}</h6>
                       <p className="text-neutral-900 text-[14px]">{t('common:transaction_history.youre_about_to_confirm_transaction')}</p>
                   </div>
                   <div className="mb-[24px]">
                       <TextField type="text" label={t('common:transaction_history.reference_number')} placeholder={t('common:general.enter_number')} value={refNo} onChange={(e)=>{
                           setRefNo(e.target.value)
                       }} autoFocus={true} required/>
                       {refNoErr && <div className="mt-[8px] flex items-center gap-[4px]"><i className="icon-exclamation-triangle text-error-500"/><span className="text-error-500 text-[14px]">{refNoErr}</span></div>}
                   </div>
                   <div className="mb-[24px]">
                       <TextArea label={`${t('common:general.remarks')} (${t('common:general.optional')})`} placeholder={t('common:general.enter_remarks')} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}}/>
                   </div>
                   <div className="p-[32px] w-full absolute bottom-0 right-0 flex justify-between gap-[8px] bg-white">
                       <Button size="lg" variant="primary" disabled={confirming || !refNo} onClick={()=>{confirmTxn()}}>{confirming ? <LoadingSpinner variant={"primary"}/> : t('common:transaction_history.confirm_transaction')}</Button>
                   </div>
               </>
           }
           {stage === 2 &&
               <div className="flex flex-1 flex-col">
                   {confTxnRes.Status === 'SUCC' &&
                       <>
                           <div className="flex-1 flex-col flex justify-center items-center">
                               <ActionSuccess headingText={t('common:transaction_history.request_sent')} subText={confTxnRes.Message}/>
                           </div>
                           <Button variant="primary" size="md" onClick={()=>{
                               //force the page to reload if it's already on the trading history page
                               navigate(0)
                           }}>{t('common:general.sounds_good')}</Button>
                       </>
                   }
                   {confTxnRes.Status === 'FAIL' &&
                       <>
                           <div className="flex-1 flex-col flex justify-center items-center">
                               <ActionFailed headingText={confTxnRes.Message}/>
                           </div>
                           <Button variant="primary" size="lg" onClick={()=>{setStage(stage - 1)}}>{t('common:general.try_again')}</Button>
                       </>
                   }
               </div>
           }
       </>
    )
}

export default ConfTxn
