import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {PasswordMeter} from "password-meter";
import {axiosSend} from "../../utils/request";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, PasswordField} from "@secondstaxorg/sscomp";
import {apiUrl} from "../../helpers";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

interface ProfileContentProps{
    userDets:any,
    viewFxn:(a:string)=>void,
    step:number,
    stepFxn:(a:number)=>void,
    view:string
}

const ProfileContent = (props:ProfileContentProps) => {
    const {userDets,stepFxn,viewFxn,step,view} = props
    const [stage,setStage] = useState(step)
    const [cntView,setCntView] = useState(view)
    const [currPass,setCurrPass] = useState('')
    const [newPass,setNewPass] = useState('')
    const [pwBarsBg,setPwBarsBg] = useState('bg-neutral-50')
    const barsProgress: JSX.Element[] = [];
    const [numProgBars,setNumProgBars] = useState(0)
    const [pwErrors,setPwErrors]:any = useState([])
    const passLength = 8
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const [errMsg,setErrMsg] = useState('')
    const [numErrs,setNumErrs] = useState(0)
    const {t,i18n} = useTranslation('common')

    for (let i = 1; i <= numProgBars; i++){
        barsProgress.push(<div key={i} className={`pw-strength-bar ${pwBarsBg}`}/>)
    }

    //to display the gray placeholder bars
    const bars: JSX.Element[] = [];
    for(let i = 1; i <= 5; i++){
        bars.push(<div key={i} className={`pw-strength-bar bg-neutral-50`}/>)
    }

    useEffect(()=>{
        const pwMeter = new PasswordMeter({
            minLength: {
                value: passLength,
                message: 'charLength'
            },
            uppercaseLettersMinLength:{
                value: 1,
                message: 'uppercase'
            },
            lowercaseLettersMinLength:{
                value: 1,
                message: 'lowercase'
            },
            numbersMinLength:{
                value: 1,
                message: 'number'
            },
            symbolsMinLength:{
                value: 1,
                message: 'specialChar'
            }
        },{
            "140": "bareMinimum", //<= 149
            "160": "canBeBetter", //150 - 179
            "_": "perfect" // >= 180
        })

        if (pwMeter.getResults([newPass])[0].errors){
            setPwErrors(pwMeter.getResults([newPass])[0].errors)
        }else{
            setPwErrors([])
        }

        if (pwMeter.getResults([newPass])[0].score >= 140 && pwMeter.getResults([newPass])[0].score < 160){
            setPwBarsBg('bg-success-300')
            setNumProgBars(4)
        }else if(pwMeter.getResults([newPass])[0].score >= 160){
            setPwBarsBg('bg-success-500')
            setNumProgBars(5)
        }else if(pwMeter.getResults([newPass])[0].score === 0){
            setPwBarsBg('')
            setNumProgBars(0)
        }else if(pwMeter.getResults([newPass])[0].score >= 1 && pwMeter.getResults([newPass])[0].score < 140 ){
            setPwBarsBg('bg-error-500')
            setNumProgBars(1)
        }else{
            setPwBarsBg('')
            setNumProgBars(0)
        }

        const errors = pwMeter.getResults([newPass])[0].errors
        if (errors){
            setNumErrs(errors.length)
        }else{
            setNumErrs(0)
        }

        if (newPass && errors && errors.length > 0){
            if (errors.length > 2){ //if there are more than 2 errors being returned
                setPwBarsBg('bg-error-500')
                setNumProgBars(1)
            } else if(errors.length === 2){ //if there are 2 errors
                setPwBarsBg('bg-warning-300')
                setNumProgBars(2)
            } else if(errors.length === 1){ //if there's only 1 error
                setPwBarsBg('bg-warning-400')
                setNumProgBars(3)
            }
        }
    },[newPass])

    function getUserInitials(){
        let ini = ''
        let spl = userDets.user_name.split(' ')
        ini += spl[0][0]+spl[spl.length-1][0]
        return ini
    }

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        setCntView(view)
    },[view])

    function changePass(){
        if (!newPass || !currPass){
            setErrMsg(t('common:general.all_fields_required'))
            return
        }else{
            setErrMsg('')
        }

        if(numErrs > 0){
            setErrMsg(t('common:pw_requirements.pw_requirements_msg'))
            return
        }else{
            setErrMsg('')
        }

        let formData = {
            usrOldPwd:currPass,
            usrNewPwd:newPass,
            usrConfPwd:newPass,
            action:'savPassword'
        }
        setLoading(true)
        axiosSend({
            url:`/fx/admin/users/change-pwd/${userDets.usrcode}`,
            method:'PATCH',
            data:formData,
            headers:{'Access-Control-Allow-Origin': apiUrl, 'Content-Type': 'application/json'}
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            stepFxn(2)
        }).catch(err => {
            console.log(err)
            setErrMsg(err.message)
            setLoading(false)
        })
    }

    return (
        <>
            {cntView === 'profile' &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <div className="profile-info-card">
                        <div className="flex gap-[12px] border-[1px] border-neutral-100 rounded-[8px] p-[16px]">
                            <div className="profile-initials">{getUserInitials()}</div>
                            <div className="profile-info">
                                <p>{userDets.user_name}</p>
                                <p>{userDets.user_email}</p>
                                <p>{t('common:general.joined_on')} {dayjs(userDets.user_date_created).locale(i18n.language).format(' D MMM YYYY')}</p>
                            </div>
                        </div>

                        {/*if there is no address, the other fields for the address wont be available either*/}
                        {userDets.user_address &&
                            <div className="flex gap-[12px] border-[1px] border-neutral-100 rounded-[8px] p-[16px]">
                                <div className="profile-info">
                                    <p>{t('common:dashboard.address')}</p>
                                    {userDets.user_address && <p>{userDets.user_address}</p>}
                                    {userDets.user_city && <p>{userDets.user_city}</p>}
                                    {userDets.user_country && <p>{userDets.user_country}</p>}
                                </div>
                            </div>
                        }
                        <div className="flex gap-[12px] border-[1px] border-neutral-100 rounded-[8px] p-[16px]">
                            <div className="profile-info">
                                <p>{t('common:dashboard.organisation')}</p>
                                <p>{userDets.user_org_name}</p>
                            </div>
                        </div>
                        <div className="flex gap-[12px] border-[1px] border-neutral-100 rounded-[8px] p-[16px]">
                            <div className="profile-info">
                                <p>{t('common:dashboard.last_login')}</p>
                                <p>{dayjs(userDets.user_date_last_succ_login).locale(i18n.language).format('D MMM YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    <Button size="lg" variant="primary" type="button" onClick={()=>{
                        setCntView('password')
                        viewFxn('password')
                    }}>{t('common:dashboard.change_password')}</Button>
                </div>
            }
            {cntView === 'password' &&
                <>
                    {stage === 1 &&
                        <form className="flex flex-col flex-1" onSubmit={(e)=>{
                            e.preventDefault()
                            changePass()
                        }}>
                            <div className="flex flex-col flex-1">
                                <div>
                                    <div className="flex flex-col flex-1 gap-[40px] mb-[16px]">
                                        <PasswordField onChange={(e)=>{setCurrPass(e.target.value)}} value={currPass} label={t('common:dashboard.current_password')} errMsg={t('common:dashboard.enter_password')} placeholder="••••••••"/>
                                        <PasswordField onChange={(e)=>{setNewPass(e.target.value)}} value={newPass} label={t('common:dashboard.new_password')} errMsg={t('common:dashboard.enter_new_password')} placeholder="••••••••"/>
                                    </div>
                                    <div>
                                        <div className="pw-strength">
                                            <div className="absolute grid grid-cols-5 gap-[5px] left-0 right-0">
                                                {bars}
                                            </div>
                                            <div className="absolute grid grid-cols-5 gap-[5px] left-0 right-0">
                                                {barsProgress}
                                            </div>
                                        </div>
                                        <div className="pt-[16px]">
                                            {/*<p>The minimum requirement for a password includes the following:</p>*/}
                                            <p>{t('common:login.pw_requirements')}:</p>
                                            <div className="mt-2">
                                                <p className="flex items-center"><i className={`${newPass && !pwErrors.includes('charLength') ? 'text-success-500' : 'text-neutral-300'} fa-solid fa-circle-check mr-1`}/>{t('common:pw_requirements.min_chars')}</p>
                                                <p className="flex items-center"><i className={`${newPass && !pwErrors.includes('lowercase') ? 'text-success-500' : 'text-neutral-300'} fa-solid fa-circle-check mr-1`}/>{t('common:pw_requirements.lowercase')}</p>
                                                <p className="flex items-center"><i className={`${newPass && !pwErrors.includes('uppercase') ? 'text-success-500' : 'text-neutral-300'} fa-solid fa-circle-check mr-1`}/>{t('common:pw_requirements.uppercase')}</p>
                                                <p className="flex items-center"><i className={`${newPass && !pwErrors.includes('number') ? 'text-success-500' : 'text-neutral-300'} fa-solid fa-circle-check mr-1`}/>{t('common:pw_requirements.number')}</p>
                                                <p className="flex items-center"><i className={`${newPass && !pwErrors.includes('specialChar') ? 'text-success-500' : 'text-neutral-300'} fa-solid fa-circle-check mr-1`}/>{t('common:pw_requirements.special_char')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errMsg && <p className="text-error-500 text-[14px] mb-[4px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                            <Button size="lg" variant="primary" type="submit" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:dashboard.save_password')}</Button>
                        </form>
                    }
                    {stage === 2 &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                {resp.Status === 'SUCC' &&
                                    <ActionSuccess headingText={t('common:dashboard.password_changed')} subText={t('common:dashboard.password_changed_msg')}/>
                                }
                                {resp.Status === 'FAIL' &&
                                    <ActionFailed headingText={resp.Message}/>
                                }
                            </div>
                            <Button size="lg" variant="primary" type="button" onClick={()=>{
                                if (resp.Status === 'SUCC'){
                                    viewFxn(t('common:dashboard.user_profile'))
                                    stepFxn(1)
                                }else{
                                    stepFxn(1)
                                }
                            }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.try_again')}</Button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default ProfileContent