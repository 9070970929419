import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import EditUserRole from "../components/UserRolesContent/EditUserRole";
import dayjs from "dayjs";
import AddUserRole from "../components/UserRolesContent/AddUserRole";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import UserRolesTable from "../components/UserRolesTable/UserRolesTable";
import {useTranslation} from "react-i18next";

const UserRoles = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.user_roles')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [loading,setLoading] = useState(false)
    const [showLogs,setShowLogs] = useState(false)
    const [logs,setLogs]:any = useState([])
    const [userRoles,setUserRoles]:any = useState([])
    const [userRolesOri,setUserRolesOri]:any = useState([])
    const [showEditPnl,setShowEditPnl] = useState(false)
    const [showAddPnl,setShowAddPnl] = useState(false)
    const [roleDets,setRoleDets]:any = useState({})
    const [progress,setProgress] = useState(1)
    const [disabledBack,setDisabledBack] = useState(true)
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [hasAccess,setHasAccess] = useState(true)

    function loadUserRoles(){
        axiosSend({
            url:'/fx/admin/roles',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0) {
                let tmp: any = []
                res.data.data.forEach((role: any) => {
                    tmp.push(
                        {
                            roleName: role.role_name,
                            roleCode: role.role_code,
                            clearanceLevel: role.role_clear_level_code
                        }
                    )
                    setUserRoles(tmp)
                    setUserRolesOri(tmp)
                })
            }

            if (res.data.logs && res.data.logs.length > 0) {
                let tmpLogs: any = []
                res.data.logs.forEach((log: any) => {
                    tmpLogs.push({
                        timestamp: dayjs(log.aud_time).format('DD MMM YYYY, HH:mm'),
                        user: log.aud_usr_id,
                        logDescription: log.aud_action,
                        reference: log.aud_id
                    })
                })
                setLogs(tmpLogs)
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadUserRoles()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    useEffect(()=>{
        if (progress === 1) setDisabledBack(true)
        if (progress === 2 || progress === 3 || progress === 4) setDisabledBack(false)
        if (progress === 5){
            setDisabledBack(true)
            setShowPanelTitle(false)
        }
    },[progress])

    function genStepTitle(step:number){
        if (step === 1) return t('common:user_roles.role_information')
        if (step === 2) return t('common:user_roles.role_actions')
        if (step === 3) return t('common:user_roles.role_forms')
        if (step === 4) return t('common:user_roles.role_apps')
    }

    function getRoleDet(roleCode:string){
        axiosSend({
            url:`/fx/admin/roles/${roleCode}`,
            method:'GET'
        }).then(res => {
            setRoleDets(res.data)
            setProgress(1)
            setShowEditPnl(true)
        }).catch(err => {
            console.log(err)
        })
    }

    function srchRole(qry:string){
        setSrchQry(qry)
        setUserRoles(userRolesOri.filter((role:any)=>role.roleName.toLowerCase().includes(qry.toLowerCase()) || role.roleCode.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="user-roles">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.user_roles')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        {Object.keys(userDets).length && userDets.userActions.includes('savRoles') &&
                            <div>
                                <Button variant="primary" size="md" onClick={()=>{
                                    setProgress(1)
                                    setShowAddPnl(true)
                                }}>{t('common:user_roles.add_user_role')}</Button>
                            </div>
                        }
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:user_roles.search_user_role')} onChange={(e)=>{srchRole(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <UserRolesTable roles={userRoles} callback={(e)=>{getRoleDet(e.roleCode)}}/>
            }
            {showEditPnl &&
                <SidePanel closeFxn={()=>{setShowEditPnl(false)}} backBtnArea={<BackButton disabled={disabledBack} onClick={()=>{setProgress(progress - 1)}}/>} title={showPanelTitle ? t('common:user_roles.edit_user_role') : ''} subtext={genStepTitle(progress)} >
                    <EditUserRole roleDets={roleDets} stepFxn={(e)=>{setProgress(e)}} step={progress}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={disabledBack}/>} title="Activity Logs">
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {showAddPnl &&
                <SidePanel closeFxn={()=>{setShowAddPnl(false)}} backBtnArea={<BackButton disabled={disabledBack} onClick={()=>{setProgress(progress - 1)}}/>} title={showPanelTitle ? t('common:user_roles.add_user_role') : ''} subtext={genStepTitle(progress)}>
                    <AddUserRole step={progress} stepFxn={(e)=>{setProgress(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default UserRoles