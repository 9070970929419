import {useEffect, useState} from "react";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    DropdownList,
    LoadingSpinner,
    TextArea,
    TextField
} from "@secondstaxorg/sscomp";
import {axiosSend} from "../../utils/request";
import {useTranslation} from "react-i18next";

interface ActionProps{
    appActionDets:any,
    showPanelTitle:(a:boolean)=>void
}

const EditAppAction = (props:ActionProps) => {
    const {appActionDets,showPanelTitle} = props
    const [title,setTitle] = useState(appActionDets.aa_action_title)
    const [code,setCode] = useState(appActionDets.aa_action_code)
    const [clearance,setClearance] = useState(appActionDets.aa_action_clear_level_code)
    const [desc,setDesc] = useState(appActionDets.aa_action_descr)
    const [loading,setLoading] = useState(false)
    const [stage,setStage] = useState(1)
    const [resp,setResp]:any = useState({})
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (stage === 1) showPanelTitle(true)
        if (stage === 2) showPanelTitle(false)
    },[stage])

    const clearanceVals = [
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        }
    ]

    function editAction(){
        setLoading(true)
        let formData = {
            actionCode:code,
            actionTitle:title,
            actionDesc:desc,
            actionClrLvl:clearance,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/app-actions/${appActionDets.aa_action_code}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form className="flex flex-col flex-1" onSubmit={(e)=>{
                    e.preventDefault()
                    editAction()
                }}>
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TextField value={title} label={t('common:app_actions.action_title')} required={true} onChange={(e)=>{setTitle(e.target.value)}} placeholder="Enter action title"/>
                        <TextField value={code} label={t('common:app_actions.action_code')} required={true} onChange={(e)=>{setCode(e.target.value)}} placeholder="Enter action code"/>
                        <div className="flex flex-col gap-[12px]">
                            <label className="paragraph2Medium">{t('common:general.clearance_level')}</label>
                            <DropdownList options={clearanceVals} initialText={clearance} returnedSelection={(e)=>{setClearance(e.value)}} inputName="clearanceLevel"/>
                        </div>
                        <TextArea value={desc} label={t('common:general.description')} required={true} onChange={(e)=>{setDesc(e.target.value)}} placeholder={t('common:general.enter_description')}/>
                    </div>
                    <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:app_actions.update_app_action')}</Button>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    {resp.Status === 'SUCC' &&
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <ActionSuccess headingText={t('common:app_actions.app_action_updated')} subText={t('common:app_actions.app_action_updated_msg',{actionName:`${appActionDets.aa_action_title}`})}/>
                        </div>
                    }
                    {resp.Status === 'FAIL' &&
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <ActionFailed headingText={resp.Message}/>
                        </div>
                    }
                    <Button type="button" size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC'){
                            window.location.reload()
                        }else {
                            setStage(1)
                        }
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.okay')}</Button>
                </div>
            }
        </>
    )
}

export default EditAppAction