import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ucFirstAll} from "../../helpers";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    EmailField,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";

interface EditUserProps {
    userDets:any,
    refreshedUserData?:(a:any)=>void,
    step:number,
    setStep:(a:number)=>void
}

export default function EditUserContent(props:EditUserProps){
    const {userDets,refreshedUserData,step,setStep} = props

    const [firstName,setFirstName] = useState(userDets.data[0].usr_name1)
    const [lastName,setLastName] = useState(userDets.data[0].usr_name2)
    const [address,setAddress] = useState(userDets.data[0].usr_address)
    const [province,setProvince] = useState(userDets.data[0].usr_addr_province)
    const [country,setCountry]:any = useState({})
    const [postCode,setPostCode] = useState(userDets.data[0].usr_addr_post_code)
    const [city,setCity] = useState(userDets.data[0].usr_addr_city)
    const [phone,setPhone] = useState(userDets.data[0].usr_ph_no)
    const [mobile,setMobile] = useState(userDets.data[0].usr_mb_no)
    const [email,setEmail] = useState(userDets.data[0].usr_email)
    const [organisation,setOrganisation] = useState(userDets.data[0].organization)
    const [role,setRole] = useState(userDets.roles[0].role_name)
    const [userRoles,setUserRoles]:any = useState([])
    const [orgs,setOrgs]:any = useState([])
    const [updating,setUpdating] = useState(false)
    const [stage,setStage] = useState(1)
    const [updtResp,setUpdtResp]:any = useState({})
    const [countries,setCountries]:any = useState([])

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        axiosSend({
            url:'/fx/admin/roles',
            method:"GET"
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let tmp:any = []
                res.data.data.forEach((role:any)=>{
                    tmp.push({
                        label:role.role_name,
                        value:role.role_code
                    })
                })
                setUserRoles(tmp)
            }
        })

        axiosSend({
            url:'/fx/admin/org',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let tmp:any = []
                res.data.data.forEach((org:any)=>{
                    tmp.push({
                        label:org.org_name1+' '+org.org_name2,
                        value:org.org_code
                    })
                })
                setOrgs(tmp)
            }
        })

        //load counties
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    //assign the full name of the country as the initial text
                    if (country.cty_code === userDets.data[0].usr_country){
                        setCountry(country)
                    }

                    tmp.push({
                        optionName:ucFirstAll(country.cty_name),
                        optionShortName:country.cty_code,
                        optionFlag:country.cty_flag_name
                    })
                })
                setCountries(tmp)
            }
        })
    },[])

    function updteUserDets(){
        setUpdating(true)
        const formData = {
            usrName1:firstName,
            usrName2:lastName,
            usrAddress:address,
            usrCountry:country.cty_code,
            usrAddPostCode:postCode,
            usrAddProvince:province,
            usrAddCity:city,
            usrPhone:phone,
            usrMobile:mobile,
            usrEmail:email,
            action:'savUser'
        }

        axiosSend({
            url:`/fx/admin/users/${userDets.data[0].usr_id}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setUpdtResp(res.data)
            setStep(2)
            setUpdating(false)
            if (res.data.Status === 'SUCC'){
                if (refreshedUserData){
                    refreshedUserData(res.data.Message)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            {stage === 1 &&
                <div className="flex flex-col gap-[40px]">
                    <div className="flex flex-col gap-[4px]">
                        <p className="font-bold text-[19px]">Edit User</p>
                        <p className="text-[14px]">{`${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2}`}</p>
                    </div>
                    <TextField label="First Name" required={true} value={firstName ? firstName : ''} onChange={(e)=>{setFirstName(e.target.value)}}/>
                    <TextField label="Last Name" required={true} value={lastName  ? lastName : ''} onChange={(e)=>{setLastName(e.target.value)}}/>
                    <CountrySelector options={countries} selectedOption={(e)=>{setCountry(e.optionShortName)}} initialCountry={country.cty_name ? ucFirstAll(country.cty_name) : ''} label="Country" searchable={true} inputName="country"/>
                    <TextField label="City" required={true} value={city  ? city : ''} onChange={(e)=>{setCity(e.target.value)}}/>
                    <TextField label="Region/State/Province" required={true} value={province  ? province : ''} onChange={(e)=>{setProvince(e.target.value)}}/>
                    <TextField label="Address" required={true} value={address  ? address : ''} onChange={(e)=>{setAddress(e.target.value)}}/>
                    <TextField label="Postcode" required={true} value={postCode  ? postCode : ''} onChange={(e)=>{
                        if (isNaN(Number(e.target.value))) return
                        setPostCode(e.target.value)
                    }}/>
                    <TextField label="Mobile" value={mobile ? mobile : ''} onChange={(e)=>{
                        if (isNaN(Number(e.target.value))) return
                        setMobile(e.target.value)
                    }}/>
                    <TextField label="Phone" value={phone ? phone : ''} onChange={(e)=>{
                        if (isNaN(Number(e.target.value))) return
                        setPhone(e.target.value)
                    }}/>
                    <EmailField label="Email" required={true} value={email ? email : ''} onChange={(e)=>{setEmail(e.target.value)}}/>
                    <Button size="lg" variant="primary" onClick={updteUserDets} disabled={updating}>{!updating ? 'Update User' : <LoadingSpinner variant={"primary"}/>}</Button>
                </div>
            }
            {stage === 2 &&
                <>
                    <div className="resp-body">
                        <div className="flex justify-center items-center flex-1">
                            {Object.keys(updtResp).length > 0 && updtResp.Status === 'SUCC' &&
                                <ActionSuccess headingText="User Updated" subText={`${userDets.data[0].usr_name1} ${userDets.data[0].usr_name2} was updated successfully`}/>
                            }
                            {Object.keys(updtResp).length > 0 && updtResp.Status === 'FAIL' &&
                                <ActionFailed headingText="An Error Occurred" subText={updtResp.Message}/>
                            }
                        </div>
                        <Button size="lg" variant="primary" onClick={()=>{
                            window.location.reload()
                        }}>Sounds Good</Button>
                    </div>
                </>
            }
        </>
    )
}
