import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import DashboardBaseLayout from "../components/DashboardBaseLayout";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import EditPaymentMethods from "../components/PaymentMethodsContent/EditPaymentMethods";
import dayjs from "dayjs";
import AddPaymentMethod from "../components/PaymentMethodsContent/AddPaymentMethod";
import UserContext from "../utils/userContext";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner, PaymentMethodsTable} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const PaymentMethods = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.payment_methods')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [loading,setLoading] = useState(true)
    const [paymentMethods,setPaymentMethods]:any = useState([])
    const [paymentMethodsOri,setPaymentMethodsOri]:any = useState([])
    const [showEditPnl,setShowEditPnl] = useState(false)
    const [pymtModeDets,setPymtModeDets]:any = useState({})
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [logs,setLogs]:any = useState([])
    const [showLogs,setShowLogs] = useState(false)
    const [showAddPnl,setShowAddPnl] = useState(false)
    const [hasAccess,setHasAccess] = useState(true)

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadPymtMthds()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function loadPymtMthds(){
        axiosSend({
            url: '/fx/admin/payment-modes',
            method: 'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((method:any)=>{
                    tmp.push({
                        pymtModeCode:method.pym_code,
                        pymtModeName:method.pym_desc,
                        pymtModeShortName:method.pym_sdesc,
                        remarks:method.pym_upd_rem
                    })
                })
                setPaymentMethods(tmp)
                setPaymentMethodsOri(tmp)

                //populate logs
                let tmpLogs:any = []
                res.data.logs.forEach((log:any)=>{
                    tmpLogs.push({
                        timestamp:dayjs(log.aud_time).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id
                    })
                })
                setLogs(tmpLogs)
                setLoading(false)
            }
        })
    }

    function getPaymentMode(pymtCode:string){
        axiosSend({
            url:`/fx/admin/payment-modes/${pymtCode}`,
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                setPymtModeDets(res.data.data[0])
                setShowEditPnl(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function searchPymtMethod(qry:string){
        setSrchQry(qry)
        setPaymentMethods(paymentMethodsOri.filter((method:any)=>method.pymtModeName.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="payment-methods">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.payment_methods')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setShowAddPnl(true)}}>{t('common:payment_methods.add_payment_method')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:payment_methods.search_payment_method')} onChange={(e)=>{searchPymtMethod(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <PaymentMethodsTable methods={paymentMethods} callback={(e)=>{getPaymentMode(e.pymtModeCode)}} labelOverrides={{
                    code:t('common:general.code'),
                    remarks:t('common:general.remarks'),
                    name:t('common:general.name'),
                    emptyMsg:t('common:payment_methods.empty_msg'),
                    shortName:t('common:payment_methods.short_name')
                }}/>
            }
            {showEditPnl &&
                <SidePanel closeFxn={()=>{setShowEditPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:payment_methods.edit_payment_method') : ''} subtext={pymtModeDets.pym_desc}>
                    <EditPaymentMethods paymentMethodDets={pymtModeDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={true}/>} title={t('common:general.activity_logs')}>
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {showAddPnl &&
                <SidePanel closeFxn={()=>{setShowAddPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:payment_methods.add_payment_method') : ''}>
                    <AddPaymentMethod showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default PaymentMethods