import styled from "styled-components";
import theme from "../../theme";

export const Backdrop = styled.div`
  background: rgba(7, 11, 18, 0.5);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990;
  animation: fade 0.1s ease-in-out;
  @keyframes fade{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
`

export const ModalCard = styled.div`
  min-width: 1163px;
  width: 1500px;
  border-radius: 16px;
  padding: 16px;
  background:${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap:20px;
  animation: grow 0.1s ease-in-out;
  @keyframes grow{
    from{
      transform:scale(0.9);
    }
    to{
      transform:scale(1);
    }
  }
  .card-content{
    max-height: 80vh;
    overflow: auto;
  }
`

export const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 0 8px;
  h3{
    color:${theme.colors["neutral-900"]};
    font-weight: 700;
    font-size: 23px;
    letter-spacing: -0.02em;
    line-height: 28px;
    margin: 0;
  }
  button{
    background: transparent;
    border: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`

export const CardBody = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: ${theme.colors["neutral-900"]};
  flex-grow: 1;
  p{
    margin: 0;
  }
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap:8px;
`