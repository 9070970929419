import React, {useEffect, useState} from "react"
import {
    Container,
    DropDown,
    HeaderSection,
    Heading,
    OffersContainer,
    NoDataContent,
    LoadingContent,
    Offers, OfferInfo, OfferItem, ProviderInfo, ProviderNameDelivery, RateInfo
} from "./style";
import {OffersProps} from "./type";
import {moneyfy} from "../../helpers";
import {ButtonOutline, DropdownList, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const SecuritiesOffers = (props: OffersProps) => {
    const [sortBy, setSortBy]: any = useState('')
    const {offers, loading, selectedOffer} = props
    const [dataAvailable,setDataAvailable] = useState(false)
    const [initialLoad,setInitialLoad] = useState(true)
    const [offersData,setOffersData]:any = useState([])
    const {t} = useTranslation('common')

    const options = [
        {
            label: t('common:request_lp.best_rates'),
            value: 'Best rates'
        },
        {
            label: t('common:request_lp.recommended'),
            value: 'Recommended'
        }
    ]

    useEffect(()=>{
        setOffersData(offers)
        if (!initialLoad){
            setDataAvailable(true)
        }
        setInitialLoad(false)
    },[initialLoad, offers])

    useEffect(()=>{
        if (sortBy){
            if (sortBy === 'Best rates'){
                setOffersData(offers.sort((a:any,b:any) => a.rate - b.rate))
            }
            if (sortBy === 'Recommended'){
                setOffersData(offers.sort((a:any,b:any) => b.rate - a.rate))
            }
        }
    },[sortBy])

    return (
        <Container>
            <HeaderSection>
                <Heading>
                    <h3>{t('common:request_lp.available_offers')}</h3>
                    <p>{t('common:request_lp.all_offers_found')}</p>
                </Heading>
                <DropDown>
                    <DropdownList options={options} returnedSelection={(sel) => {
                        setSortBy(sel.value)
                    }} initialText="Sort by" disabled={offers.length === 0 || loading} inputName="sortBy"/>
                </DropDown>
            </HeaderSection>
            <OffersContainer>
                {loading &&
                    <LoadingContent>
                        <LoadingSpinner variant={"primary"}/>
                    </LoadingContent>
                }
                {!loading &&
                    <>
                        {!dataAvailable &&
                            <NoDataContent>
                                <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1336_5694)">
                                        <path d="M39.5 6.5H25.5V14.5H39.5V6.5Z" fill="#E8EEF7"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M41.5012 6.5C41.5012 5.395 40.6052 4.5 39.5006 4.5H25.5006C24.396 4.5 23.5 5.395 23.5 6.5V14.5C23.5 15.605 24.396 16.5 25.5006 16.5H39.5006C40.6052 16.5 41.5012 15.605 41.5012 14.5V6.5ZM27.5012 8.5V12.5H37.5V8.5H27.5012Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M49.5 56.5C49.5 55.396 50.396 54.5 51.5 54.5C52.604 54.5 53.5 55.396 53.5 56.5V58.5C53.5 59.605 52.605 60.5 51.5 60.5H13.5C12.395 60.5 11.5 59.605 11.5 58.5V10.5C11.5 9.395 12.395 8.5 13.5 8.5H25.5C26.605 8.5 27.5 9.395 27.5 10.5V12.5H37.5V10.5C37.5 9.395 38.395 8.5 39.5 8.5H51.5C52.605 8.5 53.5 9.395 53.5 10.5V48.5C53.5 49.604 52.604 50.5 51.5 50.5C50.396 50.5 49.5 49.604 49.5 48.5V12.5H41.5V14.5C41.5 15.605 40.605 16.5 39.5 16.5H25.5C24.395 16.5 23.5 15.605 23.5 14.5V12.5H15.5V56.5H49.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 27.5H41.5C42.604 27.5 43.5 26.604 43.5 25.5C43.5 24.396 42.604 23.5 41.5 23.5H23.5C22.396 23.5 21.5 24.396 21.5 25.5C21.5 26.604 22.396 27.5 23.5 27.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 38.5H41.5C42.604 38.5 43.5 37.604 43.5 36.5C43.5 35.396 42.604 34.5 41.5 34.5H23.5C22.396 34.5 21.5 35.396 21.5 36.5C21.5 37.604 22.396 38.5 23.5 38.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 49.5H41.5C42.604 49.5 43.5 48.604 43.5 47.5C43.5 46.396 42.604 45.5 41.5 45.5H23.5C22.396 45.5 21.5 46.396 21.5 47.5C21.5 48.604 22.396 49.5 23.5 49.5Z"
                                              fill="#D2DDEE"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1336_5694">
                                            <rect width="64" height="64" fill="white" transform="translate(0.5 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                {t('common:request_lp.offers_displayed_here')}
                            </NoDataContent>
                        }
                        {offers && offers.length > 0 &&
                            <Offers>
                                {offersData.map((offer: {
                                    providerName:string,
                                    providerLogo?:string,
                                    deliveryTime:string,
                                    baseCurrency:string,
                                    exchangeCurrency:string,
                                    exchangeAmount:number,
                                    baseAmount:number,
                                    recommended:boolean,
                                    providerId:string,
                                    rate:number
                                }, index: number) => (
                                    <OfferItem key={index}>
                                        {offer.recommended && <p className="recommended">{t('common:request_lp.recommended')}</p>}
                                        <OfferInfo>
                                            <ProviderInfo>
                                                {offer.providerLogo
                                                    ?
                                                    <img src={offer.providerLogo} alt={t('common:request_lp.provider_logo')}/>
                                                    :
                                                <div className="w-[40px] h-[40px] bg-neutral-100 rounded-[8px]"/>
                                                }
                                                <ProviderNameDelivery>
                                                    <h4>{offer.providerName}</h4>
                                                    <p>{t('common:request_lp.delivery_between')}</p>
                                                    {/*<p>Delivery by {offer.deliveryTime}</p>*/}
                                                </ProviderNameDelivery>
                                            </ProviderInfo>
                                            <RateInfo>
                                                <h4>{moneyfy(offer.baseAmount,offer.baseCurrency)}</h4>
                                                <p>{offer.baseCurrency} 1.00 = {offer.exchangeCurrency} {offer.rate}</p>
                                            </RateInfo>
                                        </OfferInfo>
                                        <div className="offer-btn">
                                            <ButtonOutline size="md" variant="primary" width="fit-content" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height:'100%'
                                            }} onClick={() => {
                                                selectedOffer(offer)
                                            }}>
                                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M14.2085 0.709861C14.1155 0.616132 14.0049 0.541738 13.8831 0.490969C13.7612 0.440201 13.6305 0.414062 13.4985 0.414062C13.3665 0.414062 13.2358 0.440201 13.1139 0.490969C12.992 0.541738 12.8814 0.616132 12.7885 0.709861L5.33849 8.16986L2.20849 5.02986C2.11196 4.93662 1.99802 4.86331 1.87317 4.8141C1.74831 4.7649 1.61499 4.74077 1.48081 4.74309C1.34663 4.74541 1.21422 4.77414 1.09114 4.82763C0.96806 4.88113 0.856723 4.95834 0.763485 5.05486C0.670246 5.15138 0.596932 5.26532 0.547728 5.39018C0.498524 5.51503 0.474394 5.64836 0.476715 5.78254C0.479037 5.91672 0.507765 6.04913 0.561258 6.17221C0.614752 6.29529 0.691963 6.40662 0.788485 6.49986L4.62849 10.3399C4.72145 10.4336 4.83205 10.508 4.95391 10.5588C5.07577 10.6095 5.20647 10.6357 5.33849 10.6357C5.4705 10.6357 5.6012 10.6095 5.72306 10.5588C5.84492 10.508 5.95552 10.4336 6.04849 10.3399L14.2085 2.17986C14.31 2.08622 14.391 1.97257 14.4464 1.84607C14.5018 1.71957 14.5304 1.58296 14.5304 1.44486C14.5304 1.30676 14.5018 1.17015 14.4464 1.04365C14.391 0.917155 14.31 0.803503 14.2085 0.709861V0.709861Z"
                                                        fill="#4040F2"/>
                                                </svg>
                                            </ButtonOutline>
                                        </div>
                                    </OfferItem>
                                ))}
                            </Offers>
                        }
                        {dataAvailable && offers && offers.length === 0 &&
                            <NoDataContent>
                                <svg width="65" height="65" viewBox="0 0 65 65" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1336_5694)">
                                        <path d="M39.5 6.5H25.5V14.5H39.5V6.5Z" fill="#E8EEF7"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M41.5012 6.5C41.5012 5.395 40.6052 4.5 39.5006 4.5H25.5006C24.396 4.5 23.5 5.395 23.5 6.5V14.5C23.5 15.605 24.396 16.5 25.5006 16.5H39.5006C40.6052 16.5 41.5012 15.605 41.5012 14.5V6.5ZM27.5012 8.5V12.5H37.5V8.5H27.5012Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M49.5 56.5C49.5 55.396 50.396 54.5 51.5 54.5C52.604 54.5 53.5 55.396 53.5 56.5V58.5C53.5 59.605 52.605 60.5 51.5 60.5H13.5C12.395 60.5 11.5 59.605 11.5 58.5V10.5C11.5 9.395 12.395 8.5 13.5 8.5H25.5C26.605 8.5 27.5 9.395 27.5 10.5V12.5H37.5V10.5C37.5 9.395 38.395 8.5 39.5 8.5H51.5C52.605 8.5 53.5 9.395 53.5 10.5V48.5C53.5 49.604 52.604 50.5 51.5 50.5C50.396 50.5 49.5 49.604 49.5 48.5V12.5H41.5V14.5C41.5 15.605 40.605 16.5 39.5 16.5H25.5C24.395 16.5 23.5 15.605 23.5 14.5V12.5H15.5V56.5H49.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 27.5H41.5C42.604 27.5 43.5 26.604 43.5 25.5C43.5 24.396 42.604 23.5 41.5 23.5H23.5C22.396 23.5 21.5 24.396 21.5 25.5C21.5 26.604 22.396 27.5 23.5 27.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 38.5H41.5C42.604 38.5 43.5 37.604 43.5 36.5C43.5 35.396 42.604 34.5 41.5 34.5H23.5C22.396 34.5 21.5 35.396 21.5 36.5C21.5 37.604 22.396 38.5 23.5 38.5Z"
                                              fill="#D2DDEE"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M23.5 49.5H41.5C42.604 49.5 43.5 48.604 43.5 47.5C43.5 46.396 42.604 45.5 41.5 45.5H23.5C22.396 45.5 21.5 46.396 21.5 47.5C21.5 48.604 22.396 49.5 23.5 49.5Z"
                                              fill="#D2DDEE"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1336_5694">
                                            <rect width="64" height="64" fill="white" transform="translate(0.5 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                {t('common:request_lp.no_provs_found')}
                            </NoDataContent>
                        }
                    </>
                }
            </OffersContainer>
        </Container>
    )
}

export default SecuritiesOffers
