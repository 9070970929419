import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    DropdownList,
    LoadingSpinner,
    TextField,
    UpdateUserActions
} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface EditRoleProps{
    roleDets:any,
    stepFxn:(a:number)=>void
    step:number
}

const EditUserRole = (props:EditRoleProps) => {
    const {roleDets,stepFxn,step} = props
    const [stage,setStage] = useState(step)
    const [name,setName] = useState(roleDets.data[0].role_name)
    const [code,setCode] = useState(roleDets.data[0].role_code)
    const [clearance,setClearance] = useState(roleDets.data[0].role_clear_level_code)
    const [errMsg,setErrMsg] = useState('')
    const [roleActions,setRoleActions]:any = useState([])
    const [roleForms,setRoleForms]:any = useState([])
    const [roleApps,setRoleApps]:any = useState([])
    const [selRoleActions,setSelRoleActions]:any = useState({})
    const [selRoleForms,setSelRoleForms]:any = useState({})
    const [selRoleApps,setSelRoleApps]:any = useState({})
    const [currRoleActions,setCurrRoleActions]:any = useState([])
    const [currRoleForms,setCurrRoleForms]:any = useState([])
    const [currRoleApps,setCurrRoleApps]:any = useState([])
    const [loading,setLoading] = useState(false)
    const [resp,setResp]:any = useState({})
    const {t} = useTranslation('common')

    useEffect(()=>{
        //role's current actions
        if (roleDets.actions && roleDets.actions.length > 0){
            let tmp:any = []
            roleDets.actions.forEach((action:any)=>{
                tmp.push({
                    optionId:action.action_code,
                    optionName:action.action_title
                })
            })
            setCurrRoleActions(tmp)
        }

        //role's current forms
        if (roleDets.forms && roleDets.forms.length > 0){
            let tmp:any = []
            roleDets.forms.forEach((form:any)=>{
                tmp.push({
                    optionId:form.form_code,
                    optionName:form.af_form_name
                })
            })
            setCurrRoleForms(tmp)
        }

        //role's current apps
        if (roleDets.apps && roleDets.apps.length > 0){
            let tmp:any = []
            roleDets.apps.forEach((form:any)=>{
                tmp.push({
                    optionId:form.app_code,
                    optionName:form.app_name
                })
            })
            setCurrRoleApps(tmp)
        }
    },[])

    useEffect(()=>{
        axiosSend({
            url:'/fx/admin/app-actions',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.aa_action_title,
                        optionId:action.aa_action_code
                    })
                })
                setRoleActions(tmp)
            }
        })
        axiosSend({
            url:'/fx/admin/app-forms',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.af_form_name,
                        optionId:action.af_form_code
                    })
                })
                setRoleForms(tmp)
            }
        })
        axiosSend({
            url:'/fx/admin/apps',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((action:any)=>{
                    tmp.push({
                        optionName:action.app_name,
                        optionId:action.app_code
                    })
                })
                setRoleApps(tmp)
            }
        })
    },[])

    useEffect(()=>{
        setStage(step)
    },[step])

    const dropdownOptions = [
        {
            label:'1',
            value:'1'
        },
        {
            label:'2',
            value:'2'
        },
        {
            label:'3',
            value:'3'
        },
        {
            label:'4',
            value:'4'
        },
        {
            label:'5',
            value:'5'
        }
    ]

    function validateForm(){
        if (!name || !code || !clearance){
            setErrMsg(t('common:general.some_required_fields_empty'))
            return null
        } else{
            setErrMsg('')
            stepFxn(stage + 1)
        }
        if (stage === 4){
            submitForm()
        }
    }

    function submitForm(){
        setLoading(true)
        let formData = {
            roleCode:code,
            roleName:name,
            roleClrLvl:clearance,
            addRoleActions:selRoleActions.added,
            addRoleForms:selRoleForms.added,
            addRoleApps:selRoleApps.added,
            remRoleActions:selRoleActions.removed,
            remRoleForms:selRoleForms.removed,
            remRoleApps:selRoleApps.removed,
            action:'codeMaintenance'
        }

        axiosSend({
            url:`/fx/admin/roles/${roleDets.data[0].role_code}`,
            method:'PUT',
            data:formData
        }).then(res => {
            setResp(res.data)
            setLoading(false)
            stepFxn(5)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    validateForm()
                }} className="flex flex-col flex-1 gap-[40px]">
                    <div className="flex flex-col flex-1 gap-[40px]">
                        <TextField label={t('common:user_roles.role_name')} value={name} onChange={(e)=>{setName(e.target.value)}} required={true}/>
                        <TextField label={t('common:user_roles.role_code')} value={code} onChange={(e)=>{setCode(e.target.value)}} required={true}/>
                        <DropdownList label={t('common:user_roles.clearance_level')} options={dropdownOptions} returnedSelection={(e)=>{setClearance(e.value)}} initialText={clearance} inputName="clearanceLevel"/>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="submit" size="lg" variant="primary">{t('common:general.confirm_proceed')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleActions} currentOptions={currRoleActions} selOptions={(e)=>{setSelRoleActions(e)}} titleAvailable={t('common:user_roles.available_actions')} titleCurrent={t('common:user_roles.current_actions')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}}>{t('common:general.confirm_proceed')}</Button>
                    </div>
                </div>
            }
            {stage === 3 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleForms} currentOptions={currRoleForms} selOptions={(e)=>{setSelRoleForms(e)}} titleAvailable={t('common:user_roles.available_forms')} titleCurrent={t('common:user_roles.current_forms')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}}>{t('common:general.confirm_proceed')}</Button>
                    </div>
                </div>
            }
            {stage === 4 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <UpdateUserActions availableOptions={roleApps} currentOptions={currRoleApps} selOptions={(e)=>{setSelRoleApps(e)}} titleAvailable={t('common:user_roles.available_apps')} titleCurrent={t('common:user_roles.current_apps')}/>
                    <div className="flex flex-col gap-[4px]">
                        {errMsg && <p className="text-error-500 text-[14px]"><i className="icon-exclamation-triangle text-error-500"/> {errMsg}</p>}
                        <Button type="button" size="lg" variant="primary" onClick={()=>{validateForm()}} disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:user_roles.confirm_update_role')}</Button>
                    </div>
                </div>
            }
            {stage === 5 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:user_roles.role_updated',{roleName:roleDets.data[0].role_name})} subText={t('common:user_roles.role_updated_msg',{roleName:roleDets.data[0].role_name})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if(resp.Status === 'SUCC'){
                            window.location.reload()
                        }else{
                            setStage(stage - 1)
                        }
                    }}>{t('common:general.sounds_good')}</Button>
                </div>
            }
        </>
    )
}

export default EditUserRole