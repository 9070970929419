import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useState} from "react";
import dayjs from "dayjs";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import {exportExcel, exportPDF, moneyfy} from "../../helpers";
import UserContext from "../../utils/userContext";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, LoadingSpinner, TextField, TableAlternative} from "@secondstaxorg/sscomp";
import TradingHistoryTable from "../../components/TradingHistoryTable/TradingHistoryTable";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";

const TrxListByClientId = () => {
    const [clientId,setClientId] = useState('')
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [trxList,setTrxList]:any[] = useState([])
    const [loading,setLoading] = useState(false)
    const [emptyData,setEmptyData] = useState(false)
    const [errMsg,setErrMsg] = useState('')
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const [reportPeriod,setReportPeriod] = useState('')
    const userDets:any = useContext(UserContext)
    const {t} = useTranslation('common')

    function getTransactions(){
        if (!clientId || !startDate || !endDate){
            setErrMsg(t('common:general.all_fields_required'))
            return
        }else{
            setErrMsg('')
        }

        setLoading(true)
        axiosSend({
            url:`/fx/reports/client-trx?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`,
            method:'GET'
        }).then(res => {
            if (res.data.data){
                let tmp:any = []
                res.data.data.forEach((item:any)=>{
                    tmp.push({
                        providerLogo:item.LP_logos ? item.LP_logos[24] : '',
                        providerName:item.lip_name,
                        broker:item.org_name,
                        baseCurrFlag:item.base_curr_flag,
                        baseAmount:item.ptr_source_amount,
                        baseCurrency:item.ptr_source_cur_code,
                        exchangeCurrFlag:item.exch_curr_flag,
                        exchangeAmount:item.ptr_dest_amount,
                        exchangeCurrency:item.ptr_dest_cur_code,
                        transactionId:item.ptr_ref_no,
                        status:item.trans_status_name,
                        date:dayjs(item.ptr_trans_date).format('MMM DD YYYY, HH:mm'),
                        clientId:item.client_id,
                    })
                })
                let srtTmp = tmp.sort((a:any,b:any)=>(b.transactionId - a.transactionId))
                setTrxList(srtTmp)
                setReportPeriod(dayjs(startDate).format('MMM D YYYY')+' - '+dayjs(endDate).format('MMM D YYYY'))
                setLoading(false)
                setEmptyData(false)
            }else{
                setTrxList([])
                setLoading(false)
                setEmptyData(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        trxList.forEach((trx:any) => {
            formattedData.push({
                'Provider':trx.providerName,
                'Client ID':trx.clientId,
                'Base Currency':trx.baseCurrency,
                'Base Amount':Number(trx.baseAmount),
                'Exchange Currency':trx.exchangeCurrency,
                'Exchange Amount':Number(trx.exchangeAmount),
                'Transaction ID': trx.transactionId,
                'Status': trx.status,
                'Date':trx.date
            })
        })
        exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,t('common:reports.client_id_trx_list'))
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal && <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,t('common:reports.client_id_trx_list'))
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
                <div id="reportPreview">
                    <TableAlternative thead={[t('common:provider_rates.provider'),t('common:general.broker'),t('common:general.client_id'),t('common:request_lp.base_amount'),t('common:general.exchange_amount'),t('common:reports.transaction_id'),t('common:general.status'),t('common:general.date')]}>
                        {trxList.map((trx:any,index:number)=>(
                            <tr key={index}>
                                <td>{trx.providerName}</td>
                                <td>{trx.broker}</td>
                                <td>{trx.clientId}</td>
                                <td>{moneyfy(trx.baseAmount,trx.baseCurrency)}</td>
                                <td>{moneyfy(trx.exchangeAmount,trx.exchangeCurrency)}</td>
                                <td>{trx.transactionId}</td>
                                <td>{trx.status}</td>
                                <td>{trx.date}</td>
                            </tr>
                        ))}
                    </TableAlternative>
                </div>
            </ExportPreviewModal>}
            <div className="mb-[16px]">
                <h6 className={typography.heading6Bold}>{t('common:reports.client_id_trx_list')}</h6>
            </div>
            <div className="mb-[16px]">
                <div className="flex gap-[16px] justify-between">
                    <form className="w-8/12" onSubmit={(e)=>{
                        e.preventDefault()
                        getTransactions()
                    }}>
                        <div className="flex gap-[8px]">
                            <div className="w-10/12">
                                <TextField type="text" value={clientId} onChange={(e)=>{setClientId(e.target.value)}} placeholder={t('common:general.client_id')} required={true}/>
                            </div>
                            <div className="w-10/12">
                                <DateRangePicker selectedDateRange={(a)=>{
                                    setStartDate(dayjs(a.startDate).format('YYYY-MM-DD'))
                                    if (a.endDate) setEndDate(dayjs(a.endDate).format('YYYY-MM-DD'))
                                }} placeholder={t('common:reports.pick_date_range')}/>
                            </div>
                            <div className="w-5/12">
                                <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>
                            </div>
                        </div>
                    </form>
                    <div className="w-[200px]">
                        <ButtonOutline size="lg" variant="primary" type="button" disabled={trxList.length === 0 || loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                    </div>
                </div>
                {errMsg && <p className="mt-[8px] text-error-500">{errMsg}</p>}
            </div>
            {!loading &&
                <>
                    {trxList.length === 0 && !emptyData &&
                        <EmptyState height={300} message={t('common:reports.make_selection_msg')}/>
                    }
                    {trxList.length === 0 && emptyData &&
                        <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                    }
                    {trxList.length > 0 &&
                        <div>
                            <TradingHistoryTable transactions={trxList} loadingState={false}/>
                        </div>
                    }
                </>
            }
            {loading &&
                <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant={"primary"}/></div>
            }
        </DashboardBaseLayout>
    )
}

export default TrxListByClientId