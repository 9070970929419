import {useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import {useNavigate} from "react-router-dom";
import {ActionFailed, ActionSuccess, Button, LoadingSpinner, TextArea} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

interface CancelTxnProps{
    txnNo:string,
    setBackBtnDisabled:(a:boolean)=>void
}

const CancelTransaction = (props:CancelTxnProps) => {
    const {txnNo,setBackBtnDisabled} = props

    const [canceling,setCanceling] = useState(false)
    const [remarks,setRemarks] = useState('')
    const [stage,setStage] = useState(1)
    const [cancRes,setCancRes]:any = useState({})
    const navigate = useNavigate()
    const {t} = useTranslation('common')

    useEffect(()=>{
        if (stage === 2) setBackBtnDisabled(true)
    },[stage])

    function cancelTxn(){
        setCanceling(true)
        let formData = {
            action:"savCanTrnx",
            tranxNo:txnNo,
            updRem:remarks
        }

        axiosSend({
            url:'/fx/save-actions-symbol',
            method:"POST",
            data:formData
        }).then(res => {
            setCancRes(res.data)
            setCanceling(false)
            setStage(stage + 1)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            {stage === 1 &&
                <div className="flex flex-col flex-1 gap-[40px]">
                    <div className="flex flex-col flex-1">
                        <div className="mb-[40px]">
                            <h6 className="text-neutral-900 font-bold text-[19px]">{t('common:transaction_history.cancel_transaction')}</h6>
                            <p className="text-neutral-900 text-[14px]">{t('common:transaction_history.youre_about_to_cancel_transaction',{txnNo:txnNo})}.</p>
                        </div>
                        <div className="mb-[24px]">
                            <TextArea placeholder={t('common:general.enter_remarks')} label={t('common:general.remarks')} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} style={{height:133}} required/>
                        </div>
                    </div>
                    <Button size="lg" variant="error" disabled={canceling || !remarks} onClick={()=>{cancelTxn()}}>{canceling ? <LoadingSpinner variant={"primary"}/> : t('common:transaction_history.cancel_transaction')}</Button>
                </div>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    {cancRes.Status === 'SUCC' &&
                        <>
                            <div className="flex flex-1 justify-center items-center">
                                <ActionSuccess headingText={t('common:transaction_history.transaction_canceled')} subText={t('common:transaction_history.transaction_canceled_msg',{txnNo:txnNo})}/>
                            </div>
                            <button className="primary-button-lg w-full" onClick={()=>{navigate(0)}}>{t('common:general.sounds_good')}</button>
                        </>
                    }
                    {cancRes.Status === 'FAIL' &&
                        <>
                            <div className="flex flex-1 justify-center items-center">
                                <ActionFailed headingText={cancRes.Message}/>
                            </div>
                            <button className="primary-button-lg w-full" onClick={()=>{setStage(stage - 1)}}>Try Again</button>
                        </>
                    }

                </div>
            }
        </>
    )
}

export default CancelTransaction
