import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import EditCurrPairs from "../components/CurrencyPairsContent/EditCurrPairs";
import dayjs from "dayjs";
import AddCurrPair from "../components/CurrencyPairsContent/AddCurrPair";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import UserContext from "../utils/userContext";
import {appName, ucFirstAll} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import CurrencyPairsTable from "../components/CurrencyPairs/CurrencyPairsTable";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const CurrencyPairs = () => {
    const {t,i18n} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.currency_pairs')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [loading,setLoading] = useState(true)
    const [currPairs,setCurrPairs]:any = useState([])
    const [currPairsOri,setCurrPairsOri]:any = useState([])
    const [showEditPnl,setShowEditPnl] = useState(false)
    const [currDets,setCurrDets]:any = useState({})
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [showLogs,setShowLogs] = useState(false)
    const [logs,setLogs]:any = useState([])
    const [showAddPnl,setShowAddPnl] = useState(false)
    const [hasAccess,setHasAccess] = useState(true)

    function loadCurrPairs(){
        axiosSend({
            url:'/fx/admin/currency-pairs',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((curr:any)=>{
                    tmp.push({
                        pairCode:curr.cp_pair_code,
                        baseCurrency:ucFirstAll(curr.cp_base_cur_name),
                        baseFlag:curr.base_curr_flag_name,
                        exchangeCurrency:ucFirstAll(curr.cp_exch_cur_name),
                        exchangeFlag:curr.exch_curr_flag_name,
                        pairName:curr.cp_pair_name
                    })
                })
                setCurrPairs(tmp)
                setCurrPairsOri(tmp)
            }

            if (res.data.logs && res.data.logs.length > 0){
                let tmp:any = []
                res.data.logs.forEach((log:any)=>{
                    tmp.push({
                        timestamp:dayjs(log.aud_time).locale(i18n.language).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id,
                    })
                })
                setLogs(tmp)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadCurrPairs()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function getCurrPair(pairCode:string){
        axiosSend({
            url:`/fx/admin/currency-pairs/${pairCode.replace('/','%2F')}`,
            method:"GET"
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                setCurrDets(res.data.data[0])
            }
            setShowEditPnl(true)
        })
    }

    function srchCurr(qry:string){
        setSrchQry(qry)
        setCurrPairs(currPairsOri.filter((curr:any) => curr.pairCode.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="currency-pairs">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.currency_pairs')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setShowAddPnl(true)}}>{t('common:currency_pairs.add_currency_pair')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:currency_pairs.search_currency_pair')} onChange={(e)=>{srchCurr(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <CurrencyPairsTable pairs={currPairs} callback={(e)=>{getCurrPair(e.pairCode)}}/>
            }
            {showEditPnl &&
                <SidePanel closeFxn={()=>{setShowEditPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? 'Edit Currency Pair' : ''}>
                    <EditCurrPairs currPairDets={currDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={true}/>} title="Activity Logs">
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {showAddPnl &&
                <SidePanel closeFxn={()=>{setShowAddPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? 'Add Currency Pair' : ''}>
                    <AddCurrPair showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default CurrencyPairs