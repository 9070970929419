import DashboardBaseLayout from "../../components/DashboardBaseLayout";
import React, {useContext, useState} from "react";
import {axiosSend} from "../../utils/request";
import EmptyState from "../../components/EmptyState";
import {exportExcel, exportPDF} from "../../helpers";
import dayjs from "dayjs";
import UnauthorisedAccess from "../../components/UnauthorisedAccess";
import UserContext from "../../utils/userContext";
import ExportPreviewModal from "../../components/ExportPreviewModal/ExportPreviewModal";
import {Button, ButtonOutline, DateRangePicker, LoadingSpinner, TableAlternative} from "@secondstaxorg/sscomp";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const NumBrokerTransactions = () => {
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [brokers,setBrokers]:any = useState([])
    const [loading,setLoading] = useState(false)
    const [errMsg,setErrMsg] = useState('')
    const [emptyData,setEmptyData] = useState(false)
    const [exportModal,setExportModal] = useState(false)
    const [exportingPdf,setExportingPdf] = useState(false)
    const [reportPeriod,setReportPeriod] = useState('')
    const userDets:any = useContext(UserContext)
    const {t,i18n} = useTranslation('common')

    function getReport(){
        if (!startDate || !endDate){
            setErrMsg(t('common:reports.start_end_date_required'))
            return
        }else{
            setErrMsg('')
        }
        setLoading(true)
        axiosSend({
            url:`/fx/reports/broker-nbr-trx?startDate=${startDate}&endDate=${endDate}`,
            method:'GET'
        }).then(res => {
            if(res.data.data && res.data.data.length > 0){
                let tempBroker:any = []
                res.data.data.forEach((broker:any)=>{
                    tempBroker.push({
                        name:broker.org_name,
                        numTrx:broker.nbr_transactions
                    })
                })
                setBrokers(tempBroker)
                setReportPeriod(dayjs(startDate).locale(i18n.language).format('MMM D YYYY')+' - '+dayjs(endDate).locale(i18n.language).format('MMM D YYYY'))
                setEmptyData(false)
            }else{
                setEmptyData(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    function convertExcelHeaders(){
        let formattedData: {}[] = []
        brokers.forEach((broker:any) => {
            formattedData.push({
                'Broker Name':broker.name,
                'Number of Transactions':Number(broker.numTrx)
            })
        })
        exportExcel(formattedData,reportPeriod,userDets.profile[0].user_name,t('common:reports.num_broker_trx'))
    }

    if (Object.keys(userDets).length > 0 && userDets.profile[0].user_type !== 'SX'){
        return (
            <DashboardBaseLayout pageName="report">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="report">
            {exportModal &&
                <ExportPreviewModal title={t('common:reports.report_preview')}
                                        primaryAction={()=>{
                                            convertExcelHeaders()
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                    primaryBtnText="MS Excel"
                                        secondaryAction={()=>{
                                            exportPDF('l',(a)=>{setExportingPdf(a)},reportPeriod,userDets.profile[0].user_name,t('common:reports.num_broker_trx'))
                                            setTimeout(()=>{
                                                setExportModal(false)
                                            },1000)
                                        }}
                                        secondaryBtnText={exportingPdf ? `${t('common:general.exporting')}...` : 'PDF'}
                                                closeFxn={()=>{setExportModal(false)}}>
                    <div id="reportPreview">
                        <TableAlternative thead={[t('common:reports.broker_name'),t('common:reports.num_trx')]}>
                            {brokers.length > 0 && brokers.map((broker:any,index:number)=> (
                                <tr key={index}>
                                    <td>{broker.name}</td>
                                    <td>{broker.numTrx}</td>
                                </tr>
                            ))}
                        </TableAlternative>
                    </div>
            </ExportPreviewModal>}
            <div className="mb-[16px]">
                <div className="mb-[16px]">
                    <h6 className={typography.heading6Bold}>{t('common:reports.num_broker_trx')}</h6>
                </div>
                <div className="flex gap-[16px] justify-between">
                    <form onSubmit={(e)=>{
                        e.preventDefault()
                        getReport()
                    }} className="grid grid-cols-2 w-8/12 gap-[8px]">
                        <DateRangePicker selectedDateRange={(a)=>{
                            setStartDate(dayjs(a.startDate).locale(i18n.language).format('YYYY-MM-DD'))
                            if (a.endDate) setEndDate(dayjs(a.endDate).locale(i18n.language).format('YYYY-MM-DD'))
                        }} placeholder={t('common:reports.pick_date_range')}/>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"}/> : t('common:reports.generate_report')}</Button>

                    </form>
                    <div className="w-[200px]">
                        <ButtonOutline type="button" size="lg" variant="primary" disabled={brokers.length === 0 && loading} onClick={()=>{setExportModal(true)}}>{t('common:general.export')}</ButtonOutline>
                    </div>
                </div>
                {errMsg && <p className="text-error-500 mt-[8px]">{errMsg}</p>}
            </div>
            <div style={{width:'auto'}}>
                <div className="bg-neutral-900 rounded-[8px] p-[16px] font-medium grid grid-cols-2 mb-[8px]">
                    <p className="text-white">{t('common:reports.broker_name')}</p>
                    <p className="text-white">{t('common:reports.num_trx')}</p>
                </div>
                <div className="flex flex-col gap-[8px]">
                    {!loading &&
                        <>
                            {brokers.length === 0 && !emptyData && //initial state when report is yet to be generated
                                <EmptyState height={300} message={t('common:reports.choose_start_date_end_date')}/>
                            }
                            {brokers.length === 0 && emptyData && //when report is generated but no data is returned
                                <EmptyState height={300} message={t('common:reports.no_data_msg')}/>
                            }
                            {brokers.length > 0 && //when report is generated and data is returned
                                <>
                                    {brokers.length > 0 && brokers.map((broker:any,index:number)=> (
                                        <div key={index} className="bg-white rounded-[8px] px-[16px] py-[8px] font-medium grid grid-cols-2">
                                            <p>{broker.name}</p>
                                            <p>{broker.numTrx}</p>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                    }
                    {loading &&
                        <div className="h-[300px] flex justify-center items-center bg-white rounded-[8px]"><LoadingSpinner variant={"primary"}/></div>
                    }
                </div>
            </div>
        </DashboardBaseLayout>
    )
}

export default NumBrokerTransactions