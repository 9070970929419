import dayjs from "dayjs";
import {axiosSend} from "./utils/request";
import jsPDF from "jspdf";
import {circularBookFont,circularBoldFont} from "./fontstrings";

// export const apiUrl = 'https://lpsvc.dev.secondstax.com'
// export const apiUrl = 'https://lpsvc.uat.secondstax.com'
export const apiUrl = process.env.REACT_APP_API_URL
// export const apiUrl = window.location.hostname.includes('uat') ? 'https://lpsvc.uat.secondstax.com' : window.location.hostname.includes('dev') ? 'https://lpsvc.dev.secondstax.com' : 'http://localhost:2131'

/**
 * Represent any figure to monetary value
 * @param number numeric value
 * @param currency currency in which the value should be displayed
 * */
export function moneyfy(number: number | bigint, currency:string){
    const numberFormat = new Intl.NumberFormat('en-US', {
        style:'currency',
        currency:currency,
        currencyDisplay:'code'
    })

    return numberFormat.format(number)
}

export function formatNumber(number:number){
    return number.toLocaleString(
        'en-US',
        {minimumFractionDigits: 2}
    )
}

/**
 * Set the initial character of a string to uppercase
 * @param str string whose first character should be converted to uppercase
 * */
export function ucFirst(str:string){
    let fc = str.charAt(0).toUpperCase() //convert the first character to uppercase
    let rc = str.slice(1).toLowerCase() //convert the remaining characters to lowercase
    return `${fc}${rc}`
}


export function ucFirstAll(str:string){
    const slc = str.split(' ')
    let phr:any = []
    slc.forEach(sl => {
        let fc = sl.charAt(0).toUpperCase()
        let rc = sl.slice(1).toLowerCase()
        phr.push(`${fc}${rc}`)
    })
    return phr.join(' ')
}

export const appName = 'SSX LP'

export function dayDiff(rateDate:string,t:any){
    //format dates
    const todayFrmt = dayjs(new Date()).format('YYYY M D')
    const newDayFrmt = dayjs(rateDate).format('YYYY M D')

    const today = dayjs(todayFrmt)
    const newDay = dayjs(newDayFrmt)

    if (today.diff(newDay) === 0){
        return t('dashboard.today')+', '
    }

    if (today.diff(newDay) === 86400000){
        return t('dashboard.yesterday')+', '
    }

    if (today.diff(newDay) > 86400000){
        return '' //older than yesterday
    }

    return '' //in safari, today.diff() returns a NaN. In that case, return an empty string
}

export function activeStatus(status:string,t:any){
    if (status.toLowerCase() === 'active') return t('code_maintenance.active')
    if (status.toLowerCase() === 'inactive') return t('code_maintenance.inactive')
}


export const cmPageNames = ['users','brokers','liquidity-providers','user-roles','app-actions','app-forms','app-modules','currency-pairs','payment-methods','system-config']

export function getCountries():Promise<any>{
    let tmp:any = []
    axiosSend({
        url:'/fx/lov/country',
        method:'GET'
    }).then(res => {
        if(res.data.Status === 'SUCC'){
            let cntries = res.data.data.sort((a:any,b:any)=> {
                if (a.cty_name < b.cty_name) return -1
            })
            cntries.forEach((country:any) => {
                tmp.push({
                    optionName:country.cty_name,
                    optionShortName:country.cty_code,
                    optionFlag:country.cty_flag_name
                })
            })
        }
    })
    return tmp
}

export function getCurrFlag(currName:string){
    let flag
    switch (currName.toUpperCase()){
        case 'GHS'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/ghana.svg`
            break
        case 'CAD'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/canada.svg`
            break
        case 'EUR'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/euro.svg`
            break
        case 'XOF'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/franc.svg`
            break
        case 'NGN'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/nigeria.svg`
            break
        case 'KES'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/kenya.svg`
            break
        case 'ZAR'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/sa.svg`
            break
        case 'USD'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/usa.svg`
            break
        case 'GBP'.toUpperCase():
            flag = `${process.env.REACT_APP_FLAGS_URL}/uk.svg`
            break
    }
    return flag
}

/**
 * For exporting report data to pdf
 * @param orientation whether the paper should be in landscape or portrait
 * @param exporting exported boolean function to tell that the export is in progress
 * @param generatedBy name of user that generated the report
 * @param documentTitle title of the document
 * @param period the period over which the report is being generated
 **/
export function exportPDF(orientation:"p" | "l",exporting:(a:boolean)=>void,period:string,generatedBy:string,documentTitle?:string){
    exporting(true)
    const content:HTMLElement = document.querySelector('#reportPreview')!
    const doc = new jsPDF(orientation,"mm","a4")
    doc.setFontSize(13)
    doc.addFileToVFS('Circular Pro Bold-normal.ttf', circularBoldFont)
    doc.addFont('Circular Pro Bold-normal.ttf', 'Circular Pro Bold', 'normal')
    doc.setFont('Circular Pro Bold')
    if (documentTitle) doc.text(documentTitle,10,10)
    doc.setFontSize(10)
    doc.addFileToVFS('Circular Pro Book-normal.ttf', circularBookFont)
    doc.addFont('Circular Pro Book-normal.ttf', 'Circular Pro Book', 'normal')
    doc.setFont('Circular Pro Book')
    doc.text('Generated by '+generatedBy+' on '+dayjs(new Date()).format('MMMM D YYYY @ h:mm a'),10,15)
    doc.setFontSize(10)
    doc.addFileToVFS('Circular Pro Book-normal.ttf', circularBookFont)
    doc.addFont('Circular Pro Book-normal.ttf', 'Circular Pro Book', 'normal')
    doc.setFont('Circular Pro Book')
    doc.text('Period: '+period,10,20)
    doc.addImage('https://res.cloudinary.com/dxivfczqg/image/upload/v1654096631/ssx-logo-dark_hyhacl.png','PNG',240,3,50,0)
    doc.html(content,{
        callback:function (pdfdoc){
            pdfdoc.save(`LP-Report-${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.pdf`)
            exporting(false)
        },
        html2canvas:{
            // scale:0.18,
            scale:0.2,
            letterRendering:true
        },
        x:2,
        y:25
    })
}

/**
 * For exporting report data to excel
 * @param data data to be inserted into the Excel document
 * @param period the period over which the data was generated
 * @param exportedBy name of the logged-in user who generated the report
 * @param documentTitle title of the generated report
 * */
export function exportExcel(data:any[],period:string,exportedBy:string,documentTitle?:string){
    let Excel = require('exceljs')
    let FileSaver = require('file-saver')

    let axios = require('axios')

    let workbook = new Excel.Workbook()
    let worksheet = workbook.addWorksheet('Sheet1')

    let values = data.map(function(obj:any) {
        return Object.values(obj)
    })

    let keys = Object.keys(data[0])
    keys = keys.map(function(key) {
        return key.toUpperCase() //make the header for each column uppercase
    })

    //add empty rows at the top for logo and document information
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])

    //add the object keys as the column headers
    worksheet.addRow(keys)

    //add the object values as the data
    worksheet.addRows(values)

    //set the width of each column to be wide enough for the content in it
    for(let i = 1; i <= keys.length; i++){
        worksheet.getColumn(i).width = 30
    }

    //make the headers bold
    worksheet.getRow(6).font = {bold: true, size:15}

    //merge the cells
    worksheet.mergeCells('A1:E1')
    worksheet.mergeCells('A2:E2')
    worksheet.mergeCells('A3:E3')
    worksheet.mergeCells('A4:E4')
    worksheet.mergeCells('F1:F2')

    if(documentTitle) worksheet.getCell('A1').value = documentTitle
    worksheet.getCell('A1').font = {size:20,bold: true}
    worksheet.getColumn('A').width = 50
    worksheet.getCell('A2').value = dayjs(new Date()).format('MMMM D YYYY @ h:mm a')
    worksheet.getCell('A2').font = {bold:true,size:15}
    worksheet.getCell('A3').value = 'Generated by '+exportedBy
    worksheet.getCell('A3').font = {bold:true,size:15}
    worksheet.getCell('A4').value = 'Period: '+period
    worksheet.getCell('A4').font = {bold:true,size:15}

    //adjust the width of column F to accommodate the logo
    worksheet.getColumn('F').width = 30
    worksheet.getRow(1).height = 45

    //fetch the ssx logo and then added in cell F1
    axios.get('/assets/images/ssx-logo.png', {responseType: 'arraybuffer'}).then(function(response: { data: any }) {
        let imageId = workbook.addImage({
            buffer: response.data,
            extension: 'png',
        })
        worksheet.addImage(imageId, {
            tl: { col: 5, row: 0 }, // top-left anchor to F1
            br: { col: 6, row: 1 }, // bottom-right anchor to F2
        })

        workbook.xlsx.writeBuffer().then(function(buffer: Buffer) {
            let blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            FileSaver.saveAs(blob, `LP-Report-${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.xlsx`)
        })
    }).catch(function(error: any) {
        console.log(error)
    })
}


export function exportExcelMulti(data: any[], period: string, exportedBy: string, documentTitle?: string) {
    const Excel = require('exceljs')
    const FileSaver = require('file-saver')

    const axios = require('axios')

    const workbook = new Excel.Workbook()

    const promises = data.map((entityData: any) => {
        const worksheet = workbook.addWorksheet(entityData.entityName)

        const values = entityData.trxs.map((trx: any) => Object.values(trx))

        const keys = Object.keys(entityData.trxs[0])
        keys.forEach((key: string) => {
            key = key.toUpperCase() // Make each header for each column uppercase
        })

        worksheet.addRow([])
        worksheet.addRow([])
        worksheet.addRow([])
        worksheet.addRow([])
        worksheet.addRow([])

        worksheet.addRow(keys)
        worksheet.addRows(values)

        for (let i = 1; i <= keys.length; i++) {
            worksheet.getColumn(i).width = 30
        }

        worksheet.getRow(6).font = { bold: true, size: 15 }

        worksheet.mergeCells('A1:E1')
        worksheet.mergeCells('A2:E2')
        worksheet.mergeCells('A3:E3')
        worksheet.mergeCells('A4:E4')
        worksheet.mergeCells('F1:F2')

        if (documentTitle) worksheet.getCell('A1').value = documentTitle
        worksheet.getCell('A1').font = { size: 20, bold: true }
        worksheet.getColumn('A').width = 50
        worksheet.getCell('A2').value = dayjs(new Date()).format('MMMM D YYYY @ h:mm a')
        worksheet.getCell('A2').font = { bold: true, size: 15 }
        worksheet.getCell('A3').value = 'Generated by ' + exportedBy
        worksheet.getCell('A3').font = { bold: true, size: 15 }
        worksheet.getCell('A4').value = 'Period: ' + period
        worksheet.getCell('A4').font = { bold: true, size: 15 }

        worksheet.getColumn('F').width = 30
        worksheet.getRow(1).height = 45

        return axios.get('/assets/images/ssx-logo.png', { responseType: 'arraybuffer' })
            .then((response: { data: any }) => {
                const imageId = workbook.addImage({
                    buffer: response.data, // Retrieve image buffer
                    extension: 'png',
                })
                worksheet.addImage(imageId, {
                    tl: { col: 5, row: 0 }, // top-left anchor to F1
                    br: { col: 6, row: 1 }, // bottom-right anchor to F2
                }) // Add image to worksheet
            })
            .catch((error: any) => {
                console.log(error)
            })
    })

    Promise.all(promises)
        .then(() => {
            workbook.xlsx.writeBuffer().then((buffer: Buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, `LP-Report-${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.xlsx`)
            })
        })
}