import React, {useEffect, useState} from "react";
import {LoadingSpinner, LPRequestTable} from "@secondstaxorg/sscomp";

interface TradingHistoryProps{
    transactions:[],
    loadingState:boolean
}

const ReportTrxListTable = (props:TradingHistoryProps) => {
    const {transactions,loadingState} = props
    const [txns,setTxns]:any = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        setTxns(transactions)
        setLoading(loadingState)
    },[transactions,loadingState])

    return (
        <>
            {/*TRADING HISTORY TABLE*/}
            <div className="my-[16px]">
                <div className="mt-[8px] rounded-[8px]">
                    {loading &&
                        <div className="flex justify-center items-center h-[400px] bg-white">
                            <LoadingSpinner variant={"primary"}/>
                        </div>
                    }
                    {!loading &&
                        <>
                            <LPRequestTable transactions={txns} callback={()=>{}} newTransactionFxn={()=>{}} showDetailsArrow={false}/>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default ReportTrxListTable