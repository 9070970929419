import {useEffect, useState} from "react";
import {axiosSend} from "../../utils/request";
import {ucFirstAll} from "../../helpers";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList, EmailField,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";

interface AddUserProps{
    step:number,
    setStep:(a:number)=>void
}

const AddUserContent = (props:AddUserProps) =>{
    const {setStep,step} = props
    const [fName,setFName] = useState('')
    const [lName,setLName] = useState('')
    const [address,setAddress] = useState('')
    const [city,setCity] = useState('')
    const [region,setRegion] = useState('')
    const [country,setCountry] = useState('')
    const [postCode,setPostCode] = useState('')
    const [mobile,setMobile] = useState('')
    const [phone,setPhone] = useState('')
    const [email,setEmail] = useState('')
    const [userRole,setUserRole] = useState('')
    const [org,setOrg] = useState('')
    const [stage,setStage] = useState(1)
    const [organisations,setOrganisations]:any = useState([])
    const [userRoles,setUserRoles]:any = useState([])
    const [processing,setProcessing] = useState(false)
    const [sbmtRes,setSbmtRes]:any = useState({})
    const [countries,setCountries]:any = useState([])

    useEffect(()=>{
        setStage(step)
    },[step])

    useEffect(()=>{
        //load organisations
        axiosSend({
            url:'/fx/admin/org/',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                const tmp:any = []
                res.data.data.forEach((role:any)=>{
                    tmp.push({
                        label:role.org_name1+' '+role.org_name2,
                        value:role.org_code
                    })
                })
                setOrganisations(tmp)
            }
        })

        //load user roles
        axiosSend({
            url:'/fx/admin/roles',
            method:"GET"
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let tmp:any = []
                res.data.data.forEach((role:any)=>{
                    tmp.push({
                        label:role.role_name,
                        value:role.role_code
                    })
                })
                setUserRoles(tmp)
            }
        })

        //get countries
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name){
                        tmp.push({
                            optionName:ucFirstAll(country.cty_name),
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })
    },[])

    function addUser(e:any){
        e.preventDefault()
        setProcessing(true)
        const formBody = {
            usrName1:fName,
            usrName2:lName,
            usrAddress:address,
            usrCountry:country,
            usrAddPostCode:postCode,
            usrAddProvince:region,
            usrAddCity:city,
            usrPhone:phone,
            usrMobile:mobile,
            usrEmail:email,
            usrOrgCode:org,
            usrRoleCode:userRole,
            action:'savUser'
        }

        axiosSend({
            url:'/fx/admin/users',
            method:'POST',
            data:formBody
        })
            .then(res => {
                setSbmtRes(res.data)
                setProcessing(false)
                setStep(2)
        })
            .catch(err => {
                setSbmtRes(err.response.data)
            })
    }

    return (
        <>
            {stage === 1 &&
                <div className="flex flex-col gap-[40px]">
                    <form onSubmit={addUser}>
                        <div className="flex flex-col gap-[40px]">
                            <TextField label="First Name" required={true} placeholder="Enter first name" value={fName} onChange={(e)=>{setFName(e.target.value)}}/>
                            <TextField label="Last Name" required={true} placeholder="Enter last name" value={lName} onChange={(e)=>{setLName(e.target.value)}}/>
                            <CountrySelector options={countries} placeholder="Select country" selectedOption={(e)=>{setCountry(e.optionShortName)}} label="Country" searchable={true} inputName="country"/>
                            <TextField label="Region/State/Province" required={true} placeholder="Enter Region/State/Province" value={region} onChange={(e)=>{setRegion(e.target.value)}}/>
                            <TextField label="City" required={true} placeholder="Enter city" value={city} onChange={(e)=>(setCity(e.target.value))}/>
                            <TextField label="Address" required={true} placeholder="Enter address" value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                            <TextField label="Postcode" required={true} placeholder="Enter postcode" value={postCode} onChange={(e)=>{
                                if (isNaN(Number(e.target.value))) return
                                setPostCode(e.target.value)
                            }}/>
                            <TextField label="Phone" required={true} placeholder="Enter phone number" value={phone} onChange={(e)=>{
                                if (isNaN(Number(e.target.value))) return
                                setPhone(e.target.value)
                            }}/>
                            <TextField label="Mobile" placeholder="Enter mobile number" value={mobile} onChange={(e)=>{
                                if (isNaN(Number(e.target.value))) return
                                setMobile(e.target.value)
                            }}/>
                            <EmailField label="Email" required={true} placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            <div className="flex flex-col gap-[12px]">
                                <DropdownList options={userRoles} placeholder="Select user role" label="User Role" returnedSelection={(e)=>{setUserRole(e.value)}} inputName="userRole"/>
                            </div>
                            <div className="flex flex-col gap-[12px]">
                                <DropdownList options={organisations} placeholder="Select organisation" label="Organisation" returnedSelection={(e)=>{setOrg(e.value)}} searchable={true} inputName="organisation"/>
                            </div>
                            <Button type="submit" size="lg" variant="primary" disabled={processing}>{!processing ? 'Add User' : <LoadingSpinner variant={"primary"}/>}</Button>
                        </div>
                    </form>
                </div>
            }
            {stage === 2 &&
                <div className="flex flex-col flex-1">
                    {Object.keys(sbmtRes).length > 0 &&
                        <div className="flex-1 flex flex-col justify-center items-center">
                            {sbmtRes.Status === 'SUCC' &&
                                <ActionSuccess headingText="User Added" subText={`${fName} ${lName} was added successfully`}/>
                            }
                            {sbmtRes.Status === 'FAIL' &&
                                <ActionFailed headingText="An error occurred" subText={sbmtRes.Message}/>
                            }
                            {sbmtRes.Status === 'ERR' &&
                                <ActionFailed headingText="An unexpected error occurred" subText="If the situation persists, you may report it as a bug."/>
                            }
                        </div>
                    }
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (sbmtRes.Status !== 'SUCC'){
                            setStep(1)
                        }else {
                            window.location.reload()
                        }
                    }}>{sbmtRes.Status === 'SUCC' ? 'Sounds Good' : 'Go Back'}</Button>
                </div>
            }
        </>
    )
}

export default AddUserContent
