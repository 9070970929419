import NavigationSection from "./NavigationSection";

const PreLoginContent = (props:any) => {
    const {children} = props
    return (
        <>
            <NavigationSection/>
            {children}
        </>
    )
}

export default PreLoginContent
