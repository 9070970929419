import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface BrokerState {
    screen: string
}

const initialState: BrokerState = {
    screen: 'brokerDets',
}

export const brokerSlice = createSlice({
    name: 'brokerScr',
    initialState:initialState,
    reducers: {
        changeScr: (state, action: PayloadAction<string>) => {
            state.screen = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeScr } = brokerSlice.actions

export default brokerSlice.reducer
