import DashboardBaseLayout from "../components/DashboardBaseLayout";
import VDivider from "../components/VDivider";
import SearchField from "../components/SearchField";
import React, {useContext, useEffect, useState} from "react";
import SystemConfigTable from "../components/SystemConfigTable/SystemConfigTable";
import {axiosSend} from "../utils/request";
import SidePanel from "../components/SidePanel/SidePanel";
import BackButton from "../components/BackButton";
import EditSysConfig from "../components/SystemConfigContent/EditSysConfig";
import dayjs from "dayjs";
import AddSysConfig from "../components/SystemConfigContent/AddSysConfig";
import UnauthorisedAccess from "../components/UnauthorisedAccess";
import UserContext from "../utils/userContext";
import {appName} from "../helpers";
import {AuditLog, Button, ButtonOutline, LoadingSpinner} from "@secondstaxorg/sscomp";
import {useTranslation} from "react-i18next";

const SystemConfig = () => {
    const {t} = useTranslation('common')
    document.title = `${appName} - ${t('common:code_maintenance.system_config')}`
    const userDets:any = useContext(UserContext)
    const [srchQry,setSrchQry] = useState('')
    const [sysConfigs,setSysConfigs]:any = useState([])
    const [sysConfigsOri,setSysConfigsOri]:any = useState([])
    const [loading,setLoading] = useState(true)
    const [showEditPnl,setShowEditPnl] = useState(false)
    const [showAddPnl,setShowAddPnl] = useState(false)
    const [configDets,setConfigDets]:any = useState({})
    const [logs,setLogs]:any = useState([])
    const [showLogs,setShowLogs] = useState(false)
    const [showPanelTitle,setShowPanelTitle] = useState(true)
    const [hasAccess,setHasAccess] = useState(true)

    useEffect(()=>{
        if (userDets && Object.keys(userDets).length > 0){
            let usrHasAccess = false
            userDets.apps.forEach((app:any)=>{
                if (app.APP_CAM){
                    usrHasAccess = true
                    loadSysConfigs()
                }
                setHasAccess(usrHasAccess)
            })
        }
    },[userDets])

    function loadSysConfigs(){
        axiosSend({
            url:'/fx/admin/sys-config',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                let tmp:any = []
                res.data.data.forEach((config:any)=>{
                    tmp.push({
                        configCode:config.config_code,
                        configName:config.config_name,
                        configValue:config.value,
                        remarks:config.config_up_rem
                    })
                })
                setSysConfigs(tmp)
                setSysConfigsOri(tmp)
            }

            if (res.data.logs && res.data.logs.length > 0){
                let tmp:any = []
                res.data.logs.forEach((log:any)=>{
                    tmp.push({
                        timestamp:dayjs(log.aud_time).format('DD MMM YYYY, HH:mm'),
                        user:log.aud_usr_id,
                        logDescription:log.aud_action,
                        reference:log.aud_id,
                    })
                })
                setLogs(tmp)
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }

    function getConfig(configCode:string){
        axiosSend({
            url:`/fx/admin/sys-config/${configCode}`,
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                setConfigDets(res.data.data[0])
                setShowEditPnl(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function srchConfig(qry:string){
        setSrchQry(qry)
        setSysConfigs(sysConfigsOri.filter((config:any)=>config.configName.toLowerCase().includes(qry.toLowerCase())))
    }

    if (!hasAccess){
        return (
            <DashboardBaseLayout pageName="liquidity-providers">
                <UnauthorisedAccess/>
            </DashboardBaseLayout>
        )
    }

    return (
        <DashboardBaseLayout pageName="system-config">
            <div className="flex justify-between items-center mb-[16px]">
                <h6 className="heading-6-bold font-bold">{t('common:code_maintenance.system_config')}</h6>
                <div className="flex justify-between items-center gap-[24px]">
                    <div className="flex justify-between gap-[24px]">
                        <div>
                            <Button variant="primary" size="md" onClick={()=>{setShowAddPnl(true)}}>{t('common:system_config.add_system_config')}</Button>
                        </div>
                        <div>
                            <ButtonOutline size="md" variant="primary" withBg={true} onClick={()=>{setShowLogs(true)}}>{t('common:general.view_logs')}</ButtonOutline>
                        </div>
                    </div>
                    <VDivider height={43}/>
                    <SearchField placeholder={t('common:system_config.search_system_config')} onChange={(e)=>{srchConfig(e.target.value)}} value={srchQry}/>
                </div>
            </div>
            {loading &&
                <div className="bg-white rounded-[8px] h-[450px] flex justify-center items-center">
                    <LoadingSpinner variant={"primary"}/>
                </div>
            }
            {!loading &&
                <SystemConfigTable configs={sysConfigs} callback={(e:any)=>{getConfig(e.configCode)}}/>
            }
            {showEditPnl &&
                <SidePanel closeFxn={()=>{setShowEditPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:system_config.edit_system_config') : ''} subtext={configDets.config_name}>
                    <EditSysConfig sysConfigDets={configDets} showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
            {showLogs &&
                <SidePanel closeFxn={()=>{setShowLogs(false)}} backBtnArea={<BackButton disabled={true}/>} title={t('common:general.activity_logs')}>
                    <AuditLog logDetails={logs}/>
                </SidePanel>
            }
            {showAddPnl &&
                <SidePanel closeFxn={()=>{setShowAddPnl(false)}} backBtnArea={<BackButton disabled={true}/>} title={showPanelTitle ? t('common:system_config.add_system_config') : ''}>
                    <AddSysConfig showPanelTitle={(e)=>{setShowPanelTitle(e)}}/>
                </SidePanel>
            }
        </DashboardBaseLayout>
    )
}

export default SystemConfig